import React, { useState } from "react";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";

import { withTranslation } from "react-i18next";
import $filter from "../../../../common/utils/$filter";
import AnticipationContract from "../../../Contracts/AnticipationContract/AnticipationContract";
import { getSessionUserProfile, hasRole } from "../../../../helpers/api_helper";
import { USER_PROFILES } from "../../../../domains/UserProfileDomain";

function UserIdentifierPanel(props) {
  const [userProfile] = useState(getSessionUserProfile());

  const [modalNewAnicipationContract, setModalNewAnicipationContract] =
    useState(false);

  const closeModalNewAnicipationContract = () => {
    setModalNewAnicipationContract(false);
  };

  const openModalNewAnicipationContract = () => {
    setModalNewAnicipationContract(true);
  };

  let firstKpi = {
    label: "Saldo disponivel",
    amount: $filter(props.entity.balanceAvailable, "MONEY"),
  };
  let secondKpi = {
    label: "Valor comprometido",
    amount: $filter(props.entity.compromisedValue, "MONEY"),
  };

  if (userProfile === USER_PROFILES.SELLER) {
    firstKpi = {
      label: "Limite disponível",
      amount: $filter(props.entity.creditLimit, "MONEY"),
    };
    secondKpi = {
      label: "Limite tomado",
      amount: $filter(props.entity.creditLimitCompromised, "MONEY"),
    };
  }

  return (
    <React.Fragment>
      <Card
        style={{
          marginRight: -22,
          marginLeft: -22,
          paddingLeft: 22,
        }}
      >
        <CardBody>
          <Row>
            <Col lg={4}>
              <p className="text-muted mb-0">
                {props.entity.receivingEndUser.legalName}
              </p>
              <h4>{props.entity.receivingEndUser.name}</h4>
              <p className="text-muted mb-0">
                CPF/CNPJ:{" "}
                {$filter(props.entity.receivingEndUser.legalId, "CPF_CNPJ")}
              </p>
            </Col>
            <Col lg={2}>
              <div>
                <blockquote className="blockquote font-size-16 mb-0">
                  <p className="text-muted mb-0">{firstKpi.label}</p>
                  <h4>{firstKpi.amount}</h4>
                </blockquote>
              </div>
            </Col>
            <Col lg={2}>
              {hasRole("contract.write") &&
                props.entity.balanceAvailable > 0 &&
                !props.loading && (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light mt-3"
                    onClick={openModalNewAnicipationContract}
                    style={{ marginLeft: -50 }}
                  >
                    Antecipar
                  </button>
                )}
            </Col>
            <Col lg={3}>
              <div>
                <blockquote
                  className="blockquote font-size-16 mb-0"
                  style={{ borderLeft: "4px solid #f56e50" }}
                >
                  <p className="text-muted mb-0">{secondKpi.label}</p>
                  <h4>{secondKpi.amount}</h4>
                </blockquote>
              </div>
            </Col>
          </Row>

          {props.entity["blockedCredit"] === "S" && (
            <Row className="mt-4">
              <Col lg={11}>
                <Alert color="warning" role="alert">
                  <i className="mdi mdi-calendar-lock font-size-20" />
                  &nbsp;&nbsp;
                  <span>Estabelecimento comercial com </span>
                  <strong>
                    Bloqueio de Agenda ({props.entity["tpBlockedCredit"]})
                  </strong>
                  <span>
                    , para operações de crédito é necessário remover os
                    bloqueios. Solicite orientação para equipe de Suporte de
                    Negócio
                  </span>
                </Alert>
              </Col>
            </Row>
          )}

          {!props.entity["lastUpdateDate"] && props.entity["hasLoadedApi"] && (
            <Row className="mt-4">
              <Col lg={11}>
                <Alert color="info" role="alert">
                  <i className="mdi mdi-clock font-size-20" />
                  &nbsp;&nbsp;
                  <span>Estabelecimento comercial </span>
                  <strong>sem Agenda de Recebíveis</strong>
                  <span>
                    , até o nomento não recebemos as agendas das maquininhas de
                    cartão.
                  </span>
                </Alert>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>

      {modalNewAnicipationContract && (
        <AnticipationContract
          isOpen={modalNewAnicipationContract}
          receivableUnits={props.entity.receivableUnitsAvailable}
          receivingEndUser={Object.assign(
            {
              bankAccounts: props.entity.bankAccounts,
            },
            props.entity.receivingEndUser
          )}
          financialAgent={props.entity.financialAgent}
          callback={(entity) => {
            closeModalNewAnicipationContract(entity);
          }}
        />
      )}
    </React.Fragment>
  );
}

export default withTranslation()(UserIdentifierPanel);
