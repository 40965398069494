import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Table } from "reactstrap";
import $filter from "../../../../common/utils/$filter";
import { Link } from "react-router-dom";
import TipoInfoPagamentoDomain from "../../../../domains/TipoInfoPagamentoDomain";
import DivisionRuleDomain from "../../../../domains/DivisionRuleDomain";
import PaymentCompanyDomain from "../../../../domains/PaymentCompanyDomain";
import SelectedReceivaleUnitsActionsBar from "./SelectedReceivaleUnitsActionsBar";

function AnalyticalVisionUnits(props) {
  const [receivableUnits, setReceivableUnits] = useState([]);
  const [receivableUnitsSelected, setReceivableUnitsSelected] = useState([]);

  if (receivableUnits.length === 0) {
    if (
      props.receivableUnitsAvailable &&
      props.receivableUnitsAvailable.length > 0
    ) {
      const receivableUnitsL = props.receivableUnitsAvailable;

      receivableUnitsL.forEach((ur, key) => {
        ur.showPayments = false;
        ur.checked = false;
        ur.id = key;
      });

      setReceivableUnits(Object.assign([], receivableUnitsL));
    }
  }

  const togPayments = (ur) => {
    ur.showPayments = !ur.showPayments;
  };

  const handleSelectAllReceivable = () => {
    const receivableUnitsNew = receivableUnits;

    receivableUnitsNew.forEach((ur) => {
      ur.checked = !ur.checked;
    });

    setReceivableUnits(Object.assign([], receivableUnitsNew));
    setReceivableUnitsSelected(
      Object.assign(
        [],
        receivableUnitsNew.filter((ur) => {
          return ur.checked;
        })
      )
    );
  };

  const handleSelectReceivable = (ur, index) => {
    const receivableUnitsNew = receivableUnits;
    receivableUnitsNew[index].checked = !receivableUnitsNew[index].checked;

    setReceivableUnits(Object.assign([], receivableUnitsNew));
  };

  return (
    <React.Fragment>
      <Table className="table table-striped table-bordered ">
        <thead className="table-light">
          <tr>
            <th>Dt. liquidação</th>
            <th>Credenciador/sub</th>
            <th>Arranjo de pagamento</th>
            <th>Valor constituido</th>
            <th>Valor livre</th>
            <th>Antecipação Pré</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {receivableUnits.map((ur, index) => {
            if (!ur["payments"]) ur["payments"] = [];

            return (
              <React.Fragment key={index}>
                <tr>
                  <td scope="row">{$filter(ur.settlementDate, "DATE")}</td>
                  <td>
                    {ur.nameAcquirer !== null &&
                    ur.nameAcquirer !== undefined &&
                    ur.nameAcquirer.length > 17
                      ? ur.nameAcquirer.substr(0, 30) + "..."
                      : ur.nameAcquirer}
                  </td>
                  <td>{ur.nameArrangement}</td>

                  <td>{$filter(ur.totalConstitutedValue, "MONEY")}</td>
                  <td>{$filter(ur.freeValue, "MONEY")}</td>
                  <td>{$filter(ur.anticipationPre, "MONEY")}</td>

                  <td className="text-center">
                    <Link
                      to="#"
                      style={{ fontSize: 16 }}
                      onClick={() => {
                        togPayments(ur);
                      }}
                    >
                      {ur.showPayments ? "[-]" : "[+]"}
                    </Link>
                  </td>
                </tr>

                {ur.showPayments ? (
                  <tr style={{ backgroundColor: "#b1b5c2 !important" }}>
                    <td colSpan="10" style={{ backgroundColor: "#b1b5c2" }}>
                      <table className="table table-striped table-bordered font-size-12">
                        <thead className="table-light">
                          <tr>
                            <th rowSpan="2">Ordem Liq.</th>
                            <th scope="row" rowSpan="2">
                              Beneficiário
                            </th>
                            <th scope="col" rowSpan="2">
                              Valor a pagar
                            </th>
                            <th scope="col" rowSpan="2">
                              Valor onerado
                            </th>
                            <th scope="col" rowSpan="2">
                              Data de liquidação efetiva
                            </th>
                            <th scope="col" rowSpan="2">
                              Valor de liquidação efetiva
                            </th>
                            <th scope="col" rowSpan="2">
                              Tipo pagamento
                            </th>
                            <th scope="col" rowSpan="2">
                              Regra de divisão
                            </th>

                            <th scope="col">Títular domicílio</th>
                            <th scope="col">Instituição domicílio/ISPB</th>
                            <th scope="col">Agência/conta</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ur.payments.map((pg, index) => (
                            <tr key={`PG-${index}`}>
                              <td>{pg.contractEffectIndicator}</td>
                              <td>
                                {pg.nameRecipient}
                                <br />
                                {$filter(pg.legalIdRecipient, "CPF_CNPJ")}
                              </td>
                              <td>{$filter(pg.amountToPay, "MONEY")}</td>
                              <td>
                                {pg["divisionRules"] ===
                                DivisionRuleDomain.PERCENTUAL
                                  ? `${pg.encumberedValuePercent}%`
                                  : $filter(pg.encumberedAmount, "MONEY")}
                              </td>
                              <td>
                                {$filter(pg.effectivePaymentDate, "DATE")}
                              </td>
                              <td>
                                {$filter(pg.effectivePaymentAmount, "MONEY")}
                              </td>
                              <td>
                                {TipoInfoPagamentoDomain.getLabelByValue(
                                  pg.typeInfoPayment
                                )}
                              </td>

                              <td>
                                {DivisionRuleDomain.getLabelByValue(
                                  pg.divisionRules
                                )}
                              </td>
                              <td>
                                {pg.nameHolder}
                                <br />
                                {$filter(pg.legalIdHolder, "CPF_CNPJ")}
                              </td>
                              <td>
                                {PaymentCompanyDomain.getLabelByCode(
                                  pg.bankAccountIspb
                                )}
                                <br />
                                {pg.bankAccountIspb}
                              </td>
                              <td>
                                Ag: {pg.bankAccountBranch}
                                <br /> CC: {pg.bankAccountAcNumber}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>

      {receivableUnitsSelected.length > 0 ? (
        <SelectedReceivaleUnitsActionsBar
          receivablesUnits={receivableUnitsSelected}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default withTranslation()(AnalyticalVisionUnits);
