const ServiceTypeDomain = {

    SIMPLES: "2",
    GESTAO_COLATERAIS: "1",
    MONITORAMENTO: "3",

    options: [
        {label: "Gestão De Colaterais", value: "1"},
        {label: "Registro simples", value: "2"},
        {label: "Monitoramento", value: "3"}
    ],

    getLabelByValue(value){
        for (const i in ServiceTypeDomain.options){
            if (ServiceTypeDomain.options[i].value === value){
                return this.options[i].label
            }
        }
        return null
    }
}


export default ServiceTypeDomain