import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {
  Col,
  Label,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import $filter from "../../common/utils/$filter";

const animatedComponents = makeAnimated();

function Select2PaymentPeriod(props) {
  const [options, setOptions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const filterOptions = (ops) => {
    const thisArray = ops && ops.defaultOptins ? ops.defaultOptins : [];

    const optFiltered = thisArray.filter((op) => {
      return (
        op["selectHiden"] === null ||
        op["selectHiden"] === undefined ||
        op["selectHiden"] !== true
      );
    });

    return optFiltered;
  };

  function onChange(options) {
    if (props.onChange) props.onChange(options);
  }

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);

      if (props.defaultOptins) {
        setOptions(filterOptions(props));
      }
    }
  });

  const [showPopover, setShowPopover] = useState(false);
  const [popoverBtnId] = useState(`popoverBtn${props.id}`);

  return (
    <div className="templating-select select2-container">
      {props.label !== null && props.label !== undefined ? (
        <Label for={props.id} className="form-label">
          {`${props.required === true ? "*" : ""}${props.label}`}
        </Label>
      ) : (
        ""
      )}
      {props.popover !== null && props.popover !== undefined ? (
        <button
          id={popoverBtnId}
          className="btn btn-without-class"
          onClick={() => {
            setShowPopover(!showPopover);
          }}
        >
          <i className="bx bxs-help-circle font-size-20" />
        </button>
      ) : (
        ""
      )}
      {props.popover !== null && props.popover !== undefined ? (
        <UncontrolledPopover
          placement="bottom"
          trigger="focus"
          target={popoverBtnId}
        >
          {props.label !== null && props.label !== undefined ? (
            <PopoverHeader>{props.label}</PopoverHeader>
          ) : (
            ""
          )}
          <PopoverBody>{props.popover}</PopoverBody>
        </UncontrolledPopover>
      ) : (
        ""
      )}
      <Select
        isMulti={false}
        classNamePrefix="select2-selection"
        className="select2-form"
        closeMenuOnSelect={true}
        isSearchable={false}
        value={props.value}
        isDisabled={props.isDisabled}
        menuPlacement={props.menuPlacement}
        placeholder={
          props.placeholder !== null && props.placeholder !== undefined
            ? props.placeholder
            : ""
        }
        getOptionLabel={(option) => {
          const opt = props.beforeGetOptionLabel(option);
          let parcelaLabel = "parcela";
          if (opt.numberInstallments > 0) {
            parcelaLabel = "parcelas";
          }
          return (
            <Row className="mt-2 mb-2">
              <Col>
                {opt.invalidMessage ? (
                  <i className="fa fa-ban text-danger" />
                ) : (
                  <i className="fa fa-check text-success" />
                )}
                &nbsp;&nbsp;
                <span>
                  {opt.numberInstallments}&nbsp;{parcelaLabel}
                  {opt.installmentMainAmount
                    ? ` de ${$filter(opt.installmentMainAmount, "MONEY")}`
                    : ""}
                </span>
              </Col>
              <Col>
                <span className="badge rounded-pill bg-light font-size-14">
                  {opt.paymentPeriod}
                </span>
              </Col>
            </Row>
          );
        }}
        getOptionValue={(option) => {
          return option.paymentPeriod;
        }}
        defaultValue={options[props.defaultValue]}
        options={options}
        components={animatedComponents}
        onChange={(options) => {
          onChange(options);
        }}
      />
    </div>
  );
}

export default withTranslation()(Select2PaymentPeriod);
