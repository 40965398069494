import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Row,
  Nav,
  NavItem,
  NavLink,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // Dropdown,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classnames from "classnames";

import * as url from "../../helpers/url_helper";

import TablePagination from "../../components/Common/TablePagination";
import PaginationService from "../../components/Common/PaginationService";

import StatusDomain from "../../domains/StatusDomain";
import $filter from "../../common/utils/$filter";

import ManageOptinNew from "./ManageOptinNew";
import InputText from "../../components/Common/InputText";
import InputDate from "../../components/Common/InputDate";
import LegalIdInput from "../../components/Common/LegalIdInput";
import StringUtils from "../../common/utils/StringUtils";
import { downloadFile, post } from "../../helpers/api_helper";
import ErrorDialog from "../../components/Common/ErrorDialog";
import SuccessDialog from "../../components/Common/SuccessDialog";

import Swal from "sweetalert2";
import ManageOptinView from "./ManageOptinView";

function ManageOptIn(props) {
  const [modalNewOptin, setModalNewEntity] = useState(false);
  const [modalViewOptin, setModalViewOptin] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState(PaginationService.defaultFilter());
  const [currentStatusTab, setCurrentStatusTab] = useState("ALL");
  const [optinSelected, setOptinSelected] = useState(null);

  const [statusTabs] = useState([
    { id: "ALL", title: props.t("All") },
    { id: "P", title: props.t("Processing") },
    { id: "A", title: props.t("Active") },
    { id: "I", title: props.t("Inactive") },
  ]);

  const searchByStatus = (status) => {
    if (currentStatusTab !== status) {
      setCurrentStatusTab(status);

      if (status !== "ALL") setFilterField("status", status);
      else setFilterField("status", null);

      applySearch(0, 4);
    }
  };

  const setFilterField = (name, value) => {
    filter[name] = value;
    setFilter(filter);
  };

  const handleChangeFilter = (event) => {
    filter[event.target.name] = event.target.value;
    setFilterField("filter", filter);
  };

  const applySearch = async (currentPage, itemsPerPage) => {
    try {
      const { pagerResult, filterResult } = await PaginationService.applySearch(
        url.GET_OPTIN,
        "optins",
        filter,
        currentPage,
        itemsPerPage,
        setLoading
      );
      setPager(pagerResult);
      setFilter(filterResult);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") setError(e);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPagination(filter));
    applySearch();
  };

  const downloadTerm = async (entity, index) => {
    const response = await downloadFile(
      `${url.GET_OPTIN_TERMS}/${entity.externalReference}`,
      null,
      null,
      setLoading
    );

    const newUrl = window.URL.createObjectURL(new Blob([response]));

    const link = document.createElement("a");
    link.href = newUrl;
    link.setAttribute(
      "download",
      `${entity.receivingEndUser["name"]} - ${entity.externalReference}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };

  const openOptoutConfirm = (entity, index) => {
    const html = `<div>
                <p>${props.t("Suspend access permission to")}<br/>
                    <strong>${entity.receivingEndUser.name}</strong><br/>
                    ${props.t("calendar through opt-out")}.
                </p>
            </div>`;

    Swal.fire({
      title: props.t("Confirm o opt-out?"),
      icon: "question",
      html: html,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: props.t("Confirm"),
      cancelButtonText: props.t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        setOptOut(entity, index);
      }
    });
  };

  const setOptOut = async (entity, index) => {
    try {
      const response = await post(
        url.POST_OPTOUT,
        {
          externalReference: entity["externalReference"],
        },
        null,
        setLoading
      );

      const newPager = Object.assign({}, pager);
      newPager.results[index] = response["optin"];
      setPager(newPager);

      setSuccessMessage({
        detail: props.t("Opt-in successfully deactivated"),
      });
    } catch (e) {
      if (e["status"] !== "200") setError(e);
    }
  };

  function openModalNewEntity() {
    setModalNewEntity(true);
  }

  function setResultFromNewEntity(newEntity) {
    setFilter(PaginationService.defaultFilter());

    const newPager = PaginationService.defaultPager();
    newPager.results = [newEntity];
    setPager(newPager);

    setCurrentStatusTab("ALL");
  }

  function closeModalNewEntity(resultModal) {
    setModalNewEntity(false);
    if (resultModal && resultModal["entity"]) {
      setSuccessMessage({
        detail: props.t("Opt-in successfully registered"),
      });

      setResultFromNewEntity(resultModal.entity);
    }
  }

  const openModalViewOptin = (entity) => {
    setOptinSelected(entity);
    setModalViewOptin(true);
  };

  const closeModalViewOptin = (entity) => {
    if (entity) {
      const newPager = Object.assign({}, pager);

      newPager.results.map((rl) => {
        if (rl.externalReference === entity.externalReference) {
          rl = entity;
        }
      });
      setPager(newPager);
    }
    setModalViewOptin(false);
  };

  useEffect(() => {
    applySearch();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("COMPANY | Opt-in")}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Opt-in")}
            breadcrumbItem={props.t("Manage opt-in")}
          />

          <Form
            id="searchForm"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t("Filters parameters")}</p>
                    <Row>
                      <Col lg={4} className="mb-3">
                        <InputText
                          id="externalReference"
                          onChange={(event) => handleChangeFilter(event)}
                          placeholder={props.t("Reference Code")}
                          autoComplete="off"
                        />
                      </Col>
                      <Col lg={4} className="mb-3">
                        <InputDate
                          id="startDate"
                          onChange={(event) => handleChangeFilter(event)}
                          placeholder={props.t("Start date")}
                        />
                      </Col>
                      <Col lg={4} className="mb-3">
                        <InputDate
                          id="endDate"
                          onChange={(event) => handleChangeFilter(event)}
                          placeholder={props.t("End date")}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4} className="mb-3">
                        <LegalIdInput
                          id="legalIdReceivingEndUser"
                          onChange={(event) => handleChangeFilter(event)}
                          placeholder={props.t("Legal Id Receiving end user")}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-sm btn-light w-sm waves-effect waves-light"
                          >
                            {props.t("Apply Search")}
                          </button>{" "}
                          <button
                            type="button"
                            className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                            onClick={openModalNewEntity}
                          >
                            {props.t("New")}
                          </button>{" "}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col lg={12}>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {statusTabs.map((tab, index) => {
                            return (
                              <NavItem key={tab.id}>
                                <NavLink
                                  className={classnames({
                                    active: currentStatusTab === tab.id,
                                  })}
                                  onClick={() => {
                                    searchByStatus(tab.id);
                                  }}
                                >
                                  {tab.title}
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </Nav>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg={12}>
                        <TablePagination
                          pager={pager}
                          applySearch={applySearch}
                          loading={loading}
                        >
                          <thead className="table-light">
                            <tr>
                              <th className="col-md-1 text-center">
                                {props.t("Status")}
                              </th>
                              <th className="col-md-3">
                                {props.t("Reference Code")}
                              </th>
                              <th className="col-md-3">
                                {props.t("Receiving end user")}
                              </th>
                              <th className="col-md-1">
                                {props.t("Start date/end date")}
                              </th>
                              <th className="col-md-1">
                                {props.t("Opt-out date")}
                              </th>
                              <th className="col-md-" />
                            </tr>
                          </thead>
                          <tbody>
                            {(pager.results || []).map((entity, key) => (
                              <tr key={entity["externalReference"]}>
                                <td
                                  scope="row"
                                  className="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    openModalViewOptin(entity);
                                  }}
                                >
                                  <span
                                    className={StatusDomain.getClassFromValue(
                                      entity.status
                                    )}
                                  >
                                    {StatusDomain.getNameFromValue(
                                      entity.status
                                    )}
                                  </span>
                                </td>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    openModalViewOptin(entity);
                                  }}
                                >
                                  {entity.externalReference}
                                </td>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    openModalViewOptin(entity);
                                  }}
                                >
                                  {StringUtils.abbreviateName(
                                    entity.receivingEndUser["name"]
                                  )}
                                  {StringUtils.isNotEmpty(
                                    entity.receivingEndUser["name"]
                                  ) ? (
                                    <br />
                                  ) : (
                                    ""
                                  )}
                                  <span className="card-title-desc font-size-12">
                                    {$filter(
                                      entity.receivingEndUser.legalId,
                                      "CPF_CNPJ"
                                    )}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    width: "10px",
                                    wordWrap: "break-word",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    openModalViewOptin(entity);
                                  }}
                                >
                                  {$filter(entity.startDate, "date")}
                                  <br />
                                  {$filter(entity.endDate, "date")}
                                </td>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    openModalViewOptin(entity);
                                  }}
                                >
                                  {" "}
                                  {$filter(entity.optoutDate, "date")}
                                </td>
                                <td>
                                  <div className="button-items text-end">
                                    {entity.status === "A" ? (
                                      <React.Fragment>
                                        <span
                                          style={{
                                            color: "#495057",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            openOptoutConfirm(entity, key);
                                          }}
                                        >
                                          <i className="bx bx-log-out font-size-20" />
                                          {"  "}
                                          Opt-out
                                        </span>
                                        <span
                                          style={{ color: "#cdcfd0" }}
                                          className="font-size-20"
                                        >
                                          {" | "}
                                        </span>
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                    <span
                                      style={{
                                        color: "#495057",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        downloadTerm(entity, key);
                                      }}
                                    >
                                      <i className="mdi mdi-file-download-outline font-size-20" />
                                      {"  "}
                                      Baixar Termo
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </TablePagination>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
          {modalNewOptin ? (
            <ManageOptinNew
              isOpen={modalNewOptin}
              callback={closeModalNewEntity}
            />
          ) : (
            <></>
          )}

          {modalViewOptin ? (
            <ManageOptinView
              entity={optinSelected}
              isOpen={modalViewOptin}
              callback={closeModalViewOptin}
            />
          ) : (
            <></>
          )}
        </Container>
      </div>

      {error ? (
        <ErrorDialog
          title={error["title"]}
          code={error["status"]}
          onClose={() => {
            setError(null);
          }}
        >
          <p>
            {!!error["detail"]
              ? error["detail"]
              : "Erro de rede, verifique sua conexão com a internet."}{" "}
          </p>
        </ErrorDialog>
      ) : (
        <></>
      )}

      {successMessage ? (
        <SuccessDialog
          title={successMessage["title"]}
          onClose={() => {
            setSuccessMessage(null);
          }}
        >
          <p>{successMessage["detail"]} </p>
        </SuccessDialog>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(ManageOptIn));
