import React from "react";
import ReactApexChart from "react-apexcharts";
import $filter from "../../../../common/utils/$filter";
import { withTranslation } from "react-i18next";
import ChartLocale from "../../../../domains/ChartLocale";

const ReceivablesTimelineChartAcquirerByMonths = (props) => {
  const receivableUnits = props.receivableUnits;
  const title = props.title;

  const labels = [];
  const labelsFormatted = [];
  let series = [];

  for (const i in receivableUnits) {
    const ur = receivableUnits[i];

    const existsLb = labels.filter((lb) => {
      return lb === ur["settlementDate"];
    });
    if (existsLb.length === 0) {
      labels.push(ur["settlementDate"]);

      const settlementDateParts = ur["settlementDate"].split("-");
      const monthNum = parseInt(settlementDateParts[1]) - 1;

      labelsFormatted.push(
        `${ChartLocale.pt.options.shortMonths[monthNum]}/${settlementDateParts[0]}`
      );
    }

    const existsAc = series.filter((ac) => {
      return ac.name === ur.name;
    });
    if (existsAc.length === 0) {
      series.push({
        name: ur.name,
        data: [],
        sumData: 0,
        labels: [
          { label: ur["settlementDate"], serie: ur["totalConstitutedValue"] },
        ],
      });
    } else {
      existsAc[0].labels.push({
        label: ur["settlementDate"],
        serie: ur["totalConstitutedValue"],
      });
    }
  }

  for (const i in series) {
    const sr = series[i];

    for (const j in labels) {
      const lb = labels[j];
      let serieValue = 0;

      const exists = sr.labels.filter((sLb) => {
        return sLb.label === lb;
      });
      if (exists.length > 0) {
        serieValue = exists[0].serie;
      }
      sr.data.push(serieValue);
      sr.sumData += serieValue;
    }
  }

  series = series.sort((a, b) => {
    return b.sumData - a.sumData;
  });

  const options = {
    chart: {
      height: 338,
      type: "bar",
      stacked: true,
      offsetY: -5,
      defaultLocale: ChartLocale.pt.name,
      toolbar: {
        show: true,
      },
      locales: [ChartLocale.pt],
    },
    title: {
      text: title,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "70%",
        borderRadius: 8,
      },
    },
    colors: ChartLocale.pt.colors,
    fill: {
      opacity: [0.9, 1, 0.25, 1],
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: labelsFormatted,
    markers: {
      size: 0,
    },
    xaxis: {
      type: "category",
      categories: labelsFormatted,
      labels: {
        formatter: function (val) {
          return val;
        },
      },
    },
    yaxis: {
      title: {
        text: "Valores",
      },
      labels: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return $filter(y, "MONEY");
          }
          return y;
        },
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return $filter(y, "MONEY");
          }
          return y;
        },
      },
    },
    grid: {
      borderColor: "#f8f8f8",
      padding: {
        bottom: 15,
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="410"
        dir="ltr"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default withTranslation()(ReceivablesTimelineChartAcquirerByMonths);
