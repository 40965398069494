import { USER_PROFILES } from "./UserProfileDomain"

const OrganizationProfileDomain = {

    options: [
        {label: "Agente Financeiro", value: USER_PROFILES.FINANCIAL },
        {label: "Estabelecimento Comercial", value: USER_PROFILES.USER },
        {label: "Fornecedor", value: USER_PROFILES.SELLER },
        {label: "Agente de Registro", value: USER_PROFILES.ADM, selectHiden: true },
    ],

    getLabelByValue(value) {
        if (!value) return
        for (const i in OrganizationProfileDomain.options) {
            if (OrganizationProfileDomain.options[i].value.toString() === value.toString()) {
                return this.options[i].label
            }
        }
        return null
    }
}

export default OrganizationProfileDomain