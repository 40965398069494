import React, { useState } from "react";

import { Col, Row, Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import $filter from "../../../common/utils/$filter";
import StringUtils from "../../../common/utils/StringUtils";

function AnticipationContractReceivables(props) {
  const removeGuarantee = (indexToRemove) => {
    const guarantees = [];

    props.contract.guarantees.map((ga, index) => {
      if (index !== indexToRemove) {
        guarantees.push(Object.assign({}, ga));
      }
    });
    props.contract.guarantees = guarantees;
    props.setContract(props.contract);
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Table className="table table-striped table-bordered ">
            <thead className="table-light">
              <tr>
                <th>Dt. liquidação</th>
                <th>Credenciador/sub</th>
                <th>Valor à antecipar</th>
                <th>Valor à receber</th>
                <th>Deságio</th>
                <th>Taxa</th>
              </tr>
            </thead>
            <tbody>
              {props.contract.guarantees.map((ga, index) => {
                return (
                  <tr key={index}>
                    <td scope="row">
                      {index + 1})&nbsp;&nbsp;
                      {$filter(ga.endDate, "DATE")}
                      <br />
                      <small>
                        prazo&nbsp;{ga.deadline}&nbsp;{props.t("Days")}
                      </small>
                    </td>
                    <td>
                      {StringUtils.abbreviateName(ga.acquirers[0].name)}
                      <br />
                      <small>{ga.arrangements[0].label}</small>
                    </td>
                    <td>{$filter(ga.amountCharged, "MONEY")}</td>
                    <td>{$filter(ga.mainValue, "MONEY")}</td>
                    <td>{$filter(ga.discountValue, "MONEY")}</td>
                    <td>{$filter(ga.effectiveRate, "PRECENT")}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default withTranslation()(AnticipationContractReceivables);
