const SystemRegisterDomain = {
  /**
     * $yellow:  #f5bd58;
        $indigo:  #564ab1;
        $green:   #2cb57e;
        $lite-red: #FFD6D7;
     */

  options: [
    { label: "CIP", value: "04391007000132", color: "#f5bd58" },
    { label: "CERC", value: "23399607000191", color: "#2cb57e" },
    { label: "TAG", value: "31345107000103", color: "#564ab1" },
    { label: "CIP", value: "09346601000125", color: "#f5bd58" },
  ],

  getLabelByValue(value) {
    if (!value) return;
    for (const i in SystemRegisterDomain.options) {
      if (
        SystemRegisterDomain.options[i].value.toString() === value.toString()
      ) {
        return this.options[i].label;
      }
    }
    return null;
  },
};

export default SystemRegisterDomain;
