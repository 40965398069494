import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {
  Label,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";

const animatedComponents = makeAnimated();

function Select2Generic(props) {
  const [popoverBtnId] = useState(`popoverBtn${props.id}`);
  const [showPopover, setShowPopover] = useState(false);

  const filterOptions = (ops) => {
    const thisArray = ops && ops.defaultOptins ? ops.defaultOptins : [];

    return thisArray.filter((op) => {
      return (
        op["selectHiden"] === null ||
        op["selectHiden"] === undefined ||
        op["selectHiden"] !== true
      );
    });
  };

  const [options, setOptions] = useState(filterOptions(props));

  useEffect(() => {
    setOptions(filterOptions(props));
  }, [props.defaultOptins]);

  function onChange(opt) {
    if (
      !(opt instanceof Array) &&
      opt &&
      opt.label &&
      opt.label === opt.value
    ) {
      opt.label = props.removeMask ? opt.label : `${opt.label}%`;
      opt.value = props.removeMask ? opt.value : opt.value.replace(",", ".");

      if (!props.removeMask && isNaN(opt.value)) return;

      options.push(opt);
    }

    if (props.onChange) props.onChange(opt);
  }

  return (
    <div className="templating-select select2-container">
      {props.label !== null && props.label !== undefined ? (
        <Label for={props.id} className="form-label">
          {`${props.required === true ? "*" : ""}${props.label}`}
        </Label>
      ) : (
        ""
      )}
      {props.popover !== null && props.popover !== undefined ? (
        <button
          id={popoverBtnId}
          style={{ height: 20 }}
          className="btn btn-without-class"
          onClick={() => {
            setShowPopover(!showPopover);
          }}
        >
          <i className="bx bxs-help-circle font-size-20" />
        </button>
      ) : (
        ""
      )}

      {props.popover !== null && props.popover !== undefined ? (
        <UncontrolledPopover
          placement="bottom"
          trigger="focus"
          target={popoverBtnId}
        >
          {props.label !== null && props.label !== undefined ? (
            <PopoverHeader>{props.label}</PopoverHeader>
          ) : (
            ""
          )}
          <PopoverBody>{props.popover}</PopoverBody>
        </UncontrolledPopover>
      ) : (
        ""
      )}
      <Select
        isMulti={props.isMulti ? props.isMulti : false}
        classNamePrefix="select2-selection"
        className="select2-form"
        closeMenuOnSelect={true}
        isDisabled={props.isDisabled}
        isSearchable={props.isSearchable}
        menuPlacement={props.menuPlacement}
        value={props.value}
        placeholder={
          props.placeholder !== null && props.placeholder !== undefined
            ? props.placeholder
            : ""
        }
        formatCreateLabel={
          props.formatCreateLabel ? props.formatCreateLabel : undefined
        }
        getOptionLabel={props.getOptionLabel ? props.getOptionLabel : undefined}
        defaultValue={options[props.defaultValue]}
        options={options}
        components={animatedComponents}
        onChange={(options) => {
          onChange(options);
        }}
      />
    </div>
  );
}

export default withTranslation()(Select2Generic);
