import React from "react";
import ReactApexChart from "react-apexcharts";
import $filter from "../../../../common/utils/$filter";
import { withTranslation } from "react-i18next";
import ChartLocale from "../../../../domains/ChartLocale";

const ReceivablesTimelineChartChart = (props) => {
  const receivableUnits = props?.receivableUnits || [];

  const freeValueFormatByDay = props.showFreeValue;
  const totalConstitutedByMonths = props.showTotalConstitutedValue;

  const title = props.title;

  const freeValueList = [];

  let labels = [];
  const labelsByMonths = [];
  const labelsByDay = [];
  let seriesFormat = "category";

  const seriesConstValue = [];

  for (const i in receivableUnits) {
    const ur = receivableUnits[i];
    freeValueList.push(ur["freeValue"]);

    const existsConstValue = seriesConstValue.filter((ac) => {
      return ac.name === ur.name;
    });
    if (existsConstValue.length === 0) {
      seriesConstValue.push({
        name: ur.name,
        data: [],
        labels: [
          { label: ur["settlementDate"], serie: ur["totalConstitutedValue"] },
        ],
      });
    } else {
      existsConstValue[0].labels.push({
        label: ur["settlementDate"],
        serie: ur["totalConstitutedValue"],
      });
    }

    const existsLb = labels.filter((lb) => {
      return lb === ur["settlementDate"];
    });

    if (existsLb.length === 0) {
      labels.push(ur["settlementDate"]);
      const settlementDateParts = ur["settlementDate"].split("-");
      const monthNum = parseInt(settlementDateParts[1]) - 1;

      labelsByMonths.push(
        `${ChartLocale.pt.options.shortMonths[monthNum]}/${settlementDateParts[0]}`
      );

      if (settlementDateParts.length > 2) {
        labelsByDay.push(
          `${settlementDateParts[2].split("T")[0]}/${
            ChartLocale.pt.options.months[monthNum]
          }/${settlementDateParts[0]}`
        );
      }
    }
  }

  const series = [];
  if (freeValueFormatByDay) {
    series.push({
      name: props.t("Free value"),
      type: "column",
      data: freeValueList,
    });
    labels = labelsByDay;
  } else if (totalConstitutedByMonths) {
    const seriesData = [];

    for (const i in seriesConstValue) {
      const sr = seriesConstValue[i];

      for (const j in labels) {
        const lb = labels[j];
        let serieValue = 0;

        const exists = sr.labels.filter((sLb) => {
          return sLb.label === lb;
        });
        if (exists.length > 0) {
          serieValue = exists[0].serie;
        }
        seriesData.push(serieValue);
      }
    }
    series.push({
      name: props.t("Constituted value"),
      type: "column",
      data: seriesData,
    });
    labels = labelsByMonths;
  }

  const options = {
    chart: {
      height: 338,
      type: "bar",
      stacked: false,
      offsetY: -5,
      defaultLocale: ChartLocale.pt.name,
      locales: [ChartLocale.pt],
      toolbar: {
        show: true,
      },
    },
    title: {
      text: title,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "70%",
        borderRadius: 8,
      },
    },
    colors: ChartLocale.pt.colors,
    fill: {
      opacity: [0.9, 1, 0.25, 1],
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: labels,
    markers: {
      size: 0,
    },
    xaxis: {
      type: seriesFormat,
      range: 7,
    },
    yaxis: {
      title: {
        text: "Valores",
      },
      range: 7,
      labels: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return $filter(y, "MONEY");
          }
          return y;
        },
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return $filter(y, "MONEY");
          }
          return y;
        },
      },
    },
    grid: {
      borderColor: "#f8f8f8",
      padding: {
        bottom: 15,
      },
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="420"
        dir="ltr"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default withTranslation()(ReceivablesTimelineChartChart);
