import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import InputText from "../../../components/Common/InputText";
import Select from "react-select";
import * as url from "../../../helpers/url_helper";
import PaginationService from "../../../components/Common/PaginationService";
import Select2Async from "../../../components/Common/Select2Async";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import $filter from "../../../common/utils/$filter";
import InputDate from "../../../components/Common/InputDate";
import QueryString from "qs";
import OrderViewAdmin from "./OrderViewAdmin";
import OrderStatusBadge from "./OrderStatusBadge";

function OrderV2(props) {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenId, setIsOpenId] = useState(null);
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setIsOpenId(row.id);
      setIsOpen(!isOpen);
    },
    onMouseEnter: (e, row, rowIndex) => {},
  };

  const handleChangeFilterRangeCalendar = (event, field) => {
    if (!filter[field]) {
      filter[field] = {};
    }

    filter[field].init = event?.init?.value;
    filter[field].end = event?.end?.value;
    // setFilterField("filter", filter);
    setFilter(filter);
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
    },
    {
      dataField: "orderDate",
      text: "Data da cobrança",
      formatter: (cell, row) => <span id={row}>{$filter(cell, "DATE")}</span>,
    },
    {
      dataField: "invoiceNumber",
      text: "Número da Cobrança",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => <OrderStatusBadge status={cell} />,
    },
    {
      dataField: "buyerId",
      text: "CNPJ do comprador",
      formatter: (cell, row) => (
        <span id={row}>{$filter(cell, "CPF_CNPJ")}</span>
      ),
    },
    {
      dataField: "amount",
      text: "Valor",
      formatter: (cell, row) => <span id={row}>{$filter(cell, "MONEY")}</span>,
    },
    {
      dataField: "mainAmount",
      text: "Valor principal",
      formatter: (cell, row) => <span id={row}>{$filter(cell, "MONEY")}</span>,
    },
    {
      dataField: "paymentMethod",
      text: "Método de pagamento",
    },
    {
      dataField: "deadline",
      text: "Prazo",
    },
    {
      dataField: "financialAgentId",
      text: "CNPJ agente financeiro",
      formatter: (cell, row) => (
        <span id={row}>{$filter(cell, "CPF_CNPJ")}</span>
      ),
    },
    {
      dataField: "sellerId",
      text: "Cnpj Vendedor",
      formatter: (cell, row) => (
        <span id={row}>{$filter(cell, "CPF_CNPJ")}</span>
      ),
    },
    {
      dataField: "tax",
      text: "Taxa",
      formatter: (cell, row) => (
        <span id={row}>{$filter(cell, "PRECENT")}</span>
      ),
    },
  ];

  let query = useQuery();
  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [tableList, setTableList] = useState([]);
  const [filter, setFilter] = useState(PaginationService.defaultFilter(10, 1));
  const [loading, setLoading] = useState(false);
  const [statusSelects, setStatusSelects] = useState({});
  const [error, setError] = useState(null);
  const [totalOfItems, setTotalOfItems] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const applySearch = async (
    currentPage,
    itemsPerPage,
    filterDefault = filter
  ) => {
    try {
      const { pagerResult, filterResult } =
        await PaginationService.applySearchV2(
          url.GET_ORDER_ADMIN,
          "data",
          filterDefault,
          currentPage,
          itemsPerPage,
          setLoading
        );
      setPager(pagerResult);
      setFilter(filterResult);
      updateQueryUrl(filterResult);
      setTotalOfItems(pagerResult.pagination.totalOfItems);
      setNumberOfPages(pagerResult.pagination.numberOfPages);
      setCurrentPage(pagerResult.pagination.currentPage);
      setItemsPerPage(pagerResult.pagination.itemsPerPage);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") {
        setError(e);
      }
    }
  };

  const handleChangeFilter = (event, field) => {
    filter[field] = event.target.value;

    // setFilterField("filter", filter);
    setFilter(filter);
  };

  const handlerSelect = (event, field) => {
    filter[field] = event?.value || undefined;
    setStatusSelects((v) => ({
      ...v,
      [field]: event,
    }));
    // setFilterField("filter", filter);
    setFilter((f) => ({
      ...f,
      ...filter,
      ...PaginationService.resetPagination2(filter),
    }));
    applySearch();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPagination2(filter));
    applySearch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    const filterInit = PaginationService.defaultFilter(10, 1);
    setStatusSelects((v) => ({
      ...v,
      status: null,
      buyerId: null,
      financialAgentId: null,
      sellerId: null,
      paymentMethod: null,
    }));
    document.getElementById("searchForm").reset({
      invoiceNumber: undefined,
      status: undefined,
      buyerId: undefined,
      sellerId: undefined,
      financialAgentId: undefined,
      paymentMethod: undefined,
    });

    await setFilter((_f) => ({
      ...filterInit,
    }));
    await applySearch(
      filterInit["pagination.currentPage"],
      filterInit["pagination.itemsPerPage"],
      filterInit
    );
  };

  const updateQueryUrl = (filter) => {
    if (filter) {
      const formatquery = QueryString.stringify(filter);
      props.history.push({
        search: formatquery,
      });
    }
  };

  useEffect(() => {
    if (!query.size) {
      applySearch();
      return;
    }

    async function fetchData() {
      try {
        const filterInit = QueryString.parse(query.toString());
        await setFilter((f) => ({
          ...f,
          ...filterInit,
        }));
        await applySearch(
          filterInit["pagination.currentPage"],
          filterInit["pagination.itemsPerPage"],
          filterInit
        );
        setStatusSelects((v) => ({
          ...v,
          status: {
            value: filterInit["status"],
          },
          buyerId: {
            value: filterInit["buyerId"],
            label: filterInit["buyerId"],
          },
          sellerId: {
            value: filterInit["sellerId"],
            label: filterInit["sellerId"],
          },
          financialAgentId: {
            value: filterInit["financialAgentId"],
            label: filterInit["financialAgentId"],
          },
          paymentMethod: {
            value: filterInit["paymentMethod"],
          },
        }));
      } catch (e) {
        setError(e);
      }
    }
    fetchData();
  }, []);

  const orderStatus = [
    { value: "P", label: "Pendente" },
    { value: "A", label: "Aprovado" },
    { value: "R", label: "Rejeitado" },
    { value: "C", label: "Cancelado" },
    { value: "V", label: "Em Validação" },
  ];
  // const productsStatus = {
  //   "CASH.NOW": "CASH.NOW",
  //   BOLETO_GARANTIDO: "BOLETO_GARANTIDO",
  //   "CASH.RECUPERA": "CASH.RECUPERA",
  // };
  const productsStatus = [
    { value: "CASH.NOW", label: "CASH NOW" },
    { value: "BOLETO_GARANTIDO", label: "BOLETO GARANTIDO" },
    { value: "CASH.RECUPERA", label: "CASH RECUPERA" },
  ];

  useEffect(() => {
    if (pager?.results) {
      setTableList(pager?.results);
    }
  }, [pager.results]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Cobranças")}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Cobrança")}
            breadcrumbItem={props.t("Gerenciamento de Cobranças")}
          />
          <Form
            id="searchForm"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            default={{
              invoiceNumber: undefined,
              status: undefined,
              buyerId: undefined,
              sellerId: undefined,
              paymentMethod: undefined,
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t("Filters parameters")}</p>
                    <Row>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <InputText
                          id="invoiceNumber"
                          onChange={(event) =>
                            handleChangeFilter(event, "invoiceNumber")
                          }
                          placeholder={props.t("Reference Code")}
                          autoComplete="off"
                        />
                      </Col>

                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <Select2Async
                          url={url.GET_ORDER_SELLER_AGENT_PAGINATION}
                          orderBy={"name"}
                          onChange={(event) => handlerSelect(event, "sellerId")}
                          id="sellerId"
                          placeholder={"CNPJ Fornecedor"}
                          value={statusSelects["sellerId"]}
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <Select2Async
                          url={url.GET_ORDER_BUYER_AGENT_PAGINATION}
                          orderBy={"name"}
                          onChange={(event) => handlerSelect(event, "buyerId")}
                          id="buyerId"
                          placeholder={"CNPJ Cliente Final"}
                          value={statusSelects["buyerId"]}
                        />
                      </Col>

                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <div className="templating-select select2-container">
                          <Select
                            id="statusOrder"
                            isMulti={false}
                            classNamePrefix="select2-selection"
                            isSearchable={false}
                            onChange={(event) => handlerSelect(event, "status")}
                            placeholder={props.t("Status")}
                            options={orderStatus}
                            value={statusSelects["status"]}
                            isClearable={true}
                          />
                        </div>
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <div className="templating-select select2-container">
                          <Select
                            id="paymentMethod"
                            isMulti={false}
                            classNamePrefix="select2-selection"
                            isSearchable={false}
                            onChange={(event) =>
                              handlerSelect(event, "paymentMethod")
                            }
                            placeholder={"Tipo de Cobrança"}
                            options={productsStatus}
                            value={statusSelects["paymentMethod"]}
                            isClearable={true}
                          />
                        </div>
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <InputDate
                          id="orderDate"
                          onChange={(event) =>
                            handleChangeFilterRangeCalendar(event, "orderDate")
                          }
                          placeholder="Data da cobrança"
                          values={[
                            filter?.["orderDate"]?.init,
                            filter?.["orderDate"]?.end,
                          ]}
                          options={{
                            mode: "range",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <hr />
                    </Row>
                    <Row className="mt-4">
                      <Col lg={4} className="col-sm-auto offset-sm-8">
                        <Row
                          className="row-sm-auto text-end"
                          style={{
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className="btn btn-sm btn-light w-sm waves-effect waves-ligh col-auto mr-5"
                            onClick={(e) => clearFilters(e)}
                            title="Limpar Filtros"
                          >
                            Limpar Filtros
                          </button>
                          <button
                            type="submit"
                            title="Aplicar Filtros"
                            className="btn btn-sm btn-primary w-sm waves-effect waves-primary col-auto"
                          >
                            {props.t("Apply Search")}
                          </button>
                        </Row>
                      </Col>
                    </Row>

                    <Row
                      className="mt-4"
                      style={{
                        overflowX: "auto",
                      }}
                    >
                      <Col lg={12}>
                        <PaginationProvider
                          pagination={paginationFactory({
                            onSizePerPageChange: (sizePerPage, page) => {
                              filter["pagination.itemsPerPage"] = sizePerPage;
                              filter["pagination.currentPage"] = page;
                              setFilter(filter);
                              applySearch(page, sizePerPage, {
                                ...filter,
                              });
                            },
                            onPageChange: (page, sizePerPage) => {
                              filter["pagination.itemsPerPage"] = sizePerPage;
                              filter["pagination.currentPage"] = page;
                              setFilter(filter);
                              applySearch(page, sizePerPage, {
                                ...filter,
                              });
                            },
                            page: currentPage,
                            sizePerPage: itemsPerPage,
                            totalSize: totalOfItems,
                            showTotal: true,
                          })}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <div>
                              <BootstrapTable
                                onTableChange={(type, newState) => {
                                  console.log(type, newState);
                                }}
                                keyField="id"
                                remote={true}
                                data={tableList || []}
                                loading={true}
                                showTotal={true}
                                columns={columns}
                                rowEvents={rowEvents}
                                {...paginationTableProps}
                              />
                            </div>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
        <OrderViewAdmin
          key={"OrderViewAdmin"}
          isOpen={isOpen}
          toggle={setIsOpen}
          afterClosed={applySearch}
          entityId={isOpenId}
        />
      </div>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(OrderV2));
