import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";

import ArrangementsDomain from "../../domains/ArrangementsDomain";
import { Label } from "reactstrap";

const animatedComponents = makeAnimated();

class Select2Arrangements extends React.Component {
  constructor(props) {
    super(props);

    let arrangementList = [
      {
        code: "ALL",
        label: this.props.t("All"),
      },
      {
        code: "ALL_DEBIT",
        label: this.props.t("All Debits"),
      },
      {
        code: "ALL_CREDIT",
        label: this.props.t("All Credits"),
      },
    ];
    arrangementList = arrangementList.concat(props.arrangements || []);

    // concatena a lista de arranjos com a lista de arranjos padrao do sistema e remove duplicados
    arrangementList = arrangementList.concat(
      ArrangementsDomain.options.filter(
        (arrangement) =>
          !arrangementList.some((item) => item.code === arrangement.code)
      )
    );

    this.state = {
      arrangementList: arrangementList,
    };
  }

  onChange = (arrangements) => {
    let arrangementsMap = arrangements || [];

    // nao permite coexistir a opcao ALL com as demais opcoes
    if (arrangementsMap.length > 1) {
      const firstItem = arrangementsMap[0];
      const lastItem = arrangementsMap[arrangementsMap.length - 1];

      if (firstItem["code"].includes("ALL")) {
        arrangementsMap.shift();
        arrangements = arrangementsMap;
      } else if (lastItem["code"].includes("ALL")) {
        arrangementsMap.splice(0, arrangementsMap.length - 1);
        arrangements = arrangementsMap;
      }
    }
    this.props.onChange(arrangementsMap);
  };

  render() {
    return (
      <div className="templating-select select2-container">
        {this.props.label !== null && this.props.label !== undefined ? (
          <Label for={this.props.id} className="form-label">
            {`${this.props.required === true ? "*" : ""}${this.props.label}`}
          </Label>
        ) : (
          ""
        )}

        <Select
          isMulti={
            this.props.isMulti !== null && this.props.isMulti !== undefined
              ? this.props.isMulti
              : true
          }
          classNamePrefix="select2-selection"
          className="select2-form"
          closeMenuOnSelect={true}
          menuPlacement={this.props.menuPlacement}
          placeholder={
            this.props.placeholder !== null &&
            this.props.placeholder !== undefined
              ? this.props.placeholder
              : ""
          }
          options={this.state.arrangementList}
          defaultValue={this.state.arrangementList[this.props.defaultValue]}
          components={animatedComponents}
          getOptionValue={(option) => {
            return option.code;
          }}
          getOptionLabel={(option) => {
            return option.label;
          }}
          onChange={(options) => {
            this.onChange(options);
          }}
        />
      </div>
    );
  }
}

Select2Arrangements.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  menuPlacement: PropTypes.string,
};

export default withTranslation()(Select2Arrangements);
