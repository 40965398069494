import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import { Label } from "reactstrap";
import DistributionTypeDomain from "../../domains/DistributionTypeDomain";

const animatedComponents = makeAnimated();

function Select2DistributionType(props) {
  const [options, setOptions] = useState(DistributionTypeDomain.options);

  function onChange(options) {
    if (props.onChange) props.onChange(options);
  }

  return (
    <div className="templating-select select2-container">
      {props.label !== null && props.label !== undefined ? (
        <Label for={props.id} className="form-label">
          {`${props.required === true ? "*" : ""}${props.label}`}
        </Label>
      ) : (
        ""
      )}
      <Select
        isMulti={false}
        classNamePrefix="select2-selection"
        className="select2-form"
        closeMenuOnSelect={true}
        isSearchable={false}
        menuPlacement={props.menuPlacement}
        placeholder={
          props.placeholder !== null && props.placeholder !== undefined
            ? props.placeholder
            : ""
        }
        defaultValue={options[props.defaultValue]}
        options={options}
        components={animatedComponents}
        onChange={(options) => {
          onChange(options);
        }}
        value={options.filter((option) => option.value === props.value)}
      />
    </div>
  );
}

export default withTranslation()(Select2DistributionType);
