import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useParams, useHistory, withRouter } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

import UserIdentifierPanel from "./components/UserIdentifierPanel";
import GeneralBalanceReport from "./components/GeneralBalanceReport";
import ReceivablesScheduleGraphic from "./components/ReceivablesScheduleGraphic";
import ReceivablesUnitsAnalytcs from "./components/ReceivablesUnitsAnalytcs";
import { receivablesService } from "../../../services/http";

import Loader from "../../../components/Common/Loader";

function ReceivablesScheduleDetail(props) {
  const [loading, setLoading] = useState(true);
  const [receivable, setReceivable] = useState(null);

  let { id } = useParams();
  const history = useHistory();

  const getReceivable = async (legalId) => {
    setLoading(true);

    try {
      const response = await receivablesService.getByLegalId(legalId);
      setReceivable(response.receivable);
    } catch (e) {
      if (e.response.status === 404) {
        history.push("/receivables");
        return;
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getReceivable(id);
  }, [id]);

  return (
    <>
      <div className="page-content" style={{ padding: "0 10px 0" }}>
        <MetaTags>
          <title>{props.t("COMPANY | Receivables")}</title>
        </MetaTags>

        {!!receivable && (
          <>
            <UserIdentifierPanel entity={receivable} loading={loading} />

            <Container fluid>
              <Row>
                <ReceivablesScheduleGraphic entity={receivable} />
                <GeneralBalanceReport entity={receivable} loading={loading} />
              </Row>

              <ReceivablesUnitsAnalytcs receivable={receivable} />
            </Container>
          </>
        )}
      </div>

      {loading && <Loader loading={loading} />}
    </>
  );
}

export default withRouter(withTranslation()(ReceivablesScheduleDetail));
