import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";

import { withTranslation } from "react-i18next";
import OrganizationProfileDomain from "../../domains/OrganizationProfileDomain";
import $filter from "../../common/utils/$filter";
import Select2Generic from "../../components/Common/Select2Generic";
import { get, getSessionUserProfile } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import StatusDomain from "../../domains/StatusDomain";
import { BadgeStatusSelect } from "./BadgeStatusSelect";
import { USER_PROFILES } from "../../domains/UserProfileDomain";

function OrganizationEditIdentificationData(props) {
  useEffect(() => {
    loadSegments().then();
  }, [props.entity]);

  const [state, setState] = useState({
    companySegments: null,
    segmentIndex: null,
  });

  const isAdm = getSessionUserProfile() === "REGISTER_AGENT";

  const loadSegments = async () => {
    const response = await get(
      `${url.GET_SEGMENTS}?pagination.currentPage=0&pagination.itemsPerPage=100`,
      null,
      null,
      props.setLoading
    );
    state.companySegments = response.segments.map((s) => {
      return { label: s.segmentLabel, value: s.segmentName };
    });

    if (state || state.companySegments) {
      const found = state.companySegments.find(
        (s) => s.label === props.entity.segment
      );
      state.segmentIndex = state.companySegments.indexOf(found);
    }

    setState({ ...state });
  };

  const getValueIfNotNull = (obj, fieldName) => {
    if (obj) return obj[fieldName];
    return null;
  };

  return (
    <Card>
      <CardBody>
        <Table className="table table-striped mb-0 font-size-14">
          <tbody>
            <tr>
              <td className="col-md-4">
                <p className="card-title-desc mb-2">{props.t("Legal name")}</p>
                {getValueIfNotNull(
                  props.entity.additionalInfo,
                  "nomeEmpresarial"
                )}
              </td>
              <td className="col-md-4">
                <p className="card-title-desc mb-2">{props.t("Trade name")}</p>
                {getValueIfNotNull(props.entity.additionalInfo, "nomeFantasia")}
              </td>
            </tr>

            <tr>
              <td className="col-md-4">
                <p className="card-title-desc mb-2">{props.t("Profile")}</p>
                {OrganizationProfileDomain.getLabelByValue(
                  props.entity.appProfile
                )}
              </td>
              <td className="col-md-4">
                {getSessionUserProfile() === "REGISTER_AGENT" ? (
                  <Row>
                    <Col lg={12} className="mb-3">
                      {state && state.companySegments && (
                        <Select2Generic
                          id="segment"
                          required={false}
                          removeMask={true}
                          defaultOptins={state ? state.companySegments : []}
                          defaultValue={state.segmentIndex}
                          label={props.t("Segment")}
                          onChange={(option) => {
                            props.handleChange("segment", option.value);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                ) : (
                  <>
                    <p className="card-title-desc mb-2">{props.t("Segment")}</p>
                    {props.entity.segment}
                  </>
                )}
              </td>
            </tr>

            <tr>
              <td className="col-md-4" colSpan={2}>
                <p className="card-title-desc mb-2">{props.t("Address")}</p>

                {props.entity.additionalInfo &&
                  props.entity.additionalInfo.endereco && (
                    <span>
                      {props.entity.additionalInfo.endereco.tipoLogradouro}
                      &nbsp;
                      {props.entity.additionalInfo.endereco.logradouro},&nbsp;
                      {props.entity.additionalInfo.endereco.numero}&nbsp;
                      {props.entity.additionalInfo.endereco.bairro},&nbsp;
                      {props.entity.additionalInfo.endereco.municipio.descricao}
                      &nbsp;-&nbsp;
                      {props.entity.additionalInfo.endereco.uf}
                    </span>
                  )}
              </td>
            </tr>

            <tr>
              <td className="col-md-4">
                <p className="card-title-desc mb-2">
                  {props.t("Social capital")}
                </p>

                {props.entity.additionalInfo &&
                  props.entity.additionalInfo.capitalSocial && (
                    <span>
                      {$filter(
                        parseFloat(props.entity.additionalInfo.capitalSocial) /
                          100,
                        "MONEY"
                      )}
                    </span>
                  )}
              </td>
              <td className="col-md-4">
                <p className="card-title-desc mb-2">
                  {props.t("Fundation date")}
                </p>
                {$filter(
                  getValueIfNotNull(
                    props.entity.additionalInfo,
                    "dataAbertura"
                  ),
                  "DATE"
                )}
              </td>
            </tr>

            <tr>
              <td className="col-md-4">
                <p className="card-title-desc mb-2">
                  {props.t("CNAE Principal")}
                </p>
                {props.entity.additionalInfo &&
                  props.entity.additionalInfo.cnaePrincipal && (
                    <span>
                      {props.entity.additionalInfo.cnaePrincipal.codigo}
                      &nbsp;-&nbsp;
                      {props.entity.additionalInfo.cnaePrincipal.descricao}
                    </span>
                  )}
              </td>

              {isAdm && (
                <td className="col-md-4" colSpan={2}>
                  <p className="card-title-desc mb-2">
                    Status do Plano de Acesso
                  </p>

                  <Col className="d-flex" style={{ gap: 8 }}>
                    <BadgeStatusSelect
                      name="isPlanOnline"
                      status="A"
                      defaultChecked={props.entity.isPlanOnline}
                      onChange={() => props.handleChange("isPlanOnline", true)}
                    />
                    <BadgeStatusSelect
                      name="isPlanOnline"
                      status="I"
                      defaultChecked={!props.entity.isPlanOnline}
                      onChange={() => props.handleChange("isPlanOnline", false)}
                    />
                  </Col>
                </td>
              )}
            </tr>

            {isAdm && (
              <tr>
                <td className="col-md-4" colSpan={1}>
                  <p className="card-title-desc mb-2">{props.t("Status")}</p>
                  <Col className="d-flex" style={{ gap: 8 }}>
                    <BadgeStatusSelect
                      name="status"
                      status="A"
                      defaultChecked={props.entity.status === "A"}
                      onChange={() => props.handleChange("status", "A")}
                    />
                    <BadgeStatusSelect
                      name="status"
                      status="I"
                      defaultChecked={props.entity.status === "I"}
                      onChange={() => props.handleChange("status", "I")}
                    />
                  </Col>
                </td>
                <td>
                  <span>{props.entity.nameAccessPlan}</span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}

export default withTranslation()(OrganizationEditIdentificationData);
