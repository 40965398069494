import DateTimeUtils from "./DateTimeUtils";
import DivisionRuleDomain from "../../domains/DivisionRuleDomain";
import DistributionTypeDomain from "../../domains/DistributionTypeDomain";

class AntecipationContractCalc {
  static calc(contract) {
    const newContract = Object.assign({}, contract);
    newContract["additionalInfo"] = Object.assign(
      {},
      contract["additionalInfo"]
    );
    newContract["guarantees"] = Object.assign([], contract["guarantees"]);
    newContract["isValid"] = true;
    contract["isValid"] = false;

    const self = AntecipationContractCalc;

    newContract["guarantees"] = self.calcGuarantees(newContract);
    self.calcContract(newContract);

    if (newContract["additionalInfo"]["mainValue"] < 0.01) return contract;

    newContract["log"] = {
      lastDiscountRate: newContract.additionalInfo.discountRate,
    };
    return newContract;
  }

  static calcContractWOGuarantees(contract, amountCharged, mainValue) {
    contract["debitBalance"] = 0;
    contract["additionalInfo"]["mainValue"] = 0;
    contract["additionalInfo"]["discountValue"] = 0;
    contract["additionalInfo"]["averageTerm"] = 0;

    contract["debitBalance"] = amountCharged;
    contract["additionalInfo"]["mainValue"] = mainValue;

    contract["additionalInfo"]["mainValue"] = parseFloat(
      contract["additionalInfo"]["mainValue"].toFixed(2)
    );
    contract["debitBalance"] = parseFloat(amountCharged);

    contract["guaranteedOperationLimit"] = contract["debitBalance"];
    contract["valueMaintained"] = contract["debitBalance"];

    contract["additionalInfo"]["discountValue"] =
      this.calcContractDiscountValue(contract);
    contract["additionalInfo"]["effectiveRate"] =
      this.calcContractEffectiveRateRate(contract);

    contract["dueDate"] = DateTimeUtils.addDays(new Date(), 30);
    contract["dueDate"] = DateTimeUtils.format(
      contract["dueDate"],
      "YYYY-MM-DD"
    );

    return contract;
  }

  static calcContract(contract, guarantees = null) {
    const gs = guarantees || contract.guarantees;

    contract["debitBalance"] = 0;
    contract["additionalInfo"]["mainValue"] = 0;
    contract["additionalInfo"]["discountValue"] = 0;

    for (const ga of gs) {
      contract["debitBalance"] += ga["amountCharged"];
      contract["additionalInfo"]["mainValue"] += ga["mainValue"];
    }

    contract["additionalInfo"]["mainValue"] = parseFloat(
      contract["additionalInfo"]["mainValue"].toFixed(2)
    );
    contract["debitBalance"] = parseFloat(contract["debitBalance"].toFixed(2));

    contract["guaranteedOperationLimit"] = contract["debitBalance"];
    contract["valueMaintained"] = contract["debitBalance"];

    contract["additionalInfo"]["discountValue"] =
      this.calcContractDiscountValue(contract);
    contract["additionalInfo"]["averageTerm"] =
      this.calcContractAverageTermWeighted(gs, contract);
    contract["additionalInfo"]["effectiveRate"] =
      this.calcContractEffectiveRateRate(contract);

    contract["guarantees"] = gs.sort(function (a, b) {
      let deadlineDiff = a["deadline"] - b["deadline"];

      if (deadlineDiff !== 0) return deadlineDiff;

      return a["discountValue"] - b["discountValue"];
    });

    if (contract["guarantees"].length) {
      contract["dueDate"] =
        contract["guarantees"][contract["guarantees"].length - 1]["endDate"];
    } else {
      contract["dueDate"] = DateTimeUtils.addDays(new Date(), 30);
      contract["dueDate"] = DateTimeUtils.format(
        contract["dueDate"],
        "YYYY-MM-DD"
      );
    }

    return contract;
  }

  static simSingleUr(contract, ur, discountRate = null, overridenVal = null) {
    const thisContract = { ...contract };
    const ga = {
      acquirers: [{ legalId: ur.legalIdAcquirer, name: ur.nameAcquirer }],
      arrangements: [{ code: ur.arrangementCode, label: ur.nameArrangement }],
      receivingEndUser: { legalId: ur.legalIdReceivingEndUser },
      holder: { legalId: contract.contractor.legalId },
      divisionRule: DivisionRuleDomain.VALOR_DEFINIDO,
      typeDistribution: DistributionTypeDomain.EMPILHAMENTO,
      startDate: DateTimeUtils.format(ur.settlementDate),
      endDate: DateTimeUtils.format(ur.settlementDate),
      amountCharged: ur.freeValue,
    };
    if (overridenVal) {
      ga.amountCharged = overridenVal;
    }
    discountRate =
      discountRate || thisContract.additionalInfo.discountRate || 0;

    thisContract.additionalInfo.discountRate = discountRate;

    ga["deadline"] = this.calcGuaranteeDeadline(contract, ga);
    ga["discountRate"] = this.calcGuaranteeDiscountRate(contract, ga);
    ga["mainValue"] = this.calcGuaranteeMainValue(ga);

    let invalidRate = false;
    if (ga["mainValue"] < 0.01) {
      ga["mainValue"] = parseFloat(
        ((ga["amountCharged"] * 1) / 100).toFixed(2)
      );
      invalidRate = true;
    }

    ga["discountValue"] = this.calcGuaranteeDiscountValue(ga);
    ga["effectiveRate"] = this.calcGuaranteeEffectiveRate(ga);
    if (invalidRate) ga["discountRate"] = ga["effectiveRate"];

    return ga;
  }

  static calcGuaranteeDeadline(contract, guarantee) {
    return DateTimeUtils.daysBetween(
      DateTimeUtils.parse(guarantee.endDate),
      DateTimeUtils.parse(contract.signatureDate)
    );
  }

  static calcGuaranteeDiscountRate(contract, guarantee) {
    return (
      ((contract["additionalInfo"]["discountRate"] / 30) *
        guarantee["deadline"] *
        100) /
      100
    );
  }

  static calcGuaranteeMainValue(guarantee) {
    return (
      guarantee["amountCharged"] -
      (guarantee["amountCharged"] * guarantee["discountRate"]) / 100
    );
  }

  static calcGuaranteeDiscountValue(guarantee) {
    const value = parseFloat(
      (guarantee["amountCharged"] - guarantee["mainValue"]).toFixed(2)
    );

    return value;
  }

  static calcContractDiscountValue(contract) {
    return parseFloat(
      (
        contract["debitBalance"] - contract["additionalInfo"]["mainValue"]
      ).toFixed(2)
    );
  }

  static calcContractAverageTerm(guarantees, sumDeadline) {
    return Math.ceil(sumDeadline / guarantees.length);
  }

  static calcContractAverageTermWeighted(guarantees, contract) {
    let sumWeighted = 0;

    for (const i in guarantees) {
      const ga = guarantees[i];

      const gaWeighted = ga["amountCharged"] * ga["deadline"];

      sumWeighted += gaWeighted;
    }

    return Math.floor(sumWeighted / contract.debitBalance);
  }

  static calcContractEffectiveRateRate(contract) {
    const value =
      (contract["additionalInfo"]["discountValue"] * 100) /
      contract["debitBalance"];
    return parseFloat(value.toFixed(2));
  }

  static calcGuaranteeEffectiveRate(guarantees) {
    const value =
      (guarantees["discountValue"] * 100) / guarantees["amountCharged"];
    return parseFloat(value.toFixed(2));
  }

  static calcGuarantees(contract) {
    const self = AntecipationContractCalc;

    let sumDeadline = 0;
    let lastDeadline = 0;

    const guarantees = contract["guarantees"];
    const eligibleGuarantees = [];

    for (const i in guarantees) {
      const ga = guarantees[i];

      ga["deadline"] = self.calcGuaranteeDeadline(contract, ga);
      ga["discountRate"] = self.calcGuaranteeDiscountRate(contract, ga);
      ga["mainValue"] = self.calcGuaranteeMainValue(ga);

      let invalidRate = false;
      if (ga["mainValue"] < 0.01) {
        ga["mainValue"] = parseFloat(
          ((ga["amountCharged"] * 1) / 100).toFixed(2)
        );
        invalidRate = true;
      }

      ga["discountValue"] = self.calcGuaranteeDiscountValue(ga);
      ga["effectiveRate"] = self.calcGuaranteeEffectiveRate(ga);
      if (invalidRate) ga["discountRate"] = ga["effectiveRate"];

      if (ga["discountValue"] > 0.01) {
        sumDeadline += ga["deadline"];
        if (ga["deadline"] > lastDeadline) lastDeadline = ga["deadline"];

        eligibleGuarantees.push(ga);
      }
    }

    return eligibleGuarantees;
  }
}

export default AntecipationContractCalc;
