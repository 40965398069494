import * as url from "../../helpers/url_helper";
import { HttpService } from "./HttpService";

export class ContractsService extends HttpService {
  constructor(http) {
    super(http);
  }

  async create(contract) {
    return this.post(url.POST_CONTRACT, contract);
  }

  async getAll({ page, limit, filters }) {
    return this.http.get(url.GET_CONTRACT, {
      "pagination.currentPage": page,
      "pagination.itemsPerPage": limit,
      externalReference: filters.externalReference,
    });
  }

  async getById(id) {
    return this.http.get(`${url.GET_CONTRACT}/${id}`);
  }

  async requestSignature(externalReference) {
    return this.http.post(url.REQUEST_CONTRACT_SIGNING, {
      entityId: externalReference,
    });
  }
}
