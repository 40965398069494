import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import { Label } from "reactstrap";

const animatedComponents = makeAnimated();

function Select2CreditModality(props) {
  const [options, setOptions] = useState([
    { label: props.t("Rotary"), value: "1" },
    { label: props.t("Installments"), value: "2" },
    { label: props.t("Assignment"), value: "3" },
  ]);

  function onChange(options) {
    if (props.onChange) props.onChange(options);
  }

  return (
    <div className="templating-select select2-container">
      {props.label !== null && props.label !== undefined ? (
        <Label for={props.id} className="form-label">
          {`${props.required === true ? "*" : ""}${props.label}`}
        </Label>
      ) : (
        ""
      )}
      <Select
        isMulti={
          props.isMulti !== null && props.isMulti !== undefined
            ? props.isMulti
            : false
        }
        classNamePrefix="select2-selection"
        className={props.className}
        closeMenuOnSelect={true}
        isSearchable={false}
        menuPlacement={props.menuPlacement}
        placeholder={
          props.placeholder !== null && props.placeholder !== undefined
            ? props.placeholder
            : ""
        }
        options={options}
        components={animatedComponents}
        onChange={(options) => {
          onChange(options);
        }}
        isDisabled={props.disabled}
        color={props.color}
        value={props.value}
      />
    </div>
  );
}

export default withTranslation()(Select2CreditModality);
