const ModalityDomain = {
  ROTATIVO: "1",
  PARCELADO: "2",
  CESSAO: "3",

  options: [
    { label: "Rotativo", value: "1" },
    { label: "Parcelado", value: "2" },
    { label: "Cessão", value: "3" },
  ],

  getLabelByValue(value) {
    for (const i in ModalityDomain.options) {
      if (ModalityDomain.options[i].value === value) {
        return this.options[i].label;
      }
    }
    return null;
  },
};

export default ModalityDomain;
