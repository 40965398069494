import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import ReceivablesTimelineChartChart from "./ReceivablesTimelineChartChart";
import DateTimeUtils from "../../../../common/utils/DateTimeUtils";
import classnames from "classnames";
import Loader from "../../../../components/Common/Loader";
import ReceivablesTimelineChartAcquirerByMonths from "./ReceivablesTimelineChartAcquirerByMonths";
import { receivablesService } from "../../../../services/http";

const ReceivablesScheduleGraphic = (props) => {
  const [tabs, setTabs] = useState([
    {
      title: props.t("Monthly volume"),
      onclick: (index) => {
        setActiveTab(index);
      },
    },
    {
      title: props.t("Free value"),
      onclick: (index) => {
        setActiveTab(index);
      },
    },
    {
      title: props.t("Volume per acquirer"),
      onclick: handleGetAcquirersByMonths,
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [receivableAcquirersByMonths, setReceivableAcquirersByMonths] =
    useState(null);
  const [hasLoadedAcquirersByMonths, setHasLoadedAcquirersByMonths] =
    useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const today = DateTimeUtils.format(new Date(), "DD/MM/YYYY");
  const tomorrow = DateTimeUtils.format(
    DateTimeUtils.subDays(new Date(), 1),
    "DD/MM/YYYY"
  );

  let lastUpdateDateOnly = DateTimeUtils.format(
    props.entity["lastUpdateDate"],
    "DD/MM/YYYY"
  );

  if (today === lastUpdateDateOnly) {
    lastUpdateDateOnly = "hoje";
  } else if (tomorrow === lastUpdateDateOnly) {
    lastUpdateDateOnly = "ontem";
  }

  const lastUpdateHourOnly = props.entity["lastUpdateTime"];

  function handleGetAcquirersByMonths() {
    getAcquirersByMonths();
  }

  const getAcquirersByMonths = async () => {
    if (hasLoadedAcquirersByMonths) return;

    setLoading(true);

    try {
      setHasLoadedAcquirersByMonths(true);

      const response = await receivablesService.getAllAcquirersUnitsByLegalId(
        props.entity.receivingEndUser.legalId
      );

      setReceivableAcquirersByMonths(response["receivableAcquirersByMonths"]);
      setActiveTab(2);
    } catch {
      //
    }

    setLoading(false);
  };

  return (
    <Col xl={9}>
      <Card className="card-height-100">
        <CardBody>
          <Row>
            <Col xl={9}>
              <Nav className="nav-tabs-custom nav-justified">
                {tabs.map((tab, index) => {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === index,
                        })}
                        onClick={() => {
                          tab.onclick(index);
                        }}
                      >
                        {tab.icon ? (
                          <i className={`bx ${tab.icon} font-size-20 mr-5`} />
                        ) : (
                          ""
                        )}
                        <span>{tab.title}</span>
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
            </Col>

            <Col lg={3} className="text-end">
              <p className="text-muted mb-0">
                {props.t("Last update on")}
                <br />
                {lastUpdateDateOnly && (
                  <span>
                    {lastUpdateDateOnly}&nbsp;{props.t("at")}&nbsp;
                    {lastUpdateHourOnly}
                  </span>
                )}
              </p>
              <br />
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <div className="mt-3">
                <TabContent className="p-3 text-muted" activeTab={activeTab}>
                  <TabPane tabId={0}>
                    <ReceivablesTimelineChartChart
                      receivableUnits={
                        props.entity["receivableUnitsByMonths"] || []
                      }
                      showFreeValue={false}
                      showTotalConstitutedValue={true}
                    />
                  </TabPane>

                  <TabPane tabId={1}>
                    <ReceivablesTimelineChartChart
                      receivableUnits={
                        props.entity["receivableUnitsAvailableByDay"] || []
                      }
                      showFreeValue={true}
                      showTotalConstitutedValue={false}
                    />
                  </TabPane>

                  <TabPane tabId={2}>
                    <ReceivablesTimelineChartAcquirerByMonths
                      receivableUnits={receivableAcquirersByMonths}
                    />
                  </TabPane>
                </TabContent>

                {loading ? <Loader loading={loading} /> : " "}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default withTranslation()(ReceivablesScheduleGraphic);
