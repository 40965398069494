import React from "react";

import { Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import $filter from "../../../common/utils/$filter";
import PaymentCompanyDomain from "../../../domains/PaymentCompanyDomain";

function AnticipationSummary(props) {
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <p className="card-title-desc mb-0">
            {props.t("Valor à liberar de crédito")}
          </p>
          <strong className="text-primary font-size-20">
            {$filter(props.contract.additionalInfo.mainValue, "money")}
          </strong>
        </Col>
      </Row>

      <hr style={{ borderTop: "1px solid rgb(187 187 187)" }} />

      <Row>
        <Col lg={12}>
          <p className="card-title-desc mb-0">{props.t("Customer")}</p>
          <span>{props.contract.contractor.name}</span>
        </Col>
      </Row>

      <hr style={{ borderTop: "1px solid rgb(187 187 187)" }} />

      <Row className="mb-2">
        <Col lg={6}>
          <p className="card-title-desc mb-0">{props.t("Valor à antecipar")}</p>
          <span>{$filter(props.contract.debitBalance, "money")}</span>
        </Col>

        <Col lg={6}>
          <p className="card-title-desc mb-0">{props.t("Valor de deságio")}</p>
          <span>
            {$filter(props.contract.additionalInfo.discountValue, "money")}
          </span>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col lg={6}>
          <p className="card-title-desc mb-0">{props.t("Taxa efetiva")}</p>
          <span>
            {$filter(props.contract.additionalInfo.effectiveRate, "PRECENT")}
          </span>
        </Col>

        <Col lg={6}>
          <p className="card-title-desc mb-0">{props.t("Prazo médio")}</p>
          <span>{props.contract.additionalInfo.averageTerm} dias</span>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col lg={12}>
          <p className="card-title-desc mb-0">
            {props.t("Taxa de deságio (a.m)")}
          </p>
          <span>
            {$filter(props.contract.additionalInfo.discountRate, "PRECENT")}
          </span>
        </Col>
      </Row>

      <hr style={{ borderTop: "1px solid rgb(187 187 187)" }} />

      {props.contract.contractor.bankAccount.ispb ? (
        <Row className="mb-3">
          <Col lg={12}>
            <p className="card-title-desc mb-2">
              Conta para liberação do crédito
            </p>

            <p className="card-title-desc mb-1">
              <strong>{props.contract.contractor.name}</strong>
            </p>

            <strong>
              {PaymentCompanyDomain.getLabelByCode(
                props.contract.contractor.bankAccount.ispb
              )}
            </strong>
            <p className="card-title-desc mb-1">
              Agência&nbsp;{props.contract.contractor.bankAccount.branch}&nbsp;
              Conta&nbsp;{props.contract.contractor.bankAccount.accountNumber}
              {props.contract.contractor.bankAccount.accountDigit
                ? `-${props.contract.contractor.bankAccount.accountDigit}`
                : ""}
            </p>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <hr style={{ borderTop: "1px solid rgb(187 187 187)" }} />

      {props.contract.creditorAccount.ispb ? (
        <Row className="mb-3">
          <Col lg={12}>
            <p className="card-title-desc mb-2">
              Conta para mudança de titularidade no credenciador
            </p>

            <p className="card-title-desc mb-1">
              <strong>{props.contract.creditorAccount.ownerName}</strong>
            </p>

            <strong>
              {PaymentCompanyDomain.getLabelByCode(
                props.contract.creditorAccount.ispb
              )}
            </strong>
            <p className="card-title-desc mb-1">
              Agência&nbsp;{props.contract.creditorAccount.branch}&nbsp;
              Conta&nbsp;{props.contract.creditorAccount.accountNumber}
              {props.contract.creditorAccount.accountDigit
                ? `-${props.contract.creditorAccount.accountDigit}`
                : ""}
            </p>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <hr style={{ borderTop: "1px solid rgb(187 187 187)" }} />
    </React.Fragment>
  );
}

export default withTranslation()(AnticipationSummary);
