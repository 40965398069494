import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { withTranslation } from "react-i18next";
import $filter from "../../../../common/utils/$filter";
import { getSessionUserProfile } from "../../../../helpers/api_helper";
import { USER_PROFILES } from "../../../../domains/UserProfileDomain";

function GeneralBalanceReport(props) {
  const [userProfile] = useState(getSessionUserProfile());

  const widget = [];

  if (userProfile !== USER_PROFILES.SELLER) {
    widget.push({
      id: widget.length,
      title: "Limite de Crédito",
      count: $filter(props.entity["creditLimit"], "MONEY"),
      icon: "fas fa-dollar-sign text-success",
    });
    widget.push({
      id: widget.length,
      title: "Limite de Crédito Disponível",
      count: $filter(props.entity["availableLimit"], "MONEY"),
      icon: "fas fa-dollar-sign text-success",
    });
    widget.push({
      id: widget.length,
      title: "Limite de Crédito Tomado",
      count: $filter(props.entity["usedCreditLimit"], "MONEY"),
      icon: "fas fa-dollar-sign text-success",
    });
  } else {
    widget.push({
      id: widget.length,
      title: "Saldo Disponível de Recebíveis",
      count: $filter(props.entity["balanceAvailable"], "MONEY"),
      icon: "fas fa-dollar-sign text-success",
    });
  }

  widget.push({
    id: widget.length,
    title: "Limite Parcela",
    count: $filter(props.entity["installmentCreditLimit"], "MONEY"),
    icon: "fas fa-dollar-sign text-success",
  });

  // if (props.entity['daysBlockedSchedule'] && props.entity['daysBlockedSchedule'] > 0) {
  //     widget.push({
  //         id: widget.length,
  //         title: 'Bloqueio de Agenda',
  //         count: `${props.entity['daysBlockedSchedule']} dias`,
  //         icon: 'mdi mdi-cash-multiple text-danger',
  //     })
  // }
  widget.push({
    id: widget.length,
    title: "Saldo Próximos 30 dias",
    count: $filter(props.entity["balanceNext30days"], "MONEY"),
    icon: "fas fa-dollar-sign text-primary",
  });
  widget.push({
    id: widget.length,
    title: "Volume últimos 30 dias",
    count: $filter(props.entity["volumeLast30days"], "MONEY"),
    icon: "fas fa-dollar-sign text-success",
  });
  widget.push({
    id: widget.length,
    title: "Volume Médio Mensal",
    count: $filter(props.entity["averageMonthlyVolume"], "MONEY"),
    icon: "fas fa-dollar-sign text-primary",
  });
  widget.push({
    id: widget.length,
    title: "Pré Antecipações",
    count: $filter(props.entity["valueConstitutedAnticipationPre"], "MONEY"),
    icon: "fas fa-dollar-sign text-primary",
  });

  // if (userProfile !== USER_PROFILES.SELLER) {
  //     widget.push({
  //         id: widget.length,
  //         title: 'Prazo Médio nas Maquininhas',
  //         count: `${props.entity['averageTerm']} dias`,
  //         icon: 'fas fa-dollar-sign alert-icon font-size-20',
  //     })
  // }

  const isLoading = props.loading;

  return (
    <Col xl={3}>
      <Card className="card-height-100">
        <CardBody>
          {widget.map((widget, key) => (
            <Row key={key}>
              <Col lg={1}>
                <div className="float-start">
                  <div className="avatar-sm mx-auto mb-4">
                    {/*<span className="avatar-title rounded-circle bg-light font-size-24">*/}
                    {/*    <i className={widget.icon}></i>*/}
                    {/*</span>*/}
                  </div>
                </div>
              </Col>
              <Col lg={10}>
                <div>
                  <p className="text-muted text-uppercase mb-0">
                    {widget.title}
                  </p>

                  {!isLoading ? (
                    <h4 className="mb-1">
                      <span className="counter-value">{widget.count}</span>
                    </h4>
                  ) : (
                    <h5
                      className="card-title placeholder-glow"
                      style={{
                        color: "#cccc",
                      }}
                    >
                      <span className="placeholder col-6"> </span>
                    </h5>
                  )}
                </div>
              </Col>
            </Row>
          ))}
        </CardBody>
      </Card>
    </Col>
  );
}

export default withTranslation()(GeneralBalanceReport);
