import Swal from "sweetalert2";

export const showExceededPlanModal = () => {
  const html = `<div>
                <p className="card-title-desc mb-0">
                   Parece que você excedeu o seu plano de acesso. <br>
                </p>
                <p>
                    Contate nosso setor financeiro para acessar um plano que se ajuste melhor a sua necessidade!
                </p>
                <p>
                    <a href="mailto:contato@cashtf.com">contato@cashtf.com</a>
                </p>
            </div>`;

  Swal.fire({
    title: "Plano de Acesso",
    icon: "info",
    html: html,
    confirmButtonText: "Certo!",
    cancelButtonText: "Cancelar...",
  });
};
