import { Card, CardBody, Col, NavItem, NavLink, Nav, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import SummarizedVisionUnits from "./SummarizedVisionUnits";
import AnalyticalVisionUnits from "./AnalyticalVisionUnits";
import EndUserObligationsView from "./EndUserObligationsView";
import { useReceivableUnitsTabs } from "../hooks";
import Loader from "../../../../components/Common/Loader";
import { useMemo } from "react";

function ReceivablesUnitsAnalytcs({ t, receivable }) {
  const { isLoading, tabs, units, activeTab, selectTab } =
    useReceivableUnitsTabs({ t: t, receivable: receivable });

  const tabsContent = useMemo(
    () => ({
      [tabs[0].value]: () => <SummarizedVisionUnits entity={receivable} />,
      [tabs[1].value]: () => (
        <AnalyticalVisionUnits receivableUnitsAvailable={units.availables} />
      ),
      [tabs[2].value]: () => (
        <AnalyticalVisionUnits receivableUnitsAvailable={units.compromiseds} />
      ),
      [tabs[3].value]: () => (
        <AnalyticalVisionUnits
          receivableUnitsAvailable={units.futureCommitments}
        />
      ),
      [tabs[4].value]: () => (
        <EndUserObligationsView endUserObligations={units.obligations} />
      ),
    }),
    [receivable, units]
  );

  const ActiveTabContent = tabsContent[activeTab];

  return (
    <>
      <Card>
        <CardBody>
          <Row className="mb-3">
            <Col xl={12}>
              <Nav className="nav-tabs-custom nav-justified">
                {tabs.map((tab) => (
                  <NavItem key={tab.value}>
                    <NavLink
                      className={classnames({
                        active: activeTab === tab.value,
                      })}
                      onClick={() => selectTab(tab.value)}
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <span>{tab.title}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <ActiveTabContent />
            </Col>
          </Row>
        </CardBody>
      </Card>

      {isLoading && <Loader loading={isLoading} />}
    </>
  );
}

export default withTranslation()(ReceivablesUnitsAnalytcs);
