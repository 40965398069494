import StatusDomain from "../../domains/StatusDomain";

export const BadgeStatusSelect = ({ status, ...rest }) => (
  <label
    className={`${StatusDomain.getClassFromValue(status)} d-flex`}
    style={{ gap: 8, cursor: "pointer" }}
  >
    <input type="radio" {...rest} />
    {StatusDomain.getNameFromValue(status)}
  </label>
);
