import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { Badge, Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import InputText from "../../../components/Common/InputText";
import Select from "react-select";
import * as url from "../../../helpers/url_helper";
import PaginationService from "../../../components/Common/PaginationService";
import { isObject } from "lodash";
import $filter from "../../../common/utils/$filter";
import Select2Async from "../../../components/Common/Select2Async";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import OptinViewAdmin from "./OptinViewAdmin";
import InputDateRange from "../../../components/Common/InputDateRange";
import InputDate from "../../../components/Common/InputDate";

import qs from "qs";
import GenerateStatusBadge from "./GenerateStatusBadge";
function OptinV2(props) {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenId, setIsOpenId] = useState(null);
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setIsOpenId(row.id);
      setIsOpen(!isOpen);
    },
    onMouseEnter: (e, row, rowIndex) => {},
  };
  const columns = [
    {
      dataField: "externalReference",
      text: "Codigo de Referência",
    },
    {
      dataField: "startDate",
      text: props.t("Start date"),
      formatter: (cell, row) => (
        <span key={`${row.id}-startDate`}>{$filter(cell, "DATE")}</span>
      ),
    },
    {
      dataField: "endDate",
      text: props.t("End date"),
      formatter: (cell, row) => (
        <span key={`${row.id}-endDate`}>{$filter(cell, "DATE")}</span>
      ),
    },
    {
      dataField: "optoutDate",
      text: " Opt-out",
      formatter: (cell, row) => (
        <span id={`${row.id}-optoutDate`}>{$filter(cell, "DATE")}</span>
      ),
    },
    {
      dataField: "nameFinancialAgent",
      text: "Agente Financeiro",
      formatter: (cell, row) => (
        <span title={cell}>
          <p key={`${row.id}-nameFinancialAgent`} about={cell}>
            {cell}
          </p>
          <p key={`${row.id}-legalIdFinancialAgent`}>
            {$filter(row?.legalIdFinancialAgent, "CPF_CNPJ")}
          </p>
        </span>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <GenerateStatusBadge status={cell} id={row.id} />
      ),
    },
    {
      dataField: "nameReceivingEndUser",
      text: "Cliente Final",
      formatter: (cell, row) => (
        <span>
          <p key={`${row.id}-nameReceivingEndUser`} about={cell} title={cell}>
            {cell}
          </p>
          <p key={`${row.id}-legalIdReceivingEndUser`}>
            {$filter(row.legalIdReceivingEndUser, "CPF_CNPJ")}
          </p>
        </span>
      ),
    },
  ];

  let query = useQuery();
  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState(PaginationService.defaultFilter(10, 1));
  const [loading, setLoading] = useState(false);
  const [statusSelects, setStatusSelects] = useState({});
  const [error, setError] = useState(null);

  const [totalOfItems, setTotalOfItems] = useState(0);
  const applySearch = async (
    currentPage,
    itemsPerPage,
    filterDefault = filter
  ) => {
    try {
      const { pagerResult, filterResult } =
        await PaginationService.applySearchV2(
          url.GET_OPTIN_ADMIN,
          "optinAdmin",
          filterDefault,
          currentPage,
          itemsPerPage,
          setLoading
        );
      setPager(pagerResult);
      setFilter(filterResult);
      updateQueryUrl(filterResult);
      setTotalOfItems(pagerResult?.pagination?.totalOfItems || 0);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") {
        setError(e);
      }
    }
  };

  const handleChangeFilter = (event, field) => {
    filter[field] = event.target.value;

    // setFilterField("filter", filter);
    setFilter(filter);
  };

  const handleChangeFilterRangeCalendar = (event, field) => {
    if (!filter[field]) {
      filter[field] = {};
    }

    filter[field].init = event?.init?.value;
    filter[field].end = event?.end?.value;
    // setFilterField("filter", filter);
    setFilter(filter);
  };

  const handlerSelect = (event, field) => {
    filter[field] = event?.value || undefined;
    setStatusSelects((v) => ({
      ...v,
      [field]: event,
    }));
    // setFilterField("filter", filter);
    // setFilter(filter);
    setFilter((_f) => ({
      ..._f,
      ...filter,
    }));
    applySearch();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPagination2(filter));
    applySearch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    const filterInit = PaginationService.defaultFilter(20, 1);
    setStatusSelects((v) => ({
      ...v,
      status: null,
      legalIdRequester: null,
      legalIdFinancialAgent: null,
      legalIdReceivingEndUser: null,
    }));
    document.getElementById("searchForm").reset({
      externalReference: undefined,
      startDate: undefined,
      endDate: undefined,
      legalIdRequester: undefined,
      legalIdFinancialAgent: undefined,
      legalIdReceivingEndUser: undefined,
      status: undefined,
      optoutDate: undefined,
    });

    await setFilter((_f) => ({
      ..._f,
      ...filterInit,
    }));
    await applySearch(
      filterInit["pagination.currentPage"],
      filterInit["pagination.itemsPerPage"],
      filterInit
    );
  };

  const updateQueryUrl = (filter) => {
    if (filter) {
      const formatquery = qs.stringify(filter);

      props.history.push({
        search: formatquery,
      });
    }
  };

  useEffect(() => {
    if (!query.size) {
      applySearch();
      return;
    }

    async function fetchData() {
      const filterInit = qs.parse(query.toString());
      await setFilter((f) => ({
        ...f,
        ...filterInit,
      }));
      await applySearch(
        filterInit["pagination.currentPage"],
        filterInit["pagination.itemsPerPage"],
        filterInit
      );
      setStatusSelects((v) => ({
        ...v,
        status: {
          value: filterInit?.status,
        },
        legalIdRequester: {
          value: filterInit?.legalIdRequester || undefined,
          label: filterInit?.legalIdRequester || undefined,
        },
        legalIdFinancialAgent: {
          value: filterInit?.legalIdFinancialAgent || undefined,
          label: filterInit?.legalIdFinancialAgent || undefined,
        },
        legalIdReceivingEndUser: {
          value: filterInit?.legalIdReceivingEndUser,
          label: filterInit?.legalIdReceivingEndUser,
        },
      }));
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("COMPANY | Opt-in")}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Opt-in")}
            breadcrumbItem={props.t("Manage opt-in")}
          />
          <Form
            id="searchForm"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            default={{
              externalReference: undefined,
              startDate: undefined,
              endDate: undefined,
              legalIdRequester: undefined,
              legalIdFinancialAgent: undefined,
              legalIdReceivingEndUser: undefined,
              status: undefined,
              optoutDate: undefined,
              optinDate: undefined,
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t("Filters parameters")}</p>
                    <Row>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <InputText
                          id="externalReference"
                          onChange={(event) =>
                            handleChangeFilter(event, "externalReference")
                          }
                          placeholder={props.t("Reference Code")}
                          autoComplete="off"
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <Select2Async
                          url={url.GET_FINANCIAL_AGENT_PAGINATION}
                          orderBy={"name"}
                          onChange={(event) =>
                            handlerSelect(event, "legalIdFinancialAgent")
                          }
                          id="legalIdFinancialAgent"
                          placeholder={"CNPJ Agente de Financeiro"}
                          value={statusSelects["legalIdFinancialAgent"]}
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <Select2Async
                          url={url.GET_RECEIVING_END_USER_PAGINATION}
                          orderBy={"name"}
                          onChange={(event) =>
                            handlerSelect(event, "legalIdReceivingEndUser")
                          }
                          id="legalIdReceivingEndUser"
                          placeholder={"CNPJ Cliente Final"}
                          value={statusSelects["legalIdReceivingEndUser"]}
                          filters={{
                            legalIdFinancialAgent:
                              statusSelects?.legalIdFinancialAgent?.value,
                          }}
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <div className="templating-select select2-container">
                          <Select
                            id="statusOptin"
                            isMulti={false}
                            classNamePrefix="select2-selection"
                            isSearchable={false}
                            onChange={(event) => handlerSelect(event, "status")}
                            placeholder={props.t("Status")}
                            options={[
                              { label: "Ativo", value: "A" },
                              { label: "Inativo", value: "I" },
                              { label: "Aguardando assinatura", value: "S" },
                              { label: "Pendente", value: "P" },
                              { label: "Com Error", value: "E" },
                            ]}
                            value={statusSelects["status"]}
                            isClearable={true}
                          />
                        </div>
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <InputDate
                          id="startDate"
                          onChange={(event) =>
                            handleChangeFilterRangeCalendar(event, "startDate")
                          }
                          placeholder="Data Inicial"
                          options={{
                            mode: "range",
                          }}
                          values={[
                            filter?.["startDate"]?.init,
                            filter?.["startDate"]?.end,
                          ]}
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <InputDate
                          id="endDate"
                          onChange={(event) =>
                            handleChangeFilterRangeCalendar(event, "endDate")
                          }
                          placeholder="Data Final"
                          options={{
                            mode: "range",
                          }}
                          values={[
                            filter?.["endDate"]?.init,
                            filter?.["endDate"]?.end,
                          ]}
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <InputDate
                          id="optinDate"
                          onChange={(event) =>
                            handleChangeFilterRangeCalendar(event, "optinDate")
                          }
                          placeholder="Opt-in"
                          values={[
                            filter?.["optinDate"]?.init,
                            filter?.["optinDate"]?.end,
                          ]}
                          options={{
                            mode: "range",
                          }}
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <InputDate
                          id="optoutDate"
                          onChange={(event) =>
                            handleChangeFilterRangeCalendar(event, "optoutDate")
                          }
                          placeholder="Opt-out"
                          options={{
                            mode: "range",
                          }}
                          values={[
                            filter?.["optoutDate"]?.init,
                            filter?.["optoutDate"]?.end,
                          ]}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <hr />
                    </Row>
                    <Row className="mt-4">
                      <Col lg={4} className="col-sm-auto offset-sm-8">
                        <Row
                          className="row-sm-auto text-end"
                          style={{
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className="btn btn-sm btn-light w-sm waves-effect waves-ligh col-auto mr-5"
                            onClick={(e) => clearFilters(e)}
                            title="Limpar Filtros"
                          >
                            Limpar Filtros
                          </button>
                          <button
                            type="submit"
                            title="Aplicar Filtros"
                            className="btn btn-sm btn-primary w-sm waves-effect waves-primary col-auto"
                          >
                            {props.t("Apply Search")}
                          </button>
                        </Row>
                      </Col>
                    </Row>

                    <Row
                      className="mt-4"
                      style={{
                        overflowX: "auto",
                      }}
                    >
                      <Col lg={12}>
                        <PaginationProvider
                          pagination={paginationFactory({
                            onSizePerPageChange: (sizePerPage, page) => {
                              filter["pagination.itemsPerPage"] = sizePerPage;
                              filter["pagination.currentPage"] = page;
                              setFilter(filter);
                              applySearch(page, sizePerPage, {
                                ...filter,
                              });
                            },
                            onPageChange: (page, sizePerPage) => {
                              filter["pagination.itemsPerPage"] = sizePerPage;
                              filter["pagination.currentPage"] = page;
                              setFilter(filter);
                              applySearch(page, sizePerPage, {
                                ...filter,
                              });
                            },
                            page: filter["pagination.currentPage"],
                            sizePerPage: filter["pagination.itemsPerPage"],
                            totalSize: totalOfItems,
                            showTotal: true,
                          })}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <div>
                              <BootstrapTable
                                onTableChange={(type, newState) => {
                                  return;
                                }}
                                keyField="id"
                                remote={true}
                                data={pager.results || []}
                                loading={true}
                                showTotal={true}
                                columns={columns}
                                rowEvents={rowEvents}
                                {...paginationTableProps}
                              />
                            </div>
                          )}
                        </PaginationProvider>
                      </Col>
                      <OptinViewAdmin
                        key={"OptinViewAdmin"}
                        isOpen={isOpen}
                        toggle={setIsOpen}
                        afterClosed={applySearch}
                        entityId={isOpenId}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(OptinV2));
