import React, { useEffect, useState } from "react";

import {
  Col,
  Modal,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import Loader from "../../components/Common/Loader";
import classnames from "classnames";
import InputDate from "../../components/Common/InputDate";
import InputText from "../../components/Common/InputText";
import LegalIdInput from "../../components/Common/LegalIdInput";
import Select2EffectType from "../../components/Common/Select2EffectType";
import Select2CreditModality from "../../components/Common/Select2CreditModality";
import Select2YesOrNo from "../../components/Common/Select2YesOrNo";
import Select2Banks from "../../components/Common/Select2Banks";
import Select2AccountType from "../../components/Common/Select2AccountType";
import SelectEndUserReceivables from "../../components/Common/SelectEndUserReceivables";
import InputMoney from "../../components/Common/InputMoney";
import { get, getUserSession, post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import DateTimeUtils from "../../common/utils/DateTimeUtils";
import EvaluationTypeDomain from "../../domains/EvaluationTypeDomain";
import ServiceTypeDomain from "../../domains/ServiceTypeDomain";
import UnconditionalAcceptDomain from "../../domains/UnconditionalAcceptDomain";
import Select2DiscountRates from "../../components/Common/Select2DiscountRates";
import InputPercent from "../../components/Common/InputPercent";
import AntecipationContractCalc from "../../common/utils/AntecipationContractCalc";
import ErrorDialog from "../../components/Common/ErrorDialog";
import Select2Product from "../../components/Common/Select2Product";
import ProductTypeDomain from "../../domains/ProductTypeDomain";
import Select2Arrangements from "../../components/Common/Select2Arrangements";
import $filter from "../../common/utils/$filter";
import Select2PaymentPeriod from "../../components/Common/Select2PaymentPeriod";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Select2Acquirers from "../../components/Common/Select2Acquirers";
import CpfCnpjUtils from "../../common/utils/CpfCnpjUtils";
import StringUtils from "../../common/utils/StringUtils";
import { contractsService } from "../../services/http";

function ManageContractNew(props) {
  const [loading, setLoading] = useState(false);
  const [state] = useState({
    form: {},

    tabs: [
      { title: props.t("Contract information") },
      { title: props.t("Payment data") },
      { title: props.t("Credit and Guarantees") },
    ],
  });

  const financialAgent = getUserSession()["financialAgent"];
  const registerAgent = getUserSession()["registerAgent"];
  const [modalAddGuarantee, setModalAddGuarantee] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [baseDate, setBaseDate] = useState(null);
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [entity, setEntity] = useState({
    registerAgent: registerAgent,
    financialAgent: financialAgent,
    creditorAccount: {
      taxIdOwner: financialAgent.legalId,
      bankCode:
        financialAgent &&
        financialAgent.bankAccounts &&
        financialAgent.bankAccounts.length > 0
          ? financialAgent.bankAccounts[0].bankCode
          : null,
      accountType:
        financialAgent &&
        financialAgent.bankAccounts &&
        financialAgent.bankAccounts.length > 0
          ? financialAgent.bankAccounts[0].accountType
          : null,
      branch:
        financialAgent &&
        financialAgent.bankAccounts &&
        financialAgent.bankAccounts.length > 0
          ? financialAgent.bankAccounts[0].branch
          : null,
      ispb:
        financialAgent &&
        financialAgent.bankAccounts &&
        financialAgent.bankAccounts.length > 0
          ? financialAgent.bankAccounts[0].ispb
          : null,
      accountNumber:
        financialAgent &&
        financialAgent.bankAccounts &&
        financialAgent.bankAccounts.length > 0
          ? financialAgent.bankAccounts[0].accountNumber
          : null,
      accountDigit:
        financialAgent &&
        financialAgent.bankAccounts &&
        financialAgent.bankAccounts.length > 0
          ? financialAgent.bankAccounts[0].accountDigit
          : null,
      ownerName: financialAgent.name,
    },
    guarantees: [],
    signatureDate: DateTimeUtils.format(new Date(), "YYYY-MM-DD"),
    contractor: {
      legalId: null,
    },
    effectType: null,
    serviceType: ServiceTypeDomain.SIMPLES,
    unconditionalAccept: UnconditionalAcceptDomain.ACCEPT,
    evaluationType: EvaluationTypeDomain.BASIC,
    dueDate: null,
    modality: null,
    renegotiation: false,
    debitBalance: null,
    guaranteedOperationLimit: null,
    valueMaintained: null,
    additionalInfo: {
      product: "AR",
      productName: "Antecipação de Recebíveis",
      productModality: "1",
      averageTerm: 0,
      discountRate: 0,
      admRate: 0.0,
      effectiveRate: 0,
      discountValue: 0,
      mainValue: 0,
      payments: null,
    },
    fine: 0, //multa
    fee: 0, //juros
    arrangements: [],
    acquirers: [],
  });

  const [guaranteedAmount, setGuaranteedAmount] = useState(0);

  const [contractorAcquirers, setContractAcquirers] = useState(null);
  const [contractorArrangements, setContractArrangements] = useState(null);

  const calcInstallmentValueCG = (numberInstallments) => {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    let pmt, pvif;
    let fv = 0;
    const type = 0;

    const pv = entity.debitBalance;
    const np =
      numberInstallments ||
      entity.additionalInfo.payments.paymentPeriodSelected.numberInstallments ||
      0;

    if (entity.fee === 0) return -(pv + fv) / np;

    const ir = entity.fee / 100 / 100;

    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;

    return pmt >= 0 ? pmt : pmt * -1;
  };

  const calcFee = () => {
    const main = Number(entity.debitBalance) || 0;
    let fee = 0;
    if (
      !baseDate ||
      !entity.fee ||
      !entity.additionalInfo.payments ||
      !entity.additionalInfo.payments.paymentPeriodSelected.numberInstallments
    ) {
      return fee;
    }
    if (entity.additionalInfo.product === "CG") {
      return (
        calcInstallmentValueCG() *
          entity.additionalInfo.payments.paymentPeriodSelected
            .numberInstallments -
        entity.debitBalance
      );
    } else {
      if (baseDate && entity.fee) {
        const deadline = DateTimeUtils.daysBetween(
          DateTimeUtils.parse(new Date()),
          DateTimeUtils.parse(baseDate)
        );

        fee = main * ((Number(entity.fee) || 0) / 100) * ((deadline || 0) / 30);
      }
    }
    return fee;
  };

  const getGuaranteedAmount = () => {
    const main = Number(entity.debitBalance) || 0;
    const fine = main * ((Number(entity.fine) || 0) / 100);
    const fee = calcFee();

    return Number(main + fine + fee);
  };

  const setDiscountRate = (value) => {
    if (!entity.additionalInfo) {
      entity.additionalInfo = {};
    }
    entity.additionalInfo.discountRate = value;
    setEntity(entity);
  };

  const openModalAddGuarantee = () => {
    setModalAddGuarantee(true);
  };

  function closeModalAddGuarantee(result) {
    if (result.entity && result.entity.length) {
      const gs = result.entity.map((g) => {
        g.mainValue = AntecipationContractCalc.calcGuaranteeMainValue({
          amountCharged: g.amountCharged,
          discountRate: g.originalSim.discountRate,
        });
        g.endDate = g.originalSim.endDate;
        return g;
      });

      setEntity({ ...entity, guarantees: gs });
      setGuaranteedAmount(getGuaranteedAmount());
    }
    setModalAddGuarantee(false);
  }

  function validateTab(tab) {
    const validators = {
      1: () => {
        return (
          !!entity.contractor.legalId &&
          !!entity.effectType &&
          !!entity.modality &&
          entity.renegotiation !== null &&
          entity.renegotiation !== undefined
        );
      },
      2: () => {
        return (
          !!entity.creditorAccount.legalId &&
          !!entity.creditorAccount.ispb &&
          !!entity.creditorAccount.accountType &&
          !!entity.creditorAccount.branch &&
          !!entity.creditorAccount.accountNumber
        );
      },
      3: () => {
        return (
          entity.debitBalance !== null &&
          (entity.guarantees.length || entity.arrangements.length)
        );
      },
    };

    return validators[tab]();
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        if (!validateTab(tab - 1)) return;
        let modifiedSteps = [...passedSteps, tab];
        setActiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  function toggleModal() {
    setIsOpen(false);
  }

  function afterClosed() {
    if (props.callback) props.callback({ isOpen: false, entity: entity });
  }

  function handleFieldEntity(event) {
    entity[event.target.name] = event.target.value;
    setEntity(entity);
  }

  function handleFieldEntityInline(entityInline, fieldname, event) {
    entity[entityInline][fieldname] = event.target.value;
    setEntity(entity);
  }

  function handleFieldEntitySelectOptions(fieldname, option) {
    entity[fieldname] = option["value"];
    setEntity(entity);
  }

  const buildGuarantees = (contract) => {
    return contract.guarantees.map((g) => {
      return {
        acquirers: g.originalSim.acquirers,
        arrangements: g.originalSim.arrangements,
        receivingEndUser: g.originalSim.receivingEndUser,
        holder: g.originalSim.holder,
        divisionRule: g.originalSim.divisionRule,
        typeDistribution: g.originalSim.typeDistribution,
        startDate: g.originalSim.startDate,
        endDate: g.originalSim.endDate,
        amountCharged: g.mainValue,
      };
    });
  };

  const [productsTax, setProductsTax] = useState(null);
  const getProductsTax = async () => {
    const response = await get(
      `${url.GET_SEGMENT_COMPANY}?pagination.currentPage=0&pagination.itemsPerPage=100`,
      null,
      null,
      null,
      null
    );
    if (response && response["segment"]) {
      const prodOptions = {};
      for (const i in response["segment"]["products"]) {
        const prod = response["segment"]["products"][i];

        if (prod.active) {
          prodOptions[prod.product] = {
            tax: prod.tax,
            paymentMethod: prod.product,
          };

          prodOptions[prod.product]["paymentPeriods"] = prod.paymentPeriod.map(
            (pp) => {
              const numberInstallments = pp.split("/").length;

              return {
                paymentPeriod: pp,
                numberInstallments: numberInstallments,
              };
            }
          );
        }
      }
      setProductsTax(prodOptions);
    } else {
      setProductsTax({});
    }
  };

  const getContractorAcquirers = async () => {
    if (!entity || !entity.contractor || !entity.contractor.legalId) return;

    const response = await get(
      `${url.GET_CONTRACTOR_ACQUIRER_ADMIN}/${entity.contractor.legalId}`,
      null,
      null,
      setLoading,
      null
    );
    if (response && response["acquirers"]) {
      setContractAcquirers(response["acquirers"]);
    }
  };

  const getContractorArrangements = async () => {
    if (!entity || !entity.contractor || !entity.contractor.legalId) return;
    const response = await get(
      `${url.GET_CONTRACTOR_ARRANGEMENT_ADMIN}/${entity.contractor.legalId}`,
      null,
      null,
      setLoading,
      null
    );
    if (response && response["arrangements"]) {
      setContractArrangements(
        response["arrangements"].map((a) => {
          return {
            code: a.code,
            value: a.code,
            label: a.name,
          };
        })
      );
    }
  };

  const setPaymentPeriodOptionFields = (option) => {
    option["amount"] = guaranteedAmount;
    option["installmentMainAmount"] = parseFloat(
      calcInstallmentValueCG(option["numberInstallments"])
    ).toFixed(2);
    option["installmentAmount"] =
      entity.debitBalance / option.numberInstallments;

    option["invalidMessage"] = null;

    return option;
  };

  useEffect(() => {
    getProductsTax().then();
  }, [props.isOpen]);

  useEffect(() => {
    if (!entity || !entity.contractor || !entity.contractor.legalId) return;
    else if (StringUtils.validCpfCnpj(entity.contractor.legalId)) {
      Promise.all([
        getContractorAcquirers(),
        getContractorArrangements(),
      ]).then();
    }
  }, [entity.contractor.legalId]);

  async function save() {
    // console.log('before', entity);
    if (activeTab !== state.tabs.length) {
      toggleTab(activeTab + 1);
    } else {
      try {
        let newContract = null;
        if (entity.guarantees && entity.guarantees.length) {
          newContract = AntecipationContractCalc.calcContract(entity);
          newContract.guarantees = buildGuarantees(newContract);
        } else {
          newContract = AntecipationContractCalc.calcContractWOGuarantees(
            entity,
            entity.debitBalance,
            guaranteedAmount
          );
        }
        newContract.additionalInfo.fine = entity.fine;
        newContract.additionalInfo.fee = entity.fee;
        newContract.additionalInfo.baseDate = baseDate;
        if (newContract.additionalInfo.payments) {
          newContract.additionalInfo.payments = calcInstallments(
            newContract.additionalInfo.payments
          );
          if (
            newContract.additionalInfo.payments.installments &&
            newContract.additionalInfo.payments.installments.length
          ) {
            newContract.installments =
              newContract.additionalInfo.payments.installments;
          }
        }
        // console.log('after', newContract);
        setLoading(true);

        await contractsService.create(newContract);

        // console.log('response', response);
        toggleModal();
      } catch (e) {
        if (e["status"] !== "200") {
          if (e.violations && e.violations.length) {
            if (
              e.violations.filter(
                (v) => v["property"] === "accessPlan.allowContract"
              )
            ) {
              openAccessPlanExcededModal();
            } else if (
              e.violations.filter(
                (v) => v["property"] === "accessPlan.isPlanOnline"
              )
            ) {
              openAccessPlanActiveModal();
            }
          }
        } else {
          setError({
            title: "Erro ao processar contrato",
            detail: e.message,
            code: 500,
          });
        }
      }

      setLoading(false);
    }
  }

  const openAccessPlanExcededModal = () => {
    const html = `<div>
                <p className="card-title-desc mb-0">
                   Parece que você excedeu o seu plano de acesso. <br>    
                </p>
                <p>
                    Contate nosso setor financeiro para acessar um plano que se ajuste melhor a sua necessidade!
                </p>
                <p>
                    <a href="mailto:contato@cashtf.com">contato@cashtf.com</a>
                </p>
            </div>`;

    Swal.fire({
      title: "Plano de Acesso",
      icon: "info",
      html: html,
      confirmButtonText: "Certo!",
      cancelButtonText: "Cancelar...",
    });
  };

  const openAccessPlanActiveModal = () => {
    const html = `<div>
                <p className="card-title-desc mb-0">
                   Parece que seu plano de acesso esta suspenso. <br>
                </p>
                <p>
                    Contate nosso setor financeiro para acessar um plano que se ajuste melhor a sua necessidade!
                </p>
                <p>
                    <a href="mailto:contato@cashtf.com">contato@cashtf.com</a>
                </p>
            </div>`;

    Swal.fire({
      title: "Plano de Acesso",
      icon: "info",
      html: html,
      confirmButtonText: "Certo!",
      cancelButtonText: "Cancelar...",
    });
  };

  const calcInstallments = (paymentEntity) => {
    if (paymentEntity.deadline) {
      const daysIntervals = paymentEntity.deadline.split("/");
      paymentEntity.numberInstallments = daysIntervals.length;
      paymentEntity.installmentMainAmount =
        paymentEntity.paymentPeriodSelected.installmentMainAmount;
      paymentEntity.installments = [];

      for (const interv of daysIntervals) {
        const billingDay = moment().add(interv, "days");
        paymentEntity.installments.push({
          dueDate: billingDay.toDate(),
          mainValue: paymentEntity.paymentPeriodSelected.installmentAmount,
          amount: paymentEntity.installmentMainAmount,
          effectiveRate: entity.fee / 100,
          discountRate: entity.fee / 100,
          discountValue:
            paymentEntity.installmentMainAmount -
            paymentEntity.paymentPeriodSelected.installmentAmount,
        });
      }
    }
    return paymentEntity;
  };

  const handlePaymentPeriod = (paymentPeriod) => {
    if (!entity.additionalInfo.payments) {
      entity.additionalInfo.payments = {};
    }
    entity.additionalInfo.payments.deadline = paymentPeriod
      ? paymentPeriod.paymentPeriod
      : null;
    entity.additionalInfo.payments.paymentPeriodSelected = paymentPeriod;

    setEntity(entity);
    setGuaranteedAmount(getGuaranteedAmount());
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        size="xl"
        contentClassName="modal-content-scroll"
        modalClassName="drawer right-align"
        toggle={toggleModal}
        onClosed={afterClosed}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{props.t("Register Contract")}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={afterClosed}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="wizard clearfix">
            <div className="steps clearfix">
              <ul>
                {state.tabs.map((tab, index) => {
                  return (
                    <NavItem
                      key={index}
                      className={classnames({
                        current: activeTab === index + 1,
                      })}
                    >
                      <NavLink
                        disabled={!(passedSteps || []).includes(index + 1)}
                        className={classnames({
                          current: activeTab === index + 1,
                        })}
                        onClick={() => {
                          setActiveTab(index + 1);
                        }}
                      >
                        <span className="number">{index + 1}</span>
                        <span>{tab["title"]}</span>
                      </NavLink>
                    </NavItem>
                  );
                })}
              </ul>
            </div>

            <div className="content clearfix mt-4">
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <Row>
                    <Col lg={4} className="mb-3">
                      <Select2Product
                        id="productType"
                        required={true}
                        onChange={(event) => {
                          entity.additionalInfo.product = event["value"];
                          entity.additionalInfo.productName =
                            ProductTypeDomain.getLabelByValue(event["value"]);
                          //Onus
                          entity.effectType = "3";

                          setEntity(entity);
                        }}
                        label={"Produto"}
                      />
                    </Col>
                    <Col lg={4} className="mb-3">
                      <LegalIdInput
                        id="legalIdContractor"
                        required={true}
                        onChange={(event) => {
                          handleFieldEntityInline(
                            "contractor",
                            "legalId",
                            event
                          );
                        }}
                        label={props.t("Legal ID contractor")}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={4} className="mb-3">
                      <Select2EffectType
                        id="effectType"
                        required={true}
                        label={props.t("Effect type")}
                        onChange={(option) => {
                          handleFieldEntitySelectOptions("effectType", option);
                        }}
                      />
                    </Col>

                    <Col lg={4} className="mb-3">
                      <Select2CreditModality
                        id="modality"
                        required={true}
                        label={props.t("Modality")}
                        onChange={(option) => {
                          handleFieldEntitySelectOptions("modality", option);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={4} className="mb-3">
                      <Select2YesOrNo
                        id="renegotiation"
                        required={true}
                        label={props.t("Renegotiation")}
                        defaultValue={entity.renegotiation}
                        onChange={(option) => {
                          handleFieldEntitySelectOptions(
                            "renegotiation",
                            option
                          );
                        }}
                      />
                    </Col>

                    <Col lg={4} className="mb-3">
                      <InputText
                        id="previousContractId"
                        onChange={(event) => handleFieldEntity(event)}
                        label={props.t("ID(s) of previous contract(s)")}
                      />
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId={2}>
                  <Row>
                    <Col lg={4} className="mb-3">
                      <LegalIdInput
                        id="legalIdCreditorAccount"
                        onChange={(event) => {
                          handleFieldEntityInline(
                            "creditorAccount",
                            "legalId",
                            event
                          );
                        }}
                        required={true}
                        label={props.t("Legal ID bank owner")}
                      />
                    </Col>
                    <Col lg={8} className="mb-3">
                      <Select2Banks
                        id="bankCreditorAccount"
                        required={true}
                        onChange={(option) => {
                          handleFieldEntityInline("creditorAccount", "ispb", {
                            target: { value: option ? option["ispb"] : null },
                          });
                        }}
                        label={props.t("ISPB/Payment company")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} className="mb-3">
                      <Select2AccountType
                        id="accountType"
                        required={true}
                        label={props.t("Account type")}
                        onChange={(option) => {
                          handleFieldEntityInline(
                            "creditorAccount",
                            "accountType",
                            {
                              target: {
                                value: option ? option["value"] : null,
                              },
                            }
                          );
                        }}
                      />
                    </Col>
                    <Col lg={4} className="mb-3">
                      <InputText
                        id="branchCreditorAccount"
                        onChange={(event) => {
                          handleFieldEntityInline(
                            "creditorAccount",
                            "branch",
                            event
                          );
                        }}
                        required={true}
                        label={props.t("Branch")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} className="mb-3">
                      <InputText
                        id="accountNumberCreditorAccount"
                        onChange={(event) => {
                          handleFieldEntityInline(
                            "creditorAccount",
                            "accountNumber",
                            event
                          );
                        }}
                        required={true}
                        label={props.t("Account number")}
                      />
                    </Col>
                    <Col lg={4} className="mb-3">
                      <InputText
                        id="accountDigitCreditorAccount"
                        onChange={(event) => {
                          handleFieldEntityInline(
                            "creditorAccount",
                            "accountDigit",
                            event
                          );
                        }}
                        required={true}
                        label={props.t("Account number digit")}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={3}>
                  <Row>
                    <Col lg={4} className="mb-3">
                      <InputMoney
                        id="debitBalance"
                        value={entity.debitBalance}
                        onChangeValidation={(value) => {
                          entity.debitBalance = value;
                          entity.guaranteedOperationLimit = entity.debitBalance;
                          setEntity(entity);
                          setGuaranteedAmount(getGuaranteedAmount());
                        }}
                        required={true}
                        label={"Valor Solicitado"}
                      />
                    </Col>
                    <hr style={{ borderTop: "1px solid rgb(187 187 187)" }} />
                    <Col lg={4}>
                      {entity.additionalInfo.product &&
                      ProductTypeDomain.getOptByValue(
                        entity.additionalInfo.product
                      ).allowDiscountRate ? (
                        <Select2DiscountRates
                          defaultValue={3}
                          label={props.t("Taxa de deságio (a.m)")}
                          onChange={(option) => {
                            setDiscountRate(option.value);
                            setGuaranteedAmount(getGuaranteedAmount());
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {entity.additionalInfo.product &&
                    ProductTypeDomain.getOptByValue(
                      entity.additionalInfo.product
                    ).allowFine ? (
                      <Col lg={4}>
                        <InputPercent
                          id="fine"
                          autoComplete="off"
                          onChangeValidation={(value) => {
                            entity.fine = value
                              ? value
                                  .replace("%", "")
                                  .replace(".", "")
                                  .replace(",", ".")
                              : 0;
                            setEntity(entity);
                            setGuaranteedAmount(getGuaranteedAmount());
                          }}
                          label={"Multa"}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                    {entity.additionalInfo.product &&
                    ProductTypeDomain.getOptByValue(
                      entity.additionalInfo.product
                    ).allowFee ? (
                      <Col lg={4}>
                        <InputDate
                          id="baseDate"
                          autoComplete="off"
                          onChange={(value) => {
                            setBaseDate(value ? value.target.value : null);
                            setGuaranteedAmount(getGuaranteedAmount());
                          }}
                          label={"Data base"}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                    {entity.additionalInfo.product &&
                    ProductTypeDomain.getOptByValue(
                      entity.additionalInfo.product
                    ).allowFee ? (
                      <Col lg={4}>
                        <InputPercent
                          id="fee"
                          autoComplete="off"
                          onChangeValidation={(value) => {
                            entity.fee = value
                              ? value
                                  .replace("%", "")
                                  .replace(".", "")
                                  .replace(",", ".")
                              : 0;
                            setEntity(entity);
                            setGuaranteedAmount(getGuaranteedAmount());
                          }}
                          label={"Juros (a.m)"}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  {entity.additionalInfo.product &&
                  ProductTypeDomain.getOptByValue(entity.additionalInfo.product)
                    .allowPP &&
                  productsTax ? (
                    <>
                      <Row>
                        <Col>&nbsp;</Col>
                      </Row>
                      <Row>
                        <Col lg={8}>
                          <Select2PaymentPeriod
                            id="deadline"
                            menuPlacement="top"
                            defaultOptins={
                              productsTax["BOLETO_GARANTIDO"]["paymentPeriods"]
                            }
                            required={true}
                            beforeGetOptionLabel={setPaymentPeriodOptionFields}
                            popover={props.t(
                              "Inform how many times you want your customer to pay"
                            )}
                            label={props.t("Payment period")}
                            value={state.paymentPeriodSelected}
                            onChange={(option) => {
                              handlePaymentPeriod(option);
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {entity.additionalInfo.product !== "CG" ? (
                    <>
                      <br />
                      <br />
                      <br />
                      <Row>
                        <Col lg={4} className={"text-center"}>
                          <button
                            className="btn btn-light w-sm waves-effect waves-light"
                            onClick={openModalAddGuarantee}
                          >
                            Selecionar garantias
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          className={"text-center"}
                          style={{ margin: "10px" }}
                        >
                          Ou
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                  <hr style={{ borderTop: "1px solid rgb(187 187 187)" }} />
                  <Row>
                    {contractorAcquirers ? (
                      <Col lg={4} className={"text-center"}>
                        <Select2Acquirers
                          label={props.t("Acquirers")}
                          menuPlacement="top"
                          defaultValue={0}
                          acquirers={contractorAcquirers}
                          onChange={(options) => {
                            let acqMap = options || [];
                            entity.acquirers =
                              acqMap.length > 0
                                ? acqMap.map((opt) => {
                                    return { legalId: opt.legalId };
                                  })
                                : null;
                            setEntity(entity);
                          }}
                        />
                      </Col>
                    ) : (
                      ""
                    )}

                    {contractorArrangements ? (
                      <Col lg={4} className={"text-center"}>
                        <Select2Arrangements
                          label={props.t("Payment arrangement")}
                          menuPlacement="top"
                          defaultValue={0}
                          arrangements={contractorArrangements}
                          onChange={(options) => {
                            let arrangementsMap = options || [];
                            entity.arrangements =
                              arrangementsMap.length > 0
                                ? arrangementsMap.map((opt) => {
                                    return { code: opt.code };
                                  })
                                : null;
                            setEntity(entity);
                          }}
                        />
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>

                  <Row>
                    <Col>&nbsp; </Col>
                  </Row>
                  <Row></Row>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <div className="text-start">
            <span>
              Valor selecionado:{" "}
              <strong>{$filter(guaranteedAmount, "MONEY")}</strong>
            </span>
          </div>
          <div className="text-end">
            <button
              className="btn btn-primary w-sm waves-effect waves-light"
              onClick={save}
            >
              {props.t("Confirm")}
            </button>
          </div>
        </div>

        {error ? (
          <ErrorDialog
            title={error["title"]}
            code={error["code"]}
            onClose={() => {
              setError(null);
            }}
          >
            <p>{error["detail"]} </p>
          </ErrorDialog>
        ) : (
          ""
        )}

        {loading ? <Loader loading={loading} /> : " "}
      </Modal>

      {modalAddGuarantee ? (
        <SelectEndUserReceivables
          isOpen={modalAddGuarantee}
          endUser={{ ...entity.contractor }}
          amount={guaranteedAmount}
          discountRate={entity.additionalInfo.discountRate}
          contract={entity}
          callback={(result) => {
            closeModalAddGuarantee(result);
          }}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default withTranslation()(ManageContractNew);
