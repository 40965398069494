const PaymentCompanyDomain = {
  options: [
    {
      comp: "001",
      ispb: "00000000",
      name: "BCO DO BRASIL S.A.",
    },
    {
      comp: "003",
      ispb: "04902979",
      name: "BCO DA AMAZONIA S.A.",
    },
    {
      comp: "004",
      ispb: "07237373",
      name: "BCO DO NORDESTE DO BRASIL S.A.",
    },
    {
      comp: "007",
      ispb: "33657248",
      name: "BNDES",
    },
    {
      comp: "010",
      ispb: "81723108",
      name: "CREDICOAMO",
    },
    {
      comp: "011",
      ispb: "61809182",
      name: "C.SUISSE HEDGING-GRIFFO CV S/A",
    },
    {
      comp: "012",
      ispb: "04866275",
      name: "BANCO INBURSA",
    },
    {
      comp: "014",
      ispb: "09274232",
      name: "STATE STREET BR S.A. BCO COMERCIAL",
    },
    {
      comp: "015",
      ispb: "02819125",
      name: "UBS BRASIL CCTVM S.A.",
    },
    {
      comp: "016",
      ispb: "04715685",
      name: "CCM DESP TRÂNS SC E RS",
    },
    {
      comp: "017",
      ispb: "42272526",
      name: "BNY MELLON BCO S.A.",
    },
    {
      comp: "018",
      ispb: "57839805",
      name: "BCO TRICURY S.A.",
    },
    {
      comp: "021",
      ispb: "28127603",
      name: "BCO BANESTES S.A.",
    },
    {
      comp: "024",
      ispb: "10866788",
      name: "BCO BANDEPE S.A.",
    },
    {
      comp: "025",
      ispb: "03323840",
      name: "BCO ALFA S.A.",
    },
    {
      comp: "029",
      ispb: "33885724",
      name: "BANCO ITAÚ CONSIGNADO S.A.",
    },
    {
      comp: "033",
      ispb: "90400888",
      name: "BCO SANTANDER (BRASIL) S.A.",
    },
    {
      comp: "036",
      ispb: "06271464",
      name: "BCO BBI S.A.",
    },
    {
      comp: "037",
      ispb: "04913711",
      name: "BCO DO EST. DO PA S.A.",
    },
    {
      comp: "040",
      ispb: "03609817",
      name: "BCO CARGILL S.A.",
    },
    {
      comp: "041",
      ispb: "92702067",
      name: "BCO DO ESTADO DO RS S.A.",
    },
    {
      comp: "047",
      ispb: "13009717",
      name: "BCO DO EST. DE SE S.A.",
    },
    {
      comp: "060",
      ispb: "04913129",
      name: "CONFIDENCE CC S.A.",
    },
    {
      comp: "062",
      ispb: "03012230",
      name: "HIPERCARD BM S.A.",
    },
    {
      comp: "063",
      ispb: "04184779",
      name: "BANCO BRADESCARD",
    },
    {
      comp: "064",
      ispb: "04332281",
      name: "GOLDMAN SACHS DO BRASIL BM S.A",
    },
    {
      comp: "065",
      ispb: "48795256",
      name: "BCO ANDBANK S.A.",
    },
    {
      comp: "066",
      ispb: "02801938",
      name: "BCO MORGAN STANLEY S.A.",
    },
    {
      comp: "069",
      ispb: "61033106",
      name: "BCO CREFISA S.A.",
    },
    {
      comp: "070",
      ispb: "00000208",
      name: "BRB - BCO DE BRASILIA S.A.",
    },
    {
      comp: "074",
      ispb: "03017677",
      name: "BCO. J.SAFRA S.A.",
    },
    {
      comp: "075",
      ispb: "03532415",
      name: "BANCO ABN AMRO CLEARING S.A.",
    },
    {
      comp: "076",
      ispb: "07656500",
      name: "BCO KDB BRASIL S.A.",
    },
    {
      comp: "077",
      ispb: "00416968",
      name: "BANCO INTER",
    },
    {
      comp: "078",
      ispb: "34111187",
      name: "HAITONG BI DO BRASIL S.A.",
    },
    {
      comp: "079",
      ispb: "09516419",
      name: "PICPAY BANK - BANCO MÚLTIPLO S.A",
    },
    {
      comp: "080",
      ispb: "73622748",
      name: "B&T CC LTDA.",
    },
    {
      comp: "081",
      ispb: "10264663",
      name: "BANCOSEGURO S.A.",
    },
    {
      comp: "082",
      ispb: "07679404",
      name: "BANCO TOPÁZIO S.A.",
    },
    {
      comp: "083",
      ispb: "10690848",
      name: "BCO DA CHINA BRASIL S.A.",
    },
    {
      comp: "084",
      ispb: "02398976",
      name: "SISPRIME DO BRASIL - COOP",
    },
    {
      comp: "085",
      ispb: "05463212",
      name: "COOPCENTRAL AILOS",
    },
    {
      comp: "088",
      ispb: "11476673",
      name: "BANCO RANDON S.A.",
    },
    {
      comp: "089",
      ispb: "62109566",
      name: "CREDISAN CC",
    },
    {
      comp: "091",
      ispb: "01634601",
      name: "CCCM UNICRED CENTRAL RS",
    },
    {
      comp: "092",
      ispb: "12865507",
      name: "BRK S.A. CFI",
    },
    {
      comp: "093",
      ispb: "07945233",
      name: "POLOCRED SCMEPP LTDA.",
    },
    {
      comp: "094",
      ispb: "11758741",
      name: "BANCO FINAXIS",
    },
    {
      comp: "095",
      ispb: "11703662",
      name: "TRAVELEX BANCO DE CÂMBIO S.A.",
    },
    {
      comp: "096",
      ispb: "00997185",
      name: "BCO B3 S.A.",
    },
    {
      comp: "097",
      ispb: "04632856",
      name: "CREDISIS - CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.",
    },
    {
      comp: "098",
      ispb: "78157146",
      name: "CREDIALIANÇA CCR",
    },
    {
      comp: "099",
      ispb: "03046391",
      name: "UNIPRIME COOPCENTRAL LTDA.",
    },
    {
      comp: "100",
      ispb: "00806535",
      name: "PLANNER CV S.A.",
    },
    {
      comp: "101",
      ispb: "62287735",
      name: "RENASCENCA DTVM LTDA",
    },
    {
      comp: "102",
      ispb: "02332886",
      name: "XP INVESTIMENTOS CCTVM S/A",
    },
    {
      comp: "104",
      ispb: "00360305",
      name: "CAIXA ECONOMICA FEDERAL",
    },
    {
      comp: "105",
      ispb: "07652226",
      name: "LECCA CFI S.A.",
    },
    {
      comp: "107",
      ispb: "15114366",
      name: "BCO BOCOM BBM S.A.",
    },
    {
      comp: "108",
      ispb: "01800019",
      name: "PORTOCRED S.A. - CFI",
    },
    {
      comp: "111",
      ispb: "36113876",
      name: "OLIVEIRA TRUST DTVM S.A.",
    },
    {
      comp: "113",
      ispb: "61723847",
      name: "NEON CTVM S.A.",
    },
    {
      comp: "114",
      ispb: "05790149",
      name: "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO",
    },
    {
      comp: "117",
      ispb: "92856905",
      name: "ADVANCED CC LTDA",
    },
    {
      comp: "119",
      ispb: "13720915",
      name: "BCO WESTERN UNION",
    },
    {
      comp: "120",
      ispb: "33603457",
      name: "BCO RODOBENS S.A.",
    },
    {
      comp: "121",
      ispb: "10664513",
      name: "BCO AGIBANK S.A.",
    },
    {
      comp: "122",
      ispb: "33147315",
      name: "BCO BRADESCO BERJ S.A.",
    },
    {
      comp: "124",
      ispb: "15357060",
      name: "BCO WOORI BANK DO BRASIL S.A.",
    },
    {
      comp: "125",
      ispb: "45246410",
      name: "BANCO GENIAL",
    },
    {
      comp: "126",
      ispb: "13220493",
      name: "BR PARTNERS BI",
    },
    {
      comp: "127",
      ispb: "09512542",
      name: "CODEPE CVC S.A.",
    },
    {
      comp: "128",
      ispb: "19307785",
      name: "BRAZA BANK S.A. BCO DE CÂMBIO",
    },
    {
      comp: "129",
      ispb: "18520834",
      name: "UBS BRASIL BI S.A.",
    },
    {
      comp: "130",
      ispb: "09313766",
      name: "CARUANA SCFI",
    },
    {
      comp: "131",
      ispb: "61747085",
      name: "TULLETT PREBON BRASIL CVC LTDA",
    },
    {
      comp: "132",
      ispb: "17453575",
      name: "ICBC DO BRASIL BM S.A.",
    },
    {
      comp: "133",
      ispb: "10398952",
      name: "CRESOL CONFEDERAÇÃO",
    },
    {
      comp: "134",
      ispb: "33862244",
      name: "BGC LIQUIDEZ DTVM LTDA",
    },
    {
      comp: "136",
      ispb: "00315557",
      name: "CONF NAC COOP CENTRAIS UNICRED",
    },
    {
      comp: "138",
      ispb: "10853017",
      name: "GET MONEY CC LTDA",
    },
    {
      comp: "139",
      ispb: "55230916",
      name: "INTESA SANPAOLO BRASIL S.A. BM",
    },
    {
      comp: "140",
      ispb: "62169875",
      name: "NU INVEST CORRETORA DE VALORES S.A.",
    },
    {
      comp: "141",
      ispb: "09526594",
      name: "MASTER BI S.A.",
    },
    {
      comp: "142",
      ispb: "16944141",
      name: "BROKER BRASIL CC LTDA.",
    },
    {
      comp: "143",
      ispb: "02992317",
      name: "TREVISO CC S.A.",
    },
    {
      comp: "144",
      ispb: "13059145",
      name: "EBURY BCO DE CÂMBIO S.A.",
    },
    {
      comp: "145",
      ispb: "50579044",
      name: "LEVYCAM CCV LTDA",
    },
    {
      comp: "146",
      ispb: "24074692",
      name: "GUITTA CC LTDA",
    },
    {
      comp: "149",
      ispb: "15581638",
      name: "FACTA S.A. CFI",
    },
    {
      comp: "157",
      ispb: "09105360",
      name: "ICAP DO BRASIL CTVM LTDA.",
    },
    {
      comp: "159",
      ispb: "05442029",
      name: "CASA CREDITO S.A. SCM",
    },
    {
      comp: "163",
      ispb: "23522214",
      name: "COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO",
    },
    {
      comp: "173",
      ispb: "13486793",
      name: "BRL TRUST DTVM SA",
    },
    {
      comp: "174",
      ispb: "43180355",
      name: "PEFISA S.A. - C.F.I.",
    },
    {
      comp: "177",
      ispb: "65913436",
      name: "GUIDE",
    },
    {
      comp: "180",
      ispb: "02685483",
      name: "CM CAPITAL MARKETS CCTVM LTDA",
    },
    {
      comp: "183",
      ispb: "09210106",
      name: "SOCRED SA - SCMEPP",
    },
    {
      comp: "184",
      ispb: "17298092",
      name: "BCO ITAÚ BBA S.A.",
    },
    {
      comp: "188",
      ispb: "33775974",
      name: "ATIVA S.A. INVESTIMENTOS CCTVM",
    },
    {
      comp: "189",
      ispb: "07512441",
      name: "HS FINANCEIRA",
    },
    {
      comp: "190",
      ispb: "03973814",
      name: "SERVICOOP",
    },
    {
      comp: "191",
      ispb: "04257795",
      name: "NOVA FUTURA CTVM LTDA.",
    },
    {
      comp: "194",
      ispb: "20155248",
      name: "UNIDA DTVM LTDA",
    },
    {
      comp: "195",
      ispb: "07799277",
      name: "VALOR S/A SCFI",
    },
    {
      comp: "196",
      ispb: "32648370",
      name: "FAIR CC S.A.",
    },
    {
      comp: "197",
      ispb: "16501555",
      name: "STONE IP S.A.",
    },
    {
      comp: "208",
      ispb: "30306294",
      name: "BANCO BTG PACTUAL S.A.",
    },
    {
      comp: "212",
      ispb: "92894922",
      name: "BANCO ORIGINAL",
    },
    {
      comp: "213",
      ispb: "54403563",
      name: "BCO ARBI S.A.",
    },
    {
      comp: "217",
      ispb: "91884981",
      name: "BANCO JOHN DEERE S.A.",
    },
    {
      comp: "218",
      ispb: "71027866",
      name: "BCO BS2 S.A.",
    },
    {
      comp: "222",
      ispb: "75647891",
      name: "BCO CRÉDIT AGRICOLE BR S.A.",
    },
    {
      comp: "224",
      ispb: "58616418",
      name: "BCO FIBRA S.A.",
    },
    {
      comp: "233",
      ispb: "62421979",
      name: "BANCO CIFRA",
    },
    {
      comp: "237",
      ispb: "60746948",
      name: "BCO BRADESCO S.A.",
    },
    {
      comp: "241",
      ispb: "31597552",
      name: "BCO CLASSICO S.A.",
    },
    {
      comp: "243",
      ispb: "33923798",
      name: "BANCO MASTER",
    },
    {
      comp: "246",
      ispb: "28195667",
      name: "BCO ABC BRASIL S.A.",
    },
    {
      comp: "249",
      ispb: "61182408",
      name: "BANCO INVESTCRED UNIBANCO S.A.",
    },
    {
      comp: "250",
      ispb: "50585090",
      name: "BANCO BMG CONSIGNADO S.A.",
    },
    {
      comp: "253",
      ispb: "52937216",
      name: "BEXS CC S.A.",
    },
    {
      comp: "254",
      ispb: "14388334",
      name: "PARANA BCO S.A.",
    },
    {
      comp: "259",
      ispb: "08609934",
      name: "MONEYCORP BCO DE CÂMBIO S.A.",
    },
    {
      comp: "260",
      ispb: "18236120",
      name: "NU PAGAMENTOS - IP",
    },
    {
      comp: "265",
      ispb: "33644196",
      name: "BCO FATOR S.A.",
    },
    {
      comp: "266",
      ispb: "33132044",
      name: "BCO CEDULA S.A.",
    },
    {
      comp: "268",
      ispb: "14511781",
      name: "BARI CIA HIPOTECÁRIA",
    },
    {
      comp: "269",
      ispb: "53518684",
      name: "BCO HSBC S.A.",
    },
    {
      comp: "270",
      ispb: "61444949",
      name: "SAGITUR CC - EM LIQUIDAÇÃO EXTRAJUDICIAL",
    },
    {
      comp: "271",
      ispb: "27842177",
      name: "IB CCTVM S.A.",
    },
    {
      comp: "272",
      ispb: "00250699",
      name: "AGK CC S.A.",
    },
    {
      comp: "273",
      ispb: "08253539",
      name: "CCR DE SÃO MIGUEL DO OESTE",
    },
    {
      comp: "274",
      ispb: "11581339",
      name: "BMP SCMEPP LTDA",
    },
    {
      comp: "276",
      ispb: "11970623",
      name: "BCO SENFF S.A.",
    },
    {
      comp: "278",
      ispb: "27652684",
      name: "GENIAL INVESTIMENTOS CVM S.A.",
    },
    {
      comp: "279",
      ispb: "26563270",
      name: "COOP DE PRIMAVERA DO LESTE",
    },
    {
      comp: "280",
      ispb: "23862762",
      name: "WILL FINANCEIRA S.A.CFI",
    },
    {
      comp: "281",
      ispb: "76461557",
      name: "CCR COOPAVEL",
    },
    {
      comp: "283",
      ispb: "89960090",
      name: "RB INVESTIMENTOS DTVM LTDA.",
    },
    {
      comp: "285",
      ispb: "71677850",
      name: "FRENTE CC S.A.",
    },
    {
      comp: "286",
      ispb: "07853842",
      name: "UNIPRIME OURO - COOP DE OURO",
    },
    {
      comp: "288",
      ispb: "62237649",
      name: "CAROL DTVM LTDA.",
    },
    {
      comp: "289",
      ispb: "94968518",
      name: "EFX CC LTDA.",
    },
    {
      comp: "290",
      ispb: "08561701",
      name: "PAGSEGURO INTERNET IP S.A.",
    },
    {
      comp: "292",
      ispb: "28650236",
      name: "GALAPAGOS DTVM S.A.",
    },
    {
      comp: "293",
      ispb: "71590442",
      name: "LASTRO RDV DTVM LTDA",
    },
    {
      comp: "296",
      ispb: "04062902",
      name: "OZ CORRETORA DE CÂMBIO S.A.",
    },
    {
      comp: "298",
      ispb: "17772370",
      name: "VIPS CC LTDA.",
    },
    {
      comp: "299",
      ispb: "04814563",
      name: "BCO AFINZ S.A. - BM",
    },
    {
      comp: "300",
      ispb: "33042151",
      name: "BCO LA NACION ARGENTINA",
    },
    {
      comp: "301",
      ispb: "13370835",
      name: "DOCK IP S.A.",
    },
    {
      comp: "305",
      ispb: "40353377",
      name: "FOURTRADE COR. DE CAMBIO LTDA",
    },
    {
      comp: "306",
      ispb: "40303299",
      name: "PORTOPAR DTVM LTDA",
    },
    {
      comp: "307",
      ispb: "03751794",
      name: "TERRA INVESTIMENTOS DTVM",
    },
    {
      comp: "309",
      ispb: "14190547",
      name: "CAMBIONET CC LTDA",
    },
    {
      comp: "310",
      ispb: "22610500",
      name: "VORTX DTVM LTDA.",
    },
    {
      comp: "311",
      ispb: "76641497",
      name: "DOURADA CORRETORA",
    },
    {
      comp: "312",
      ispb: "07693858",
      name: "HSCM SCMEPP LTDA.",
    },
    {
      comp: "313",
      ispb: "16927221",
      name: "AMAZÔNIA CC LTDA.",
    },
    {
      comp: "315",
      ispb: "03502968",
      name: "PI DTVM S.A.",
    },
    {
      comp: "318",
      ispb: "61186680",
      name: "BCO BMG S.A.",
    },
    {
      comp: "319",
      ispb: "11495073",
      name: "OM DTVM LTDA",
    },
    {
      comp: "320",
      ispb: "07450604",
      name: "BOC BRASIL",
    },
    {
      comp: "321",
      ispb: "18188384",
      name: "CREFAZ SCMEPP SA",
    },
    {
      comp: "322",
      ispb: "01073966",
      name: "CCR DE ABELARDO LUZ",
    },
    {
      comp: "323",
      ispb: "10573521",
      name: "MERCADO PAGO IP LTDA.",
    },
    {
      comp: "324",
      ispb: "21332862",
      name: "CARTOS SCD S.A.",
    },
    {
      comp: "325",
      ispb: "13293225",
      name: "ÓRAMA DTVM S.A.",
    },
    {
      comp: "326",
      ispb: "03311443",
      name: "PARATI - CFI S.A.",
    },
    {
      comp: "328",
      ispb: "05841967",
      name: "CECM FABRIC CALÇADOS SAPIRANGA",
    },
    {
      comp: "329",
      ispb: "32402502",
      name: "QI SCD S.A.",
    },
    {
      comp: "330",
      ispb: "00556603",
      name: "BANCO BARI S.A.",
    },
    {
      comp: "331",
      ispb: "13673855",
      name: "OSLO CAPITAL DTVM SA",
    },
    {
      comp: "332",
      ispb: "13140088",
      name: "ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO",
    },
    {
      comp: "334",
      ispb: "15124464",
      name: "BANCO BESA S.A.",
    },
    {
      comp: "335",
      ispb: "27098060",
      name: "BANCO DIGIO",
    },
    {
      comp: "336",
      ispb: "31872495",
      name: "BCO C6 S.A.",
    },
    {
      comp: "340",
      ispb: "09554480",
      name: "SUPERDIGITAL I.P. S.A.",
    },
    {
      comp: "341",
      ispb: "60701190",
      name: "ITAÚ UNIBANCO S.A.",
    },
    {
      comp: "342",
      ispb: "32997490",
      name: "CREDITAS SCD",
    },
    {
      comp: "343",
      ispb: "24537861",
      name: "FFA SCMEPP LTDA.",
    },
    {
      comp: "348",
      ispb: "33264668",
      name: "BCO XP S.A.",
    },
    {
      comp: "349",
      ispb: "27214112",
      name: "AL5 S.A. CFI",
    },
    {
      comp: "350",
      ispb: "01330387",
      name: "COOP DE AGRICULTORES E AEROPORTUÁRIOS DO BRASIL",
    },
    {
      comp: "352",
      ispb: "29162769",
      name: "TORO CTVM S.A.",
    },
    {
      comp: "354",
      ispb: "52904364",
      name: "NECTON INVESTIMENTOS S.A CVM",
    },
    {
      comp: "355",
      ispb: "34335592",
      name: "ÓTIMO SCD S.A.",
    },
    {
      comp: "358",
      ispb: "09464032",
      name: "MIDWAY S.A. - SCFI",
    },
    {
      comp: "359",
      ispb: "05351887",
      name: "ZEMA CFI S/A",
    },
    {
      comp: "360",
      ispb: "02276653",
      name: "TRINUS CAPITAL DTVM",
    },
    {
      comp: "362",
      ispb: "01027058",
      name: "CIELO IP S.A.",
    },
    {
      comp: "363",
      ispb: "62285390",
      name: "SINGULARE CTVM S.A.",
    },
    {
      comp: "364",
      ispb: "09089356",
      name: "EFÍ S.A. - IP",
    },
    {
      comp: "365",
      ispb: "68757681",
      name: "SIMPAUL",
    },
    {
      comp: "366",
      ispb: "61533584",
      name: "BCO SOCIETE GENERALE BRASIL",
    },
    {
      comp: "367",
      ispb: "34711571",
      name: "VITREO DTVM S.A.",
    },
    {
      comp: "368",
      ispb: "08357240",
      name: "BCO CSF S.A.",
    },
    {
      comp: "370",
      ispb: "61088183",
      name: "BCO MIZUHO S.A.",
    },
    {
      comp: "371",
      ispb: "92875780",
      name: "WARREN CVMC LTDA",
    },
    {
      comp: "373",
      ispb: "35977097",
      name: "UP.P SEP S.A.",
    },
    {
      comp: "374",
      ispb: "27351731",
      name: "REALIZE CFI S.A.",
    },
    {
      comp: "376",
      ispb: "33172537",
      name: "BCO J.P. MORGAN S.A.",
    },
    {
      comp: "377",
      ispb: "17826860",
      name: "BMS SCD S.A.",
    },
    {
      comp: "378",
      ispb: "01852137",
      name: "BCO BRASILEIRO DE CRÉDITO S.A.",
    },
    {
      comp: "379",
      ispb: "01658426",
      name: "CECM COOPERFORTE",
    },
    {
      comp: "380",
      ispb: "22896431",
      name: "PICPAY",
    },
    {
      comp: "381",
      ispb: "60814191",
      name: "BCO MERCEDES-BENZ S.A.",
    },
    {
      comp: "382",
      ispb: "04307598",
      name: "FIDUCIA SCMEPP LTDA",
    },
    {
      comp: "383",
      ispb: "21018182",
      name: "EBANX IP LTDA.",
    },
    {
      comp: "384",
      ispb: "11165756",
      name: "GLOBAL SCM LTDA",
    },
    {
      comp: "385",
      ispb: "03844699",
      name: "CECM DOS TRAB.PORT. DA G.VITOR",
    },
    {
      comp: "386",
      ispb: "30680829",
      name: "NU FINANCEIRA S.A. CFI",
    },
    {
      comp: "387",
      ispb: "03215790",
      name: "BCO TOYOTA DO BRASIL S.A.",
    },
    {
      comp: "389",
      ispb: "17184037",
      name: "BCO MERCANTIL DO BRASIL S.A.",
    },
    {
      comp: "390",
      ispb: "59274605",
      name: "BCO GM S.A.",
    },
    {
      comp: "391",
      ispb: "08240446",
      name: "CCR DE IBIAM",
    },
    {
      comp: "393",
      ispb: "59109165",
      name: "BCO VOLKSWAGEN S.A",
    },
    {
      comp: "394",
      ispb: "07207996",
      name: "BCO BRADESCO FINANC. S.A.",
    },
    {
      comp: "395",
      ispb: "08673569",
      name: "F D GOLD DTVM LTDA",
    },
    {
      comp: "396",
      ispb: "13884775",
      name: "HUB IP S.A.",
    },
    {
      comp: "397",
      ispb: "34088029",
      name: "LISTO SCD S.A.",
    },
    {
      comp: "398",
      ispb: "31749596",
      name: "IDEAL CTVM S.A.",
    },
    {
      comp: "399",
      ispb: "01701201",
      name: "KIRTON BANK",
    },
    {
      comp: "400",
      ispb: "05491616",
      name: "COOP CREDITAG",
    },
    {
      comp: "401",
      ispb: "15111975",
      name: "IUGU IP S.A.",
    },
    {
      comp: "402",
      ispb: "36947229",
      name: "COBUCCIO S.A. SCFI",
    },
    {
      comp: "403",
      ispb: "37880206",
      name: "CORA SCFI",
    },
    {
      comp: "404",
      ispb: "37241230",
      name: "SUMUP SCD S.A.",
    },
    {
      comp: "406",
      ispb: "37715993",
      name: "ACCREDITO SCD S.A.",
    },
    {
      comp: "407",
      ispb: "00329598",
      name: "SEFER INVESTIMENTOS DTVM LTDA",
    },
    {
      comp: "408",
      ispb: "36586946",
      name: "BONUSPAGO SCD S.A.",
    },
    {
      comp: "410",
      ispb: "05684234",
      name: "PLANNER SOCIEDADE DE CRÉDITO DIRETO",
    },
    {
      comp: "411",
      ispb: "05192316",
      name: "VIA CERTA FINANCIADORA S.A. - CFI",
    },
    {
      comp: "412",
      ispb: "15173776",
      name: "SOCIAL BANK S/A",
    },
    {
      comp: "413",
      ispb: "01858774",
      name: "BCO BV S.A.",
    },
    {
      comp: "414",
      ispb: "37526080",
      name: "LEND SCD S.A.",
    },
    {
      comp: "415",
      ispb: "17157777",
      name: "BCO NACIONAL",
    },
    {
      comp: "416",
      ispb: "19324634",
      name: "LAMARA SCD S.A.",
    },
    {
      comp: "418",
      ispb: "37414009",
      name: "ZIPDIN SCD S.A.",
    },
    {
      comp: "419",
      ispb: "38129006",
      name: "NUMBRS SCD S.A.",
    },
    {
      comp: "421",
      ispb: "39343350",
      name: "CC LAR CREDI",
    },
    {
      comp: "422",
      ispb: "58160789",
      name: "BCO SAFRA S.A.",
    },
    {
      comp: "423",
      ispb: "00460065",
      name: "COLUNA S.A. DTVM",
    },
    {
      comp: "425",
      ispb: "03881423",
      name: "SOCINAL S.A. CFI",
    },
    {
      comp: "426",
      ispb: "11285104",
      name: "NEON FINANCEIRA - CFI S.A.",
    },
    {
      comp: "427",
      ispb: "27302181",
      name: "CRED-UFES",
    },
    {
      comp: "428",
      ispb: "39664698",
      name: "CREDSYSTEM SCD S.A.",
    },
    {
      comp: "429",
      ispb: "05676026",
      name: "CREDIARE CFI S.A.",
    },
    {
      comp: "430",
      ispb: "00204963",
      name: "CCR SEARA",
    },
    {
      comp: "433",
      ispb: "44077014",
      name: "BR-CAPITAL DTVM S.A.",
    },
    {
      comp: "435",
      ispb: "38224857",
      name: "DELCRED SCD S.A.",
    },
    {
      comp: "438",
      ispb: "67030395",
      name: "TRUSTEE DTVM LTDA.",
    },
    {
      comp: "439",
      ispb: "16695922",
      name: "ID CTVM",
    },
    {
      comp: "440",
      ispb: "82096447",
      name: "CREDIBRF COOP",
    },
    {
      comp: "442",
      ispb: "87963450",
      name: "MAGNETIS - DTVM",
    },
    {
      comp: "443",
      ispb: "39416705",
      name: "CREDIHOME SCD",
    },
    {
      comp: "444",
      ispb: "40654622",
      name: "TRINUS SCD S.A.",
    },
    {
      comp: "445",
      ispb: "35551187",
      name: "PLANTAE CFI",
    },
    {
      comp: "447",
      ispb: "12392983",
      name: "MIRAE ASSET (BRASIL) CCTVM LTDA.",
    },
    {
      comp: "448",
      ispb: "39669186",
      name: "HEMERA DTVM LTDA.",
    },
    {
      comp: "449",
      ispb: "37555231",
      name: "DM",
    },
    {
      comp: "450",
      ispb: "13203354",
      name: "FITBANK IP",
    },
    {
      comp: "451",
      ispb: "40475846",
      name: "J17 - SCD S/A",
    },
    {
      comp: "452",
      ispb: "39676772",
      name: "CREDIFIT SCD S.A.",
    },
    {
      comp: "454",
      ispb: "41592532",
      name: "MÉRITO DTVM LTDA.",
    },
    {
      comp: "455",
      ispb: "38429045",
      name: "FÊNIX DTVM LTDA.",
    },
    {
      comp: "456",
      ispb: "60498557",
      name: "BCO MUFG BRASIL S.A.",
    },
    {
      comp: "457",
      ispb: "39587424",
      name: "UY3 SCD S/A",
    },
    {
      comp: "458",
      ispb: "07253654",
      name: "HEDGE INVESTMENTS DTVM LTDA.",
    },
    {
      comp: "459",
      ispb: "04546162",
      name: "CCM SERV. PÚBLICOS SP",
    },
    {
      comp: "460",
      ispb: "42047025",
      name: "UNAVANTI SCD S/A",
    },
    {
      comp: "461",
      ispb: "19540550",
      name: "ASAAS IP S.A.",
    },
    {
      comp: "462",
      ispb: "39908427",
      name: "STARK SCD S.A.",
    },
    {
      comp: "463",
      ispb: "40434681",
      name: "AZUMI DTVM",
    },
    {
      comp: "464",
      ispb: "60518222",
      name: "BCO SUMITOMO MITSUI BRASIL S.A.",
    },
    {
      comp: "465",
      ispb: "40083667",
      name: "CAPITAL CONSIG SCD S.A.",
    },
    {
      comp: "467",
      ispb: "33886862",
      name: "MASTER S/A CCTVM",
    },
    {
      comp: "468",
      ispb: "04862600",
      name: "PORTOSEG S.A. CFI",
    },
    {
      comp: "469",
      ispb: "07138049",
      name: "PICPAY INVEST",
    },
    {
      comp: "470",
      ispb: "18394228",
      name: "CDC SCD S.A.",
    },
    {
      comp: "471",
      ispb: "04831810",
      name: "CECM SERV PUBL PINHÃO",
    },
    {
      comp: "473",
      ispb: "33466988",
      name: "BCO CAIXA GERAL BRASIL S.A.",
    },
    {
      comp: "475",
      ispb: "10371492",
      name: "BCO YAMAHA MOTOR S.A.",
    },
    {
      comp: "477",
      ispb: "33042953",
      name: "CITIBANK N.A.",
    },
    {
      comp: "478",
      ispb: "11760553",
      name: "GAZINCRED S.A. SCFI",
    },
    {
      comp: "479",
      ispb: "60394079",
      name: "BCO ITAUBANK S.A.",
    },
    {
      comp: "481",
      ispb: "43599047",
      name: "SUPERLÓGICA SCD S.A.",
    },
    {
      comp: "482",
      ispb: "42259084",
      name: "SBCASH SCD",
    },
    {
      comp: "484",
      ispb: "36864992",
      name: "MAF DTVM SA",
    },
    {
      comp: "487",
      ispb: "62331228",
      name: "DEUTSCHE BANK S.A.BCO ALEMAO",
    },
    {
      comp: "488",
      ispb: "46518205",
      name: "JPMORGAN CHASE BANK",
    },
    {
      comp: "492",
      ispb: "49336860",
      name: "ING BANK N.V.",
    },
    {
      comp: "495",
      ispb: "44189447",
      name: "BCO LA PROVINCIA B AIRES BCE",
    },
    {
      comp: "505",
      ispb: "32062580",
      name: "BCO CREDIT SUISSE S.A.",
    },
    {
      comp: "506",
      ispb: "42066258",
      name: "RJI",
    },
    {
      comp: "507",
      ispb: "37229413",
      name: "SCFI EFÍ S.A.",
    },
    {
      comp: "508",
      ispb: "61384004",
      name: "AVENUE SECURITIES DTVM LTDA.",
    },
    {
      comp: "509",
      ispb: "13935893",
      name: "CELCOIN IP S.A.",
    },
    {
      comp: "510",
      ispb: "39738065",
      name: "FFCRED SCD S.A.",
    },
    {
      comp: "511",
      ispb: "44683140",
      name: "MAGNUM SCD",
    },
    {
      comp: "512",
      ispb: "36266751",
      name: "FINVEST DTVM",
    },
    {
      comp: "513",
      ispb: "44728700",
      name: "ATF SCD S.A.",
    },
    {
      comp: "514",
      ispb: "73302408",
      name: "EXIM CC LTDA.",
    },
    {
      comp: "516",
      ispb: "36583700",
      name: "QISTA S.A. CFI",
    },
    {
      comp: "518",
      ispb: "37679449",
      name: "MERCADO CRÉDITO SCFI S.A.",
    },
    {
      comp: "519",
      ispb: "40768766",
      name: "LIONS TRUST DTVM",
    },
    {
      comp: "520",
      ispb: "44705774",
      name: "SOMAPAY SCD S.A.",
    },
    {
      comp: "521",
      ispb: "44019481",
      name: "PEAK SEP S.A.",
    },
    {
      comp: "522",
      ispb: "47593544",
      name: "RED SCD S.A.",
    },
    {
      comp: "523",
      ispb: "44292580",
      name: "HR DIGITAL SCD",
    },
    {
      comp: "524",
      ispb: "45854066",
      name: "WNT CAPITAL DTVM",
    },
    {
      comp: "525",
      ispb: "34265629",
      name: "INTERCAM CC LTDA",
    },
    {
      comp: "526",
      ispb: "46026562",
      name: "MONETARIE SCD",
    },
    {
      comp: "527",
      ispb: "44478623",
      name: "ATICCA SCD S.A.",
    },
    {
      comp: "528",
      ispb: "34829992",
      name: "REAG TRUST DTVM",
    },
    {
      comp: "529",
      ispb: "17079937",
      name: "PINBANK IP",
    },
    {
      comp: "530",
      ispb: "47873449",
      name: "SER FINANCE SCD S.A.",
    },
    {
      comp: "531",
      ispb: "34337707",
      name: "BMP SCD S.A.",
    },
    {
      comp: "532",
      ispb: "45745537",
      name: "EAGLE SCD S.A.",
    },
    {
      comp: "533",
      ispb: "22575466",
      name: "SRM BANK",
    },
    {
      comp: "534",
      ispb: "00714671",
      name: "EWALLY IP S.A.",
    },
    {
      comp: "535",
      ispb: "39519944",
      name: "OPEA SCD",
    },
    {
      comp: "536",
      ispb: "20855875",
      name: "NEON PAGAMENTOS S.A. IP",
    },
    {
      comp: "537",
      ispb: "45756448",
      name: "MICROCASH SCMEPP LTDA.",
    },
    {
      comp: "538",
      ispb: "20251847",
      name: "SUDACRED SCD S.A.",
    },
    {
      comp: "539",
      ispb: "00122327",
      name: "SANTINVEST S.A. - CFI",
    },
    {
      comp: "540",
      ispb: "04849745",
      name: "HBI SCD",
    },
    {
      comp: "541",
      ispb: "00954288",
      name: "FDO GARANTIDOR CRÉDITOS",
    },
    {
      comp: "542",
      ispb: "18189547",
      name: "CLOUDWALK IP LTDA",
    },
    {
      comp: "543",
      ispb: "92825397",
      name: "COOPCRECE",
    },
    {
      comp: "544",
      ispb: "38593706",
      name: "MULTICRED SCD S.A.",
    },
    {
      comp: "545",
      ispb: "17352220",
      name: "SENSO CCVM S.A.",
    },
    {
      comp: "546",
      ispb: "30980539",
      name: "U4C INSTITUIÇÃO DE PAGAMENTO S.A.",
    },
    {
      comp: "547",
      ispb: "45331622",
      name: "BNK DIGITAL SCD S.A.",
    },
    {
      comp: "548",
      ispb: "06249129",
      name: "RPW S.A. SCFI",
    },
    {
      comp: "549",
      ispb: "15489568",
      name: "INTRA DTVM",
    },
    {
      comp: "550",
      ispb: "32074986",
      name: "BEETELLER",
    },
    {
      comp: "551",
      ispb: "48967968",
      name: "VERT DTVM LTDA.",
    },
    {
      comp: "552",
      ispb: "32192325",
      name: "UZZIPAY IP S.A.",
    },
    {
      comp: "553",
      ispb: "48707451",
      name: "PERCAPITAL SCD S.A.",
    },
    {
      comp: "554",
      ispb: "28811341",
      name: "STONEX BANCO DE CÂMBIO S.A.",
    },
    {
      comp: "555",
      ispb: "02682287",
      name: "PAN CFI",
    },
    {
      comp: "556",
      ispb: "40333582",
      name: "SAYGO CÂMBIO",
    },
    {
      comp: "557",
      ispb: "30944783",
      name: "PAGPRIME IP",
    },
    {
      comp: "558",
      ispb: "46955383",
      name: "QI DTVM LTDA.",
    },
    {
      comp: "559",
      ispb: "49288113",
      name: "KANASTRA SCD",
    },
    {
      comp: "560",
      ispb: "21995256",
      name: "MAG IP LTDA.",
    },
    {
      comp: "561",
      ispb: "20757199",
      name: "PAY4FUN IP S.A.",
    },
    {
      comp: "562",
      ispb: "18684408",
      name: "AZIMUT BRASIL DTVM LTDA",
    },
    {
      comp: "563",
      ispb: "40276692",
      name: "PROTEGE PAY CASH IP S.A.",
    },
    {
      comp: "565",
      ispb: "74014747",
      name: "ÁGORA CTVM S.A.",
    },
    {
      comp: "566",
      ispb: "23114447",
      name: "FLAGSHIP IP LTDA",
    },
    {
      comp: "567",
      ispb: "33040601",
      name: "MERCANTIL FINANCEIRA",
    },
    {
      comp: "568",
      ispb: "49933388",
      name: "BRCONDOS SCD S.A.",
    },
    {
      comp: "569",
      ispb: "12473687",
      name: "CONTA PRONTA IP",
    },
    {
      comp: "572",
      ispb: "51414521",
      name: "ALL IN CRED SCD S.A.",
    },
    {
      comp: "575",
      ispb: "48584954",
      name: "DGBK CREDIT S.A. - SOCIEDADE DE CRÉDITO DIRETO.",
    },
    {
      comp: "576",
      ispb: "11351086",
      name: "MERCADO BITCOIN IP LTDA",
    },
    {
      comp: "577",
      ispb: "10663610",
      name: "AF DESENVOLVE SP S.A.",
    },
    {
      comp: "578",
      ispb: "01235921",
      name: "SICRES",
    },
    {
      comp: "579",
      ispb: "49555647",
      name: "QUADRA SCD",
    },
    {
      comp: "580",
      ispb: "87437687",
      name: "CCCPOUPINV SUL E SUDESTE - CENTRAL SUL/SUDESTE",
    },
    {
      comp: "581",
      ispb: "70119680",
      name: "CENTRAL NORDESTE",
    },
    {
      comp: "582",
      ispb: "33737818",
      name: "CCC POUP INV DE MS GO DF E TO",
    },
    {
      comp: "583",
      ispb: "33667205",
      name: "CCC POUP INV DO CENTRO NORTE DO BRASIL",
    },
    {
      comp: "584",
      ispb: "80230774",
      name: "CCC POUP E INV DOS ESTADOS DO PR SP E RJ",
    },
    {
      comp: "585",
      ispb: "50946592",
      name: "SETHI SCD SA",
    },
    {
      comp: "586",
      ispb: "35810871",
      name: "Z1 IP LTDA.",
    },
    {
      comp: "587",
      ispb: "37678915",
      name: "FIDD DTVM LTDA.",
    },
    {
      comp: "588",
      ispb: "20308187",
      name: "PROVER PROMOCAO DE VENDAS IP LTDA.",
    },
    {
      comp: "589",
      ispb: "51212088",
      name: "G5 SCD SA",
    },
    {
      comp: "590",
      ispb: "40473435",
      name: "REPASSES FINANCEIROS E SOLUCOES TECNOLOGICAS IP S.A.",
    },
    {
      comp: "591",
      ispb: "02671743",
      name: "BANVOX DTVM",
    },
    {
      comp: "592",
      ispb: "45548763",
      name: "MAPS IP LTDA.",
    },
    {
      comp: "593",
      ispb: "27084098",
      name: "TRANSFEERA IP S.A.",
    },
    {
      comp: "594",
      ispb: "48703388",
      name: "ASA SOCIEDADE DE CRÉDITO DIRETO S.A.",
    },
    {
      comp: "595",
      ispb: "19468242",
      name: "ZOOP MEIOS DE PAGAMENTO",
    },
    {
      comp: "597",
      ispb: "34747388",
      name: "ISSUER IP LTDA.",
    },
    {
      comp: "599",
      ispb: "36321990",
      name: "AGORACRED S/A SCFI",
    },
    {
      comp: "600",
      ispb: "59118133",
      name: "BCO LUSO BRASILEIRO S.A.",
    },
    {
      comp: "604",
      ispb: "31895683",
      name: "BCO INDUSTRIAL DO BRASIL S.A.",
    },
    {
      comp: "610",
      ispb: "78626983",
      name: "BCO VR S.A.",
    },
    {
      comp: "611",
      ispb: "61820817",
      name: "BCO PAULISTA S.A.",
    },
    {
      comp: "612",
      ispb: "31880826",
      name: "BCO GUANABARA S.A.",
    },
    {
      comp: "613",
      ispb: "60850229",
      name: "OMNI BANCO S.A.",
    },
    {
      comp: "614",
      ispb: "52440987",
      name: "NITRO SCD S.A.",
    },
    {
      comp: "615",
      ispb: "37470405",
      name: "SMART SOLUTIONS GROUP IP LTDA",
    },
    {
      comp: "619",
      ispb: "49931906",
      name: "TRIO IP LTDA.",
    },
    {
      comp: "620",
      ispb: "51342763",
      name: "REVOLUT SCD S.A.",
    },
    {
      comp: "623",
      ispb: "59285411",
      name: "BANCO PAN",
    },
    {
      comp: "626",
      ispb: "61348538",
      name: "BCO C6 CONSIG",
    },
    {
      comp: "630",
      ispb: "58497702",
      name: "BCO LETSBANK S.A.",
    },
    {
      comp: "632",
      ispb: "52586293",
      name: "Z-ON SCD S.A.",
    },
    {
      comp: "633",
      ispb: "68900810",
      name: "BCO RENDIMENTO S.A.",
    },
    {
      comp: "634",
      ispb: "17351180",
      name: "BCO TRIANGULO S.A.",
    },
    {
      comp: "636",
      ispb: "40112555",
      name: "GIRO - SCD S/A",
    },
    {
      comp: "637",
      ispb: "60889128",
      name: "BCO SOFISA S.A.",
    },
    {
      comp: "643",
      ispb: "62144175",
      name: "BCO PINE S.A.",
    },
    {
      comp: "644",
      ispb: "54647259",
      name: "321 SCD S.A.",
    },
    {
      comp: "646",
      ispb: "91669747",
      name: "DM SA CFI",
    },
    {
      comp: "651",
      ispb: "25104230",
      name: "PAGARE IP S.A.",
    },
    {
      comp: "652",
      ispb: "60872504",
      name: "ITAÚ UNIBANCO HOLDING S.A.",
    },
    {
      comp: "653",
      ispb: "61024352",
      name: "BANCO VOITER",
    },
    {
      comp: "654",
      ispb: "92874270",
      name: "BCO DIGIMAIS S.A.",
    },
    {
      comp: "655",
      ispb: "59588111",
      name: "BCO VOTORANTIM S.A.",
    },
    {
      comp: "659",
      ispb: "35210410",
      name: "ONEKEY PAYMENTS IP S.A.",
    },
    {
      comp: "660",
      ispb: "34471744",
      name: "PAGME IP LTDA",
    },
    {
      comp: "661",
      ispb: "55428859",
      name: "FREEX CC S.A.",
    },
    {
      comp: "664",
      ispb: "11414839",
      name: "EAGLE IP LTDA.",
    },
    {
      comp: "707",
      ispb: "62232889",
      name: "BCO DAYCOVAL S.A",
    },
    {
      comp: "712",
      ispb: "78632767",
      name: "OURIBANK S.A.",
    },
    {
      comp: "719",
      ispb: "33884941",
      name: "BANCO MASTER MÚLTIPLO",
    },
    {
      comp: "720",
      ispb: "80271455",
      name: "BCO RNX S.A.",
    },
    {
      comp: "739",
      ispb: "00558456",
      name: "BCO CETELEM S.A.",
    },
    {
      comp: "741",
      ispb: "00517645",
      name: "BCO RIBEIRAO PRETO S.A.",
    },
    {
      comp: "743",
      ispb: "00795423",
      name: "BANCO SEMEAR",
    },
    {
      comp: "745",
      ispb: "33479023",
      name: "BCO CITIBANK S.A.",
    },
    {
      comp: "746",
      ispb: "30723886",
      name: "BCO MODAL S.A.",
    },
    {
      comp: "747",
      ispb: "01023570",
      name: "BCO RABOBANK INTL BRASIL S.A.",
    },
    {
      comp: "748",
      ispb: "01181521",
      name: "BCO COOPERATIVO SICREDI S.A.",
    },
    {
      comp: "751",
      ispb: "29030467",
      name: "SCOTIABANK BRASIL",
    },
    {
      comp: "752",
      ispb: "01522368",
      name: "BCO BNP PARIBAS BRASIL S A",
    },
    {
      comp: "753",
      ispb: "74828799",
      name: "NOVO BCO CONTINENTAL S.A. - BM",
    },
    {
      comp: "754",
      ispb: "76543115",
      name: "BANCO SISTEMA",
    },
    {
      comp: "755",
      ispb: "62073200",
      name: "BOFA MERRILL LYNCH BM S.A.",
    },
    {
      comp: "756",
      ispb: "02038232",
      name: "BANCO SICOOB S.A.",
    },
    {
      comp: "757",
      ispb: "02318507",
      name: "BCO KEB HANA DO BRASIL S.A.",
    },
  ],

  getLabelByCode(ispb) {
    for (const i in PaymentCompanyDomain.options) {
      if (PaymentCompanyDomain.options[i].ispb === ispb) {
        return this.options[i].name;
      }
    }
    return null;
  },
};

export default PaymentCompanyDomain;
