import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Progress,
  Row,
} from "reactstrap";
import { MetaTags } from "react-meta-tags";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select2Product from "../../../components/Common/Select2Product";
import ProductTypeDomain from "../../../domains/ProductTypeDomain";
import Select2EffectType from "../../../components/Common/Select2EffectType";
import Select2CreditModality from "../../../components/Common/Select2CreditModality";
import InputText from "../../../components/Common/InputText";
import Select2YesOrNoBool from "../../../components/Common/Select2YesOrNoBool";
import InputDate from "../../../components/Common/InputDate";
import Select2Async from "../../../components/Common/Select2Async";
import GravameSelectContract from "./GravameSelectContract";
import { get, getUserSession, post } from "../../../helpers/api_helper";
import InputMoney from "../../../components/Common/InputMoney";
import GravameBankAccount from "./GravameBankAccount";

import * as url from "../../../helpers/url_helper";
import GravameGarantiasNewV2 from "./GravameGarantiasNewV2";
import DateTimeUtils from "../../../common/utils/DateTimeUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import EffetiveTypeDomain from "../../../domains/EffetiveTypeDomain";
import ModalityDomain from "../../../domains/ModalityDomain";
import ServiceTypeDomain from "../../../domains/ServiceTypeDomain";
import Select2ServiceType from "../../../components/Common/Select2ServiceType";

import moment from "moment-timezone";

function GravameNewV2(props) {
  const [financialAgent, setFinancialAgent] = useState(
    getUserSession()["financialAgent"]
  );
  const baseCreditorAccount = {
    taxIdOwner: "",
    bankCode: null,
    accountType: "",
    branch: "",
    ispb: "",
    accountNumber: "",
    accountDigit: "",
    ownerName: "",
    legalId: "",
  };
  const history = useHistory();

  const registerAgent = getUserSession()["registerAgent"];
  const [loading, setLoading] = React.useState(false);
  const [bankAccounts, setBankAccounts] = React.useState([]);
  const [guarantees, setGuarantees] = React.useState([]);
  const [selecteContractor, setSelectedContractor] = React.useState(null);

  const [contractor, setContractor] = React.useState({
    legalId: "",
    name: "",
    tradingName: "",
    receivingEndUserDetails: null,
    receivableDetails: null,
    optins: [],
    billings: [],
    acquirers: [],
    arrangements: [],
  });

  const [creditorAccount, setCreditorAccount] =
    React.useState(baseCreditorAccount);

  const [entity, setEntity] = React.useState({
    registerAgent: registerAgent,
    financialAgent: financialAgent,
    creditorAccount: creditorAccount,
    guarantees: guarantees,
    contractor: contractor,
    signatureDate: DateTimeUtils.format(new Date(), "YYYY-MM-DD"),
    effectType: "2",
    serviceType: "1",
    unconditionalAccept: true,
    evaluationType: "",
    dueDate: moment().add(1, "year").format("YYYY-MM-DD"),
    modality: "3",
    renegotiation: false,
    debitBalance: 0,
    guaranteedOperationLimit: 0,
    valueMaintained: 0,
    additionalInfo: {
      product: "CB",
      productName: "Cobrança",
      productModality: "",
      averageTerm: 0,
      discountRate: "0",
      admRate: 0,
      effectiveRate: 0,
      discountValue: 0,
      mainValue: 0,
      payments: null,
      fine: 0,
      fee: 0,
      baseDate: null,
    },
    fine: 0,
    fee: 0,
    arrangements: [],
    acquirers: [],
  });

  const getBankAccounts = async (legalId) => {
    const response = await get(
      `${url.GET_BANK_ID_FINANCIAL_ADMIN}/${legalId}`,
      null,
      {},
      setLoading
    );

    return response;
  };

  const criarGravame = async () => {
    const response = await post(
      `${url.POST_GRAVAME_ADMIN}`,
      entity,
      {},
      setLoading
    );

    history.push("/contracts/manage-contracts");

    return response;
  };

  useEffect(() => {
    if (financialAgent.legalId) {
      setCreditorAccount(baseCreditorAccount);
      getBankAccounts(financialAgent.legalId).then((response) => {
        setBankAccounts(response);
      });
    }
  }, [financialAgent]);

  useEffect(() => {
    setEntity((entity) => ({
      ...entity,
      registerAgent: registerAgent,
      financialAgent: financialAgent,
      creditorAccount: creditorAccount,
      guarantees: guarantees,
      contractor: {
        legalId: contractor?.legalId,
        name: contractor?.name,
        tradingName: contractor?.tradingName,
      },
      arrangements: contractor?.arrangements || [],
      acquirers: contractor?.acquirers || [],
    }));
  }, [registerAgent, financialAgent, creditorAccount, guarantees, contractor]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Gravame</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={"Novo gravame"} breadcrumbItem={"Gravame"} />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              console.log("submit", e);
              criarGravame();
            }}
            id="newGravameForm"
          >
            <Row>
              <Col sm={12} md={6} xl={5}>
                <GravameSelectContract
                  selecteContractor={selecteContractor}
                  setContractor={setContractor}
                  contractor={contractor}
                  setSelectedContractor={setSelectedContractor}
                />
              </Col>
              <Col sm={12} md={6} xl={7}>
                {loading && <Progress animated color="success" value="100" />}
                <Card>
                  <CardBody>
                    <Row>
                      <Card className="p-3">
                        <CardTitle>Cadastrar configuração do Gravame</CardTitle>
                        <Row>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <Select2Product
                                id="productType"
                                required={true}
                                popover={
                                  "Seleciona o tipo do produto do Gravame"
                                }
                                onChange={(event) => {
                                  entity.additionalInfo.product =
                                    event["value"];
                                  entity.additionalInfo.productName =
                                    ProductTypeDomain.getLabelByValue(
                                      event["value"]
                                    );

                                  setEntity(entity);
                                }}
                                label={"Produto"}
                                // disabled={!enableInputs.contractor}
                                // className={
                                //   !enableInputs.contractor
                                //     ? "select2-form"
                                //     : "select2-form-disabled"
                                // }
                                value={{
                                  value: entity?.additionalInfo?.product,
                                  label: entity?.additionalInfo?.productName,
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <Select2EffectType
                                id="effectType"
                                required={true}
                                label={props.t("Effect type")}
                                onChange={(option) => {
                                  entity.effectType = option["value"];
                                  setEntity(entity);
                                }}
                                // disabled={!enableInputs.contractor}
                                // className={
                                //   !enableInputs.contractor
                                //     ? "select2-form"
                                //     : "select2-form-disabled"
                                // }
                                value={{
                                  value: entity.effectType,
                                  label: EffetiveTypeDomain.getLabelByValue(
                                    entity.effectType
                                  ),
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <Select2ServiceType
                                id="serviceType"
                                required={true}
                                label={props.t("Service type")}
                                onChange={(option) => {
                                  entity.serviceType = option["value"];
                                  setEntity(entity);
                                }}
                                // disabled={!enableInputs.contractor}
                                // className={
                                //   !enableInputs.contractor
                                //     ? "select2-form"
                                //     : "select2-form-disabled"
                                // }
                                value={{
                                  value: entity.serviceType,
                                  label: ServiceTypeDomain.getLabelByValue(
                                    entity.serviceType
                                  ),
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <Select2CreditModality
                                id="modality"
                                required={true}
                                label={props.t("Modality")}
                                onChange={(option) => {
                                  entity.modality = option["value"];
                                  setEntity(entity);
                                }}
                                // disabled={!enableInputs.contractor}
                                // className={
                                //   !enableInputs.contractor
                                //     ? "select2-form"
                                //     : "select2-form-disabled"
                                // }
                                value={{
                                  value: entity.modality,
                                  label: ModalityDomain.getLabelByValue(
                                    entity.modality
                                  ),
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <Select2YesOrNoBool
                                id="renegotiation"
                                required={true}
                                defaultValue={false}
                                popover={
                                  "Indica se o contrato atual se trata de repactuação"
                                }
                                label={props.t("Renegotiation")}
                                onChange={(option) => {
                                  entity.renegotiation = option["value"];
                                  setEntity(entity);
                                }}
                                // disabled={!enableInputs.contractor}
                                // className={
                                //   !enableInputs.contractor
                                //     ? "select2-form"
                                //     : "select2-form-disabled"
                                // }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={4} sm={6} className="mb-3">
                            <FormGroup>
                              <InputText
                                className="gravame"
                                disabled={!entity.renegotiation}
                                id="previousContractId"
                                onChange={(event) => {
                                  entity.previousContractId =
                                    event?.target?.value;
                                  setEntity(entity);
                                }}
                                label={props.t("ID(s) of previous contract(s)")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <Select2YesOrNoBool
                                id="unconditionalAccept"
                                required={true}
                                label={"Aceite Incondicional da Operação"}
                                defaultValue={true}
                                onChange={(option) => {
                                  entity.unconditionalAccept = option["value"];
                                  setEntity(entity);
                                }}
                                // disabled={!enableInputs.contractor}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <InputDate
                                id="signatureDate"
                                className="gravame"
                                autoComplete="off"
                                onChange={(e) => {
                                  entity.signatureDate = e?.target?.value;
                                  setEntity(entity);
                                }}
                                required={true}
                                value={entity.signatureDate}
                                label={"Data de assinatura"}
                                // disabled={!enableInputs?.contractor}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <InputDate
                                id="dueDate"
                                className="gravame"
                                autoComplete="off"
                                onChange={(e) => {
                                  entity.dueDate = e?.target?.value;
                                  setEntity(entity);
                                }}
                                required={true}
                                value={entity.dueDate}
                                label={"Data de vencimento"}
                                // disabled={!enableInputs.contractor}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="p-3">
                        <CardTitle>Participantes</CardTitle>
                        <Row>
                          <Col md={4} sm={6} lg={4} className="mb-3">
                            <FormGroup>
                              <InputText
                                // disabled={!enableInputs.contractor}
                                id="contractorLegalId"
                                className="gravame"
                                required={true}
                                value={entity?.contractor?.legalId}
                                label={props.t("CNPJ do contratante")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} lg={4} className="mb-3">
                            <FormGroup>
                              <InputText
                                disabled={true}
                                required={true}
                                className="gravame"
                                id="registerAgentLegalId"
                                value={entity?.registerAgent?.legalId}
                                label={props.t("CNPJ do participante")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} lg={4} className="mb-3">
                            <Select2Async
                              url={url.GET_FINANCIAL_AGENT_PAGINATION}
                              orderBy={"name"}
                              required={true}
                              onChange={(event) =>
                                setFinancialAgent({
                                  legalId: event?.value,
                                  name: event?.label,
                                })
                              }
                              id="financialAgentLegalId"
                              placeholder={props.t("CNPJ do detendor")}
                              label={props.t("CNPJ do detendor")}
                              value={{
                                value: entity?.financialAgent?.legalId,
                                label: entity?.financialAgent?.name,
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                      <Card className="p-3">
                        <CardTitle>Valores do Gravame</CardTitle>
                        <Row>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <InputMoney
                                id="debitBalance"
                                className="gravame"
                                required={true}
                                onChange={(event) => {
                                  entity.debitBalance = event?.target?.value;
                                  setEntity(entity);
                                }}
                                label={props.t("Debit balance")}
                                value={entity.debitBalance}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <InputMoney
                                id="guaranteedOperationLimit"
                                onChange={(event) => {
                                  entity.guaranteedOperationLimit =
                                    event?.target?.value;
                                  setEntity(entity);
                                }}
                                className="gravame"
                                required={true}
                                label={props.t("Guaranteed operation limit")}
                                value={entity.guaranteedOperationLimit}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={6} className="mb-3">
                            <FormGroup>
                              <InputMoney
                                id="valueMaintained"
                                className="gravame"
                                onChange={(event) => {
                                  entity.valueMaintained = event?.target?.value;
                                  setEntity(entity);
                                }}
                                required={true}
                                label={"Valor mínimo a ser mantido"}
                                value={entity.valueMaintained}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="p-3">
                        <Row>
                          <CardTitle>Dados Bancarios</CardTitle>

                          <Col md={12} className="mb-3">
                            <GravameBankAccount
                              bankAccounts={bankAccounts}
                              reload={getBankAccounts}
                              financialAgent={entity?.financialAgent}
                              setCreditorAccount={setCreditorAccount}
                            />
                          </Col>
                        </Row>
                      </Card>
                      <Card className="p-3">
                        <Row>
                          <Col md={12}>
                            <GravameGarantiasNewV2
                              guarantees={guarantees}
                              setGuarantees={setGuarantees}
                              creditorAccount={creditorAccount}
                              acquirers={contractor?.acquirers || []}
                              arrangements={contractor?.arrangements || []}
                              receivingEndUserLegalId={
                                entity.contractor?.legalId
                              }
                              holderLegalId={entity.contractor?.legalId}
                              contract={contractor}
                            />
                          </Col>
                        </Row>
                      </Card>
                      <Col md={12}>
                        <div className="mt-5">
                          <button
                            disabled={loading}
                            type="submit"
                            title="Salvar"
                            className="btn btn-sm btn-primary w-sm waves-effect waves-primary col-auto"
                          >
                            {props.t("Save")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(GravameNewV2));
