const EffetiveTypeDomain = {
  TROCA_TITULARIDADE: "1",
  CESSAO_FIDUCIARIA: "2",
  ONUS_OUTROS: "3",
  BLOQUEIO_JUDICIAL: "4",
  PROMESSA_CESSAO: "5",

  options: [
    { label: "Troca de titularidade", value: "1" },
    { label: "Cessão fiduciária", value: "2" },
    { label: "Ônus - Outros", value: "3" },
    { label: "Bloqueio judicial", value: "4" },
    { label: "Promessa de Cessão", value: "5" },
  ],

  getInstance(value) {
    for (const i in EffetiveTypeDomain.options) {
      if (EffetiveTypeDomain.options[i].value === value) {
        return this.options[i];
      }
    }
    return null;
  },

  getLabelByValue(value) {
    for (const i in EffetiveTypeDomain.options) {
      if (EffetiveTypeDomain.options[i].value === value) {
        return this.options[i].label;
      }
    }
    return null;
  },
};

export default EffetiveTypeDomain;
