import { useState } from "react";
import AsyncSelect from "react-select/async";
import PaginationService from "./PaginationService";
import debounce from "debounce-promise";
import { Label } from "reactstrap";
const Select2Async = ({
  url,
  orderBy,
  initValue,
  onChange,
  id,
  filters,
  ...props
}) => {
  const [filter, setFilter] = useState(PaginationService.defaultFilter(20));
  const [loading, setLoading] = useState(false);

  const applySearch = debounce((inputValue) => {
    return new Promise((resolve, reject) => {
      PaginationService.applySearch(
        url,
        "data",
        { ...filter, q: inputValue, orderBy: orderBy, ...filters },
        1,
        20,
        setLoading
      )
        .then(({ pagerResult }) => {
          resolve(pagerResult?.results);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }, 1200);

  return (
    <div className="templating-select select2-container">
      {props.label !== null && props.label !== undefined ? (
        <Label for={props.id} className="form-label" disabled={props.disabled}>
          {`${props.required === true ? "*" : ""}${props.label}`}
        </Label>
      ) : (
        <></>
      )}
      <AsyncSelect
        name={id}
        {...props}
        id={id}
        classNamePrefix="select2-selection"
        className="select2-form"
        cacheOptions={false}
        defaultOptions={true}
        loadOptions={applySearch}
        onChange={onChange}
        isLoading={loading}
        isMulti={false}
        isClearable={true}
        defaultInputValue={initValue}
      />
    </div>
  );
};

export default Select2Async;
