import { useState } from "react";
import { receivablesService } from "../../../../services/http";

const createTabs = (t) => [
  {
    title: t("Summary"),
    value: "summary",
  },
  {
    title: t("Receivable Units Available"),
    value: "availables",
  },
  {
    title: t("Unavailable receivables"),
    value: "compromiseds",
  },
  {
    title: t("Not constituted receivables"),
    value: "futureCommitments",
  },
  { title: t("Obligations"), value: "obligations" },
];

export const useReceivableUnitsTabs = ({ t, receivable }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("summary");
  const [units, setUnits] = useState({
    availables: [],
    compromiseds: [],
    futureCommitments: [],
    obligations: [],
  });

  const tabs = createTabs(t);

  const getAllAvailableUnits = async (legalId) => {
    if (units.availables.length > 0) return;

    try {
      const response = await receivablesService.getAllAvailableUnitsLegalId(
        legalId
      );
      setUnits((prevState) => ({
        ...prevState,
        availables: response.receivableUnitsAvailable,
      }));
    } catch {
      //
    }
  };

  const getAllCompromisedUnits = async (legalId) => {
    if (units.compromiseds.length > 0) return;

    try {
      const response =
        await receivablesService.getAllCompromisedsUnitsByLegalId(legalId);
      setUnits((prevState) => ({
        ...prevState,
        compromiseds: response.receivableUnitsCompromised,
      }));
    } catch {
      //
    }
  };

  const getAllFutureCommitmentsUnits = async (legalId) => {
    if (units.futureCommitments.length > 0) return;

    try {
      const response =
        await receivablesService.getAllFutureCommitmentsUnitsByLegalId(legalId);
      setUnits((prevState) => ({
        ...prevState,
        futureCommitments: response.receivableFutureCommitments,
      }));
    } catch {
      //
    }
  };

  const getAllObligationsUnits = async (legalId) => {
    if (units.obligations.length > 0) return;

    try {
      const response = await receivablesService.getAllObligationsUnitsByLegalId(
        legalId
      );
      setUnits((prevState) => ({
        ...prevState,
        obligations: response.endUserObligations,
      }));
    } catch {
      //
    }
  };

  const selectTab = async (selectedTab) => {
    if (isLoading) return;

    const receivableRequests = {
      [tabs[1].value]: getAllAvailableUnits,
      [tabs[2].value]: getAllCompromisedUnits,
      [tabs[3].value]: getAllFutureCommitmentsUnits,
      [tabs[4].value]: getAllObligationsUnits,
    };

    const receivableRequest = receivableRequests[selectedTab];

    setIsLoading(true);

    await receivableRequest?.(receivable.receivingEndUser.legalId);

    setIsLoading(false);
    setActiveTab(selectedTab);
  };

  return {
    tabs,
    units,
    activeTab,
    selectTab,
    isLoading,
  };
};
