import React, { useState } from "react";
import { Toast, ToastHeader, ToastBody } from "reactstrap";

const Alert = ({ message, type, onClose }) => {
  const [visible, setVisible] = useState(true);
  const bgColor = type === "error" ? "bg-danger" : "bg-success";

  const handleClose = () => {
    setVisible(false);
    if (onClose) {
      onClose();
    }
  };

  useState(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 10000);
    }
  }, [message]);

  if (!visible) return null;

  return (
    <div className="position-fixed top-0 end-0 p-2" style={{ zIndex: 1050 }}>
      <Toast className={`${bgColor} bg-soft`}>
        <ToastHeader toggle={handleClose} className="center">
          {type === "error" ? "Alerta!" : "Sucesso!"}
        </ToastHeader>
        <ToastBody className="bgColor">{message}</ToastBody>
      </Toast>
    </div>
  );
};

export const showAlert = (message, type = "error", onClose) => {
  return <Alert message={message} type={type} onClose={onClose} />;
};

export default Alert;
