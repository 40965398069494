import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";

import { Row, Col } from "reactstrap";
import { useParams, withRouter } from "react-router-dom";
import ModalCheckoutSign from "./ModalCheckoutSign";
import { get, post } from "../../helpers/api_helper";
import { GET_CHECKOUT, POST_INI_CHECKOUT } from "../../helpers/url_helper";
import ErrorDialog from "../../components/Common/ErrorDialog";
import Loader from "../../components/Common/Loader";
import CheckoutDesktopContent from "./CheckoutDesktopContent";
import CheckoutMobileContent from "./CheckoutMobileContent";
import DeviceIdentifier from "react-device-identifier";

const Checkout = (props) => {
  let { orderReference } = useParams();

  const [modalDocInSign, setModalDocInSign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [documentAlreadySigned, setDocumentAlreadySigned] = useState(false);
  const [paymentPeriodSelected, setPaymentPeriodSelected] = useState(null);
  const [innerHeight] = useState(window.innerHeight);

  const [buyerFormFields, setBuyerFormFields] = useState({
    legalIdBuyer: { isValid: false },
    phoneBuyer: { isValid: false },
    emailBuyer: { isValid: false },
    personLegalId: { isValid: false },
    personName: { isValid: false },
    personCompanyPlacement: { isValid: false },
    checkAcceptedTerms: { isValid: true },
  });

  const [isValidToInitCheckout, setIsValidToInitCheckout] = useState(false);

  const [entity, setEntity] = useState({
    orderDate: null,
    invoiceNumber: null,
    seller: {
      legalId: null,
      name: null,
    },
    amount: 0,
    paymentMethod: null,
    deadline: null,
    numInstallments: null,
    installmentAmount: null,
    message: null,
  });

  const [buyer, setBuyer] = useState({
    legalId: null,
    phone: null,
    email: null,
  });

  useEffect(() => {
    if (!hasLoaded) {
      loadCheckout();
      setHasLoaded(true);
    }
  });

  const handleBuyerField = (name, value) => {
    buyer[name] = value;
    setBuyer(buyer);
  };

  const handleBuyerFormField = (fieldId, isValid) => {
    buyerFormFields[fieldId] = { isValid: isValid };
    const newForm = Object.assign({}, buyerFormFields);

    setBuyerFormFields(newForm);
    // console.log('newForm', newForm)
    validateForm(newForm);
  };

  const validateForm = (buyerForm, paymentOption) => {
    let form = buyerForm ? buyerForm : buyerFormFields;
    let paySelected = paymentOption ? paymentOption : paymentPeriodSelected;

    let isValidFull = true;
    for (const fieldName in form) {
      if (!form[fieldName].isValid) {
        isValidFull = false;
      }
    }

    let paymentSel = true;
    // if (entity.paymentMethod === 'CASH.NOW' && !paySelected){
    //     paymentSel = false
    // }
    setIsValidToInitCheckout(isValidFull && paymentSel);
  };

  const handlePaymentPeriod = (paymentOption) => {
    setPaymentPeriodSelected(paymentOption);
    validateForm(null, paymentOption);
  };

  const closeModalSignd = (modalResult) => {
    setModalDocInSign(false);

    if (
      modalResult &&
      modalResult["event"] &&
      modalResult["event"] === "DOC_SIGNED"
    ) {
      setDocumentAlreadySigned(true);

      entity.isSigned = modalResult["entity"]["isSigned"];
      setEntity(Object.assign({}, entity));
    }
  };

  const initCheckout = async () => {
    if (!isValidToInitCheckout) return;

    try {
      const request = {
        legalId: buyer["legalId"],
        phone: buyer["phone"],
        email: buyer["email"],
        personName: buyer["personName"],
        personLegalId: buyer["personLegalId"],
        personCompanyPlacement: buyer["personCompanyPlacement"],
      };
      if (paymentPeriodSelected) {
        request["paymentPeriod"] = paymentPeriodSelected["paymentPeriod"];
      }

      const response = await post(
        `${POST_INI_CHECKOUT}/${orderReference}`,
        request,
        null,
        setLoading
      );

      if (response["order"]) {
        response["order"]["orderReference"] = orderReference;
        response["order"]["buyer"]["email"] = buyer["email"];

        if (response["order"] && response["order"]["isSigned"] === true) {
          setEntity(
            Object.assign(
              { paymentOptions: entity["paymentOptions"] },
              response["order"]
            )
          );
          setDocumentAlreadySigned(true);
        } else {
          setEntity(
            Object.assign(
              { paymentOptions: entity["paymentOptions"] },
              response["order"]
            )
          );
          setModalDocInSign(true);
        }
      }
    } catch (e) {
      if (e.status === 400) {
        setError(e);
      } else {
        setError({
          title: props.t("Failed to start checkout"),
          detail: props.t("Invalid user or checkout unavailable"),
        });
      }
    }
  };

  const loadCheckout = async () => {
    try {
      const response = await get(
        `${GET_CHECKOUT}/${orderReference}`,
        null,
        null,
        setLoading
      );
      if (response["order"]) {
        setEntity(response["order"]);
      }
    } catch (e) {
      setError({
        title: props.t("Failed to load checkout"),
        detail: props.t(
          "Checkout is currently unavailable, please try again later"
        ),
      });
    } finally {
      setFirstLoad(false);
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Checkout | Cash2Pay</title>
      </MetaTags>
      <div className="checkout-page">
        {error ? (
          <ErrorDialog
            title={error["title"]}
            code={error["status"]}
            onClose={() => {
              setError(null);
            }}
          >
            <p>{error["detail"]} </p>
          </ErrorDialog>
        ) : (
          ""
        )}

        <DeviceIdentifier isDesktop={true}>
          <CheckoutDesktopContent
            documentAlreadySigned={documentAlreadySigned}
            handleBuyerField={handleBuyerField}
            handlePaymentPeriod={handlePaymentPeriod}
            firstLoad={firstLoad}
            entity={entity}
            paymentPeriodSelected={paymentPeriodSelected}
            handleBuyerFormField={handleBuyerFormField}
          />
        </DeviceIdentifier>

        <DeviceIdentifier isMobile={true}>
          <CheckoutMobileContent
            documentAlreadySigned={documentAlreadySigned}
            handleBuyerField={handleBuyerField}
            firstLoad={firstLoad}
            entity={entity}
            handlePaymentPeriod={handlePaymentPeriod}
            initCheckout={initCheckout}
            paymentPeriodSelected={paymentPeriodSelected}
            handleBuyerFormField={handleBuyerFormField}
          />
        </DeviceIdentifier>

        {!documentAlreadySigned ? (
          <Row className="fixed-bottom d-none d-lg-flex">
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              {!modalDocInSign ? (
                <div className="text-center p-5">
                  <button
                    style={{
                      borderRadius: 120,
                      textTransform: "uppercase",
                      fontWeight: 600,
                    }}
                    className="btn btn-primary w-100 w-sm waves-effect waves-light h-25"
                    disabled={!isValidToInitCheckout}
                    onClick={() => {
                      initCheckout();
                    }}
                  >
                    {props.t("Start Checkout")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i className="fa fa-lock" />
                  </button>
                </div>
              ) : (
                ""
              )}
            </Col>

            {/*{*/}
            {/*    innerHeight > 640 ?*/}

            {/*        <Col xl={8} lg={6} md={6} sm={12} xs={12}  >*/}
            {/*            <div className="ps-5 pe-5" style={{color: "#ffffff9e"}}>*/}
            {/*                <Link*/}
            {/*                    className="d-lg-none d-xl-block"*/}
            {/*                    style={{color: "#ffffff9e"}}*/}
            {/*                    to={{ pathname: AppFields.webSiteUrl }} target="_blank">*/}
            {/*                    {props.t("Cash2Pay to the right industry and retail connection, learn more at")}:&nbsp;{AppFields.webSite}*/}
            {/*                </Link>*/}
            {/*                <hr/>*/}
            {/*                <p> {AppFields.copyright}</p>*/}
            {/*            </div>*/}

            {/*        </Col> : ""*/}
            {/*}*/}
          </Row>
        ) : (
          ""
        )}
      </div>
      {modalDocInSign ? (
        <ModalCheckoutSign
          entity={entity}
          isOpen={modalDocInSign}
          callback={closeModalSignd}
        />
      ) : (
        ""
      )}
      {loading ? <Loader loading={loading} /> : " "}
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Checkout));
