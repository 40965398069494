import { post, put, getV2, patch } from "../../helpers/api_helper";

import { AuthService } from "./AuthService";
import { ContractsService } from "./ContractsService";
import { ReceivablesService } from "./ReceivablesService";
import { UsersService } from "./UsersService";

const http = {
  get: getV2,
  post,
  put,
  patch,
};

export const usersService = new UsersService(http);
export const authService = new AuthService(http);
export const receivablesService = new ReceivablesService(http);
export const contractsService = new ContractsService(http);
