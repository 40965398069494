import * as url from "../../helpers/url_helper";

export class UsersService {
  constructor(http) {
    this.http = http;
  }

  async create(user) {
    return this.http.post(url.POST_USERS, user, null);
  }

  async update(legalId, user) {
    return this.http.put(`${url.PUT_USERS}/${legalId}`, user, null);
  }
}
