import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import InputText from "../../components/Common/InputText";
import TablePagination from "../../components/Common/TablePagination";
import $filter from "../../common/utils/$filter";
import PaginationService from "../../components/Common/PaginationService";
import * as url from "../../helpers/url_helper";
import ErrorDialog from "../../components/Common/ErrorDialog";
import SuccessDialog from "../../components/Common/SuccessDialog";
import SegmentNew from "./SegmentNew";

function Segments(props) {
  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState(PaginationService.defaultFilter());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalNewEntity, setModalNewEntity] = useState(false);
  const [modalNewEntityEditMode, setModalNewEntityEditMode] = useState(null);
  const [editEntity, setEditEntity] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const applySearch = async (currentPage, itemsPerPage) => {
    try {
      const { pagerResult, filterResult } = await PaginationService.applySearch(
        url.GET_SEGMENTS,
        "segments",
        filter,
        currentPage,
        itemsPerPage,
        setLoading
      );
      setPager(pagerResult);
      setFilter(filterResult);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") setError(e);
    }
  };

  const handleChangeFilter = (event) => {
    filter[event.target.name] = event.target.value;
    setFilterField("filter", filter);
  };

  const setFilterField = (name, value) => {
    filter[name] = value;
    setFilter(filter);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPagination(filter));
    applySearch();
  };

  function openModalNewEntity() {
    setEditEntity(null);
    setModalNewEntityEditMode("NEW");
    setModalNewEntity(true);
  }
  function openModalEditEntity(entity) {
    const entityDisassociated = Object.assign({}, entity);
    entityDisassociated.products = Object.assign([], entity.products);

    if (entityDisassociated.products.length > 0) {
      entityDisassociated.products[0] = Object.assign(
        {},
        entityDisassociated.products[0]
      );
    }
    if (entityDisassociated.products.length > 1) {
      entityDisassociated.products[1] = Object.assign(
        {},
        entityDisassociated.products[1]
      );
    }

    setEditEntity(entityDisassociated);
    setModalNewEntityEditMode("EDIT");
    setModalNewEntity(true);
  }

  function setResultFromNewEntity(newEntity) {
    setFilter(PaginationService.defaultFilter());
    const newPager = PaginationService.defaultPager();
    newPager.results = [newEntity];
    setPager(newPager);
  }
  function setResultFromChangedEntity(newEntity) {
    const newResults = [];
    pager.results.map((entity) => {
      if (entity.segmentName === newEntity.segmentName)
        newResults.push(newEntity);
      else newResults.push(entity);
    });
    pager.results = newResults;
    setPager(pager);
  }

  function closeModalNewEntity(resultModal) {
    setModalNewEntity(resultModal.isOpen);

    if (resultModal["event"] === "SAVED") {
      setResultFromNewEntity(resultModal["entity"]);

      setSuccessMessage({
        detail: props.t(
          resultModal.successMessage || "Segment successfully registered"
        ),
      });
    } else if (resultModal["event"] === "CHANGED") {
      setResultFromChangedEntity(resultModal["entity"]);

      setSuccessMessage({
        detail: props.t(
          resultModal.successMessage || "Segment successfully changed"
        ),
      });
    }
  }

  const getProductTax = (products, productName) => {
    if (products && products.length > 0) {
      const prod = products.filter((pr) => {
        return pr.product === productName;
      })[0];
      if (prod) return prod["tax"];
    }
    return 0;
  };

  useEffect(() => {
    applySearch();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Segments")}</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            title={props.t("Segments")}
            breadcrumbItem={props.t("Manage Segments")}
          />

          <Form
            id="searchForm"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t("Filters parameters")}</p>
                    <Row>
                      <Col lg={4} className="mb-3">
                        <InputText
                          id="segmentLabel"
                          onChange={(event) => handleChangeFilter(event)}
                          placeholder={props.t("Name")}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-sm btn-light w-sm waves-effect waves-light"
                          >
                            {props.t("Apply Search")}
                          </button>{" "}
                          <button
                            type="button"
                            className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                            onClick={() => {
                              openModalNewEntity();
                            }}
                          >
                            {props.t("New")}
                          </button>{" "}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <hr />
                    </Row>

                    <Row className="mt-4">
                      <Col lg={12}>
                        <TablePagination
                          pager={pager}
                          applySearch={applySearch}
                          loading={loading}
                        >
                          <thead className="table-light">
                            <tr>
                              <th className="col-md-3">{props.t("Name")}</th>
                              <th className="col-md-2">Taxa Cash.Now</th>
                              <th className="col-md-2">Taxa Cash.Flow</th>
                              <th className="col-md-2">
                                Taxa Boleto Protegido
                              </th>
                              <th className="col-md-2">Taxa Cash.Recupera</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(pager.results || []).map((entity, key) => (
                              <tr
                                key={entity["legalId"]}
                                className="align-middle"
                                onClick={() => {
                                  openModalEditEntity(entity);
                                }}
                              >
                                <td>{entity.segmentLabel}</td>
                                <td>
                                  {$filter(
                                    getProductTax(
                                      entity["products"],
                                      "CASH.NOW"
                                    ),
                                    "PRECENT"
                                  )}
                                </td>
                                <td>
                                  {$filter(
                                    getProductTax(
                                      entity["products"],
                                      "CASH.FLOW"
                                    ),
                                    "PRECENT"
                                  )}
                                </td>
                                <td>
                                  {$filter(
                                    getProductTax(
                                      entity["products"],
                                      "BOLETO_GARANTIDO"
                                    ),
                                    "PRECENT"
                                  )}
                                </td>
                                <td>
                                  {$filter(
                                    getProductTax(
                                      entity["products"],
                                      "CASH.RECUPERA"
                                    ),
                                    "PRECENT"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </TablePagination>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>

          {modalNewEntity ? (
            <SegmentNew
              editMode={modalNewEntityEditMode}
              entity={editEntity}
              isOpen={modalNewEntity}
              callback={closeModalNewEntity}
            />
          ) : (
            ""
          )}
        </Container>
      </div>

      {error ? (
        <ErrorDialog
          title={error["title"]}
          onClose={() => {
            setError(null);
          }}
        >
          <p>{error["detail"]} </p>
        </ErrorDialog>
      ) : (
        ""
      )}

      {successMessage ? (
        <SuccessDialog
          title={successMessage["title"]}
          onClose={() => {
            setSuccessMessage(null);
          }}
        >
          <p>{successMessage["detail"]} </p>
        </SuccessDialog>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(Segments));
