import * as url from "../../helpers/url_helper";
import { HttpService } from "./HttpService";

export class AuthService extends HttpService {
  constructor(http) {
    super(http);
  }

  async recoveryPassword(email) {
    return this.http.post(url.POST_REQUEST_NEW_PASSWORD, { email }, null);
  }
}
