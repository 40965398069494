import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Form, Container, Table } from "reactstrap";

import { withTranslation } from "react-i18next";
import $filter from "../../common/utils/$filter";
import StringUtils from "../../common/utils/StringUtils";
import {
  createNewSession,
  getUserSession,
  post,
} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { getHome } from "../../routes/allRoutes";
import Loader from "./Loader";
import { USER_PROFILES } from "../../domains/UserProfileDomain";

const CompanyBox = (props) => (
  <React.Fragment>
    <Row>
      <Col lg={12}>
        <p className="card-title-desc mb-0">{props.title}</p>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col lg={12}>
        <Table>
          <tbody>
            {props.orgs.map((entity, key) => (
              <tr key={entity["legalId"]}>
                <td style={{ padding: "0 0 10px 0" }}>
                  <button
                    className="btn w-100 btn-select"
                    style={{
                      padding: 20,
                      height: 65,
                    }}
                    onClick={() => {
                      props.hancleChangeCompany(entity);
                    }}
                  >
                    <span>
                      {StringUtils.abbreviateName(entity.name, 45)} <br />
                      {$filter(entity.legalId, "CPF_CNPJ")}
                    </span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  </React.Fragment>
);

function SelectChangeSessionOrganizationModal(props) {
  const [loading, setLoading] = useState(false);
  const [firstSearch, setFirstSearch] = useState(false);
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [user, setUser] = useState(getUserSession());

  const [financialAgents, setFinancialAgents] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [endUsers, setEndUsers] = useState([]);
  const [registerAgents, setRegisterAgents] = useState([]);

  const afterClosed = () => {
    props.callback({ isOpen: false, entity: null, event: "CLOSE" });
  };

  const close = (selectedEntity) => {
    props.callback({
      isOpen: false,
      entity: selectedEntity,
      event: "SELECTED",
    });
  };

  const toggle = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!firstSearch) {
      setFirstSearch(true);

      if (user.financialAgents && user.financialAgents.length > 0) {
        const registerAgentsProfile = [];
        const financialAgentsProfile = [];
        const sellersProfile = [];
        const endUsersProfile = [];

        user.financialAgents.map((org) => {
          if (org.appProfile === USER_PROFILES.ADM)
            registerAgentsProfile.push(org);
          else if (org.appProfile === USER_PROFILES.FINANCIAL)
            financialAgentsProfile.push(org);
          else if (org.appProfile === USER_PROFILES.SELLER) sellersProfile.push(org);
          else if (org.appProfile === USER_PROFILES.USER) endUsersProfile.push(org);
        });

        setRegisterAgents(registerAgentsProfile);
        setFinancialAgents(financialAgentsProfile);
        setSellers(sellersProfile);
        setEndUsers(endUsersProfile);
      }
    }
  });

  const hancleChangeCompany = async (compay) => {
    if (loading) return;

    const response = await post(
      url.POST_CHANGE_COMPANY,
      {
        financialAgent: {
          legalId: compay["legalId"],
        },
      },
      null,
      setLoading
    );
    if (response["status"] === "200") {
      await createNewSession(response["user"]);
      const pageUrl = getHome();
      window.location = pageUrl;
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggle();
        }}
        onClosed={() => {
          afterClosed();
        }}
        modalClassName="drawer right-align"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {props.t("Select Organization for change access profile")}
          </h5>
          <button
            type="button"
            onClick={() => {
              toggle();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          {registerAgents.length ? (
            <CompanyBox
              title="Agente de Registro"
              orgs={registerAgents}
              hancleChangeCompany={hancleChangeCompany}
            />
          ) : (
            ""
          )}
          {financialAgents.length ? (
            <CompanyBox
              title="Agente Financeiro"
              orgs={financialAgents}
              hancleChangeCompany={hancleChangeCompany}
            />
          ) : (
            ""
          )}
          {sellers.length ? (
            <CompanyBox
              title="Fornecedor"
              orgs={sellers}
              hancleChangeCompany={hancleChangeCompany}
            />
          ) : (
            ""
          )}
          {endUsers.length ? (
            <CompanyBox
              title="Estabelelecimento Comercial"
              orgs={endUsers}
              hancleChangeCompany={hancleChangeCompany}
            />
          ) : (
            ""
          )}
        </div>

        {loading ? <Loader loading={loading} /> : <></>}
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(SelectChangeSessionOrganizationModal);
