import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";

import CpfCnpjUtils from "../../common/utils/CpfCnpjUtils";
import AcquiresDomain from "../../domains/AcquiresDomain";
import { Label } from "reactstrap";

const animatedComponents = makeAnimated();

class Select2Acquirers extends React.Component {
  constructor(props) {
    super(props);

    let acquirerList = [
      {
        legalId: "ALL",
        name: this.props.t("ALL"),
      },
    ];

    acquirerList = acquirerList.concat(props?.acquirers || []);

    // concatena a lista de adquirentes com a lista de adquirentes padrao do sistema e remove duplicados
    acquirerList = acquirerList.concat(
      AcquiresDomain.options.filter(
        (acquirer) =>
          !acquirerList.some((item) => item.legalId === acquirer.legalId)
      )
    );

    this.state = {
      acquirerList: acquirerList,
    };
  }

  onChange = (acquirers) => {
    let acquirersMap = acquirers || [];

    // nao permite coexistir a opcao ALL com as demais opcoes
    if (acquirersMap.length > 1) {
      const firstItem = acquirersMap[0];
      const lastItem = acquirersMap[acquirersMap.length - 1];

      if (firstItem["legalId"] === "ALL") {
        acquirersMap.shift();
        acquirers = acquirersMap;
      } else if (lastItem["legalId"] === "ALL") {
        acquirersMap.splice(0, acquirersMap.length - 1);
        acquirers = acquirersMap;
      }
    }
    if (this.props.onChange) {
      this.props.onChange(acquirersMap);
    }
  };

  render() {
    return (
      <div className="templating-select select2-container">
        {this.props.label !== null && this.props.label !== undefined ? (
          <Label for={this.props.id} className="form-label">
            {`${this.props.required === true ? "*" : ""}${this.props.label}`}
          </Label>
        ) : (
          ""
        )}
        <Select
          isMulti={
            this.props.isMulti !== null && this.props.isMulti !== undefined
              ? this.props.isMulti
              : true
          }
          classNamePrefix="select2-selection"
          className="select2-form"
          closeMenuOnSelect={true}
          menuPlacement={this.props.menuPlacement}
          placeholder={
            this.props.placeholder !== null &&
            this.props.placeholder !== undefined
              ? this.props.placeholder
              : ""
          }
          isClearable={true}
          options={this.state.acquirerList}
          defaultValue={this.state.acquirerList[this.props.defaultValue]}
          components={animatedComponents}
          getOptionValue={(option) => {
            return option.legalId;
          }}
          getOptionLabel={(option) => {
            if (option.legalId === "ALL") {
              return option.name;
            }
            return `${option.name} ${CpfCnpjUtils.getMask(option.legalId)}`;
          }}
          onChange={(options) => {
            this.onChange(options);
          }}
        />
      </div>
    );
  }
}

Select2Acquirers.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  menuPlacement: PropTypes.string,
};

export default withTranslation()(Select2Acquirers);
