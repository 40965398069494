import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import InputText from "../../components/Common/InputText";
import LegalIdInput from "../../components/Common/LegalIdInput";
import TablePagination from "../../components/Common/TablePagination";
import StatusDomain from "../../domains/StatusDomain";
import $filter from "../../common/utils/$filter";
import PaginationService from "../../components/Common/PaginationService";
import * as url from "../../helpers/url_helper";
import { CreateOrUpdateUserModal } from "./CreateOrUpdateUserModal";
import ErrorDialog from "../../components/Common/ErrorDialog";
import SuccessDialog from "../../components/Common/SuccessDialog";

function Users(props) {
  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState(PaginationService.defaultFilter());
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const [modalNewEntityEditMode, setModalNewEntityEditMode] = useState(null);
  const [editEntity, setEditEntity] = useState(null);

  const applySearch = async (currentPage, itemsPerPage) => {
    try {
      const { pagerResult, filterResult } = await PaginationService.applySearch(
        url.GET_USERS,
        "users",
        filter,
        currentPage,
        itemsPerPage,
        setLoading
      );
      setPager(pagerResult);
      setFilter(filterResult);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") setError(e);
    }
  };

  useEffect(() => {
    applySearch();
  }, []);

  const handleChangeFilter = (event) => {
    filter[event.target.name] = event.target.value;
    setFilterField("filter", filter);
  };

  const setFilterField = (name, value) => {
    filter[name] = value;
    setFilter(filter);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPagination(filter));
    applySearch();
  };

  function openModalNewEntity() {
    setEditEntity(null);
    setModalNewEntityEditMode("NEW");
    setUserModalOpen(true);
  }
  function openModalEditEntity(entity) {
    const entityDisassociated = Object.assign({}, entity);
    entityDisassociated.organizations = Object.assign([], entity.organizations);

    setEditEntity(entityDisassociated);
    setModalNewEntityEditMode("EDIT");
    setUserModalOpen(true);
  }

  const handleUserCreated = (userCreated) => {
    setFilter(PaginationService.defaultFilter());

    const newPager = PaginationService.defaultPager();
    newPager.results = [userCreated];

    setPager(newPager);
  };

  const handleUserUpdated = (userUpdated) => {
    const updatedPagerResults = pager.results.map((user) =>
      user.legalId === userUpdated.legalId ? userUpdated : user
    );

    setPager((prevState) => ({ ...prevState, results: updatedPagerResults }));
  };

  const handleClose = () => {
    setUserModalOpen(false);
  };

  const handleSuccessShow = (message) => {
    setSuccess(message);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Users")}</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            title={props.t("User")}
            breadcrumbItem={props.t("Manage Users")}
          />

          <Form
            id="searchForm"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t("Filters parameters")}</p>
                    <Row>
                      <Col lg={4} className="mb-3">
                        <LegalIdInput
                          id="legalId"
                          onChange={(event) => handleChangeFilter(event)}
                          placeholder={props.t("CPF")}
                        />
                      </Col>
                      <Col lg={4} className="mb-3">
                        <InputText
                          id="name"
                          onChange={(event) => handleChangeFilter(event)}
                          placeholder={props.t("Name")}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-sm btn-light w-sm waves-effect waves-light"
                          >
                            {props.t("Apply Search")}
                          </button>{" "}
                          <button
                            type="button"
                            className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                            onClick={() => {
                              openModalNewEntity();
                            }}
                          >
                            {props.t("New")}
                          </button>{" "}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <hr />
                    </Row>

                    <Row className="mt-4">
                      <Col lg={12}>
                        <TablePagination
                          pager={pager}
                          applySearch={applySearch}
                          loading={loading}
                        >
                          <thead className="table-light">
                            <tr>
                              <th className="col-md-1 text-center">
                                {props.t("Status")}
                              </th>
                              <th className="col-md-3">{props.t("CPF")}</th>
                              <th className="col-md-5">{props.t("Name")}</th>
                              <th className="col-md-3">{props.t("Email")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(pager.results || []).map((entity, key) => (
                              <tr
                                key={entity["legalId"]}
                                className="align-middle"
                                onClick={() => {
                                  openModalEditEntity(entity);
                                }}
                              >
                                <td
                                  scope="row"
                                  className="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {}}
                                >
                                  <span
                                    className={StatusDomain.getClassFromValue(
                                      entity.status
                                    )}
                                  >
                                    {StatusDomain.getNameFromValue(
                                      entity.status
                                    )}
                                  </span>
                                </td>
                                <td>{$filter(entity.legalId, "CPF_CNPJ")}</td>
                                <td>{entity.name}</td>
                                <td>{entity.userName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </TablePagination>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>

          {isUserModalOpen && (
            <CreateOrUpdateUserModal
              isEdit={modalNewEntityEditMode === "EDIT"}
              user={editEntity}
              isOpen={isUserModalOpen}
              onUserCreated={handleUserCreated}
              onUserUpdated={handleUserUpdated}
              onClosed={handleClose}
              onShowMessage={handleSuccessShow}
            />
          )}
        </Container>
      </div>

      {error && (
        <ErrorDialog title={error["title"]} onClose={() => setError(null)}>
          <p>{error["detail"]} </p>
        </ErrorDialog>
      )}

      {success && (
        <SuccessDialog
          title={success["title"]}
          onClose={() => setSuccess(null)}
        >
          <p>{success["detail"]} </p>
        </SuccessDialog>
      )}
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(Users));
