import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import ManageContractNew from "./ManageContractNew";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

//Import TablePagination
import TablePagination from "../../components/Common/TablePagination";
import PaginationService from "../../components/Common/PaginationService";
import * as url from "../../helpers/url_helper";
// import $filterEl from "../../common/utils/$filterEl";
import StatusDomain from "../../domains/StatusDomain";
import EffetiveTypeDomain from "../../domains/EffetiveTypeDomain";
import $filter from "../../common/utils/$filter";
import InputText from "../../components/Common/InputText";
import LegalIdInput from "../../components/Common/LegalIdInput";
import InputDate from "../../components/Common/InputDate";
import classnames from "classnames";
import ErrorDialog from "../../components/Common/ErrorDialog";
import { getSessionUserProfile, hasRole } from "../../helpers/api_helper";
import { USER_PROFILES } from "../../domains/UserProfileDomain";

function ManageContracts(props) {
  const history = useHistory();

  const [modalNewContract, setModalNewContract] = useState(false);

  const navigateToNew = () => openNewContractModal();

  const [error, setError] = useState(null);

  const navigateToEdit = (contract) => {
    history.push(`/contracts/edit/${contract.contractId}`);
  };

  const [loading, setLoading] = useState(false);

  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState(() => {
    const defaultFilter = PaginationService.defaultFilter();
    const queryParams = new URLSearchParams(window.location.search);

    return {
      ...defaultFilter,
      externalReference: queryParams.get("codeRef") || "",
    };
  });
  const [statusContractTab, setStatusContractTab] = useState("ALL");

  function openNewContractModal() {
    setModalNewContract(true);
    removeBodyCss();
  }

  function closeNewContractModal() {
    setModalNewContract(false);
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toggleStatusContractTab = (tab) => {
    if (statusContractTab !== tab) {
      setStatusContractTab(tab);

      if (tab !== "ALL") setFilterField("status", tab);
      else setFilterField("status", null);

      applySearch();
    }
  };

  const setFilterField = (name, value) => {
    filter[name] = value;
    setFilter(filter);
  };

  const handleChangeFilter = (event) => {
    filter[event.target.name] = event.target.value;
    setFilterField("filter", filter);
  };

  // const handleChangeFilterWithMask = (event, maskType) => {
  //   // apply mask in input field
  //   const { valueWithMask, unmaskedValue } = $filterEl(event, maskType);
  //   setFilterField(event.target.name, unmaskedValue);
  // };

  const applySearch = async (currentPage, itemsPerPage) => {
    try {
      const { pagerResult, filterResult } = await PaginationService.applySearch(
        url.GET_CONTRACT,
        "contracts",
        filter,
        currentPage,
        itemsPerPage,
        setLoading
      );
      setPager(pagerResult);
      setFilter(filterResult);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") setError(e);
    }
  };

  // const clearFilter = () => {
  //   document.getElementById("searchForm").reset();
  //   setFilter(PaginationService.defaultFilter());
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPagination(filter));
    applySearch();
  };

  useEffect(() => {
    applySearch();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("CASHTF | CONTRACTS")}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Contracts")}
            breadcrumbItem={props.t("Manage contracts")}
          />

          {/* Search */}
          <Form
            id="searchForm"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t("Filters parameters")}</p>
                    <Row className="mb-3">
                      <Col lg={4}>
                        <InputText
                          id="externalReference"
                          onChange={(event) => handleChangeFilter(event)}
                          placeholder={props.t("Reference Code")}
                          autoComplete="off"
                          defaultValue={filter.externalReference}
                        />
                      </Col>
                      <Col lg={4}>
                        <LegalIdInput
                          id="legalIdContractor"
                          onChange={(event) => handleChangeFilter(event)}
                          placeholder={props.t("Legal ID contractor")}
                        />
                      </Col>
                      <Col lg={4}>
                        <InputDate
                          id="creationDate"
                          onChange={(event) => handleChangeFilter(event)}
                          placeholder={props.t("Registration Date")}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col lg={12}>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-sm btn-light w-sm waves-effect waves-light"
                          >
                            {props.t("Apply Search")}
                          </button>{" "}
                          {hasRole("contract.write") ? (
                            <button
                              type="button"
                              className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                              onClick={() => {
                                navigateToNew();
                              }}
                            >
                              {props.t("New")}
                            </button>
                          ) : (
                            ""
                          )}{" "}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col lg={12}>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: statusContractTab === "ALL",
                              })}
                              onClick={() => {
                                toggleStatusContractTab("ALL");
                              }}
                            >
                              {props.t("All")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: statusContractTab === "P",
                              })}
                              onClick={() => {
                                toggleStatusContractTab("P");
                              }}
                            >
                              {props.t("Processing")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: statusContractTab === "A",
                              })}
                              onClick={() => {
                                toggleStatusContractTab("A");
                              }}
                            >
                              {props.t("Active")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: statusContractTab === "X",
                              })}
                              onClick={() => {
                                toggleStatusContractTab("X");
                              }}
                            >
                              {props.t("Late")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: statusContractTab === "I",
                              })}
                              onClick={() => {
                                toggleStatusContractTab("I");
                              }}
                            >
                              {props.t("Inactive")}
                            </NavLink>
                          </NavItem>
                        </Nav>

                        {/*<TabContent*/}
                        {/*    className="p-3 text-muted"*/}
                        {/*    activeTab={statusOptinTab}*/}
                        {/*>*/}
                        {/*    <TabPane tabId="ALL">*/}

                        {/*    </TabPane>*/}
                        {/*    <TabPane tabId="P">*/}

                        {/*    </TabPane>*/}
                        {/*    <TabPane tabId="A">*/}

                        {/*    </TabPane>*/}
                        {/*    <TabPane tabId="I">*/}

                        {/*    </TabPane>*/}
                        {/*</TabContent>*/}
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg={12}>
                        <TablePagination
                          pager={pager}
                          applySearch={applySearch}
                          loading={loading}
                        >
                          <thead className="table-light">
                            <tr>
                              <th className="col-md-1 text-center">
                                {props.t("Status")}
                              </th>
                              <th className="col-md-2">
                                {props.t("Reference Code")}
                              </th>
                              {getSessionUserProfile() ===
                              USER_PROFILES.USER ? (
                                <th className="col-md-3">
                                  {props.t("Financial Agent")}
                                </th>
                              ) : (
                                <th className="col-md-3">
                                  {props.t("Contractor")}
                                </th>
                              )}
                              <th className="col-md-1">
                                {props.t("Signature date")}
                              </th>
                              <th className="col-md-1">{props.t("Effect")}</th>
                              <th className="col-md-1">
                                {props.t("Total amount")}
                              </th>
                              <th className="col-md-1">
                                {props.t("Debit balance")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {(pager.results || []).map((entity, key) => (
                              <tr
                                key={entity["contractId"]}
                                onClick={() => {
                                  navigateToEdit(entity);
                                }}
                              >
                                <td scope="row" className="text-center">
                                  <span
                                    className={StatusDomain.getClassFromValue(
                                      entity.status
                                    )}
                                  >
                                    {StatusDomain.getNameFromValue(
                                      entity.status
                                    )}
                                  </span>
                                </td>
                                <td style={{ wordWrap: "break-word" }}>
                                  {entity.externalReference}
                                </td>
                                <td>
                                  {getSessionUserProfile() ===
                                  USER_PROFILES.USER
                                    ? entity.financialAgent["name"]
                                    : entity.contractor["name"]}
                                  {entity.contractor["name"] !== null &&
                                  entity.contractor["name"] !== undefined ? (
                                    <br />
                                  ) : (
                                    ""
                                  )}
                                  <span className="card-title-desc font-size-12">
                                    {$filter(
                                      getSessionUserProfile() ===
                                        USER_PROFILES.USER
                                        ? entity.financialAgent.legalId
                                        : entity.contractor.legalId,
                                      "CPF_CNPJ"
                                    )}
                                  </span>
                                </td>
                                <td>{$filter(entity.signatureDate, "date")}</td>
                                <td>
                                  {EffetiveTypeDomain.getLabelByValue(
                                    entity.effectType
                                  )}
                                </td>
                                <td>{$filter(entity.totalAmount, "MONEY")}</td>
                                <td>{$filter(entity.debitBalance, "MONEY")}</td>
                              </tr>
                            ))}
                          </tbody>
                        </TablePagination>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>

          {modalNewContract && (
            <ManageContractNew
              isOpen={modalNewContract}
              callback={() => {
                closeNewContractModal();
              }}
            />
          )}
        </Container>
      </div>

      {error && (
        <ErrorDialog
          title={error["title"]}
          code={error["status"]}
          onClose={() => {
            setError(null);
          }}
        >
          <p>
            {!!error["detail"]
              ? error["detail"]
              : "Erro de rede, verifique sua conexão com a internet."}{" "}
          </p>
        </ErrorDialog>
      )}
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(ManageContracts));
