import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import { Label } from "reactstrap";
import PaymentCompanyDomain from "../../domains/PaymentCompanyDomain";

const animatedComponents = makeAnimated();

function Select2Banks(props) {
  const [options, setOptions] = useState(PaymentCompanyDomain.options);

  const [defaultValue] = useState(
    props.defaultValue !== null && props.defaultValue !== undefined
      ? options.filter((bank) => {
          return bank.ispb === props.defaultValue;
        })[0]
      : null
  );

  function onChange(options) {
    if (props.onChange) props.onChange(options);
  }

  return (
    <div className="templating-select select2-container">
      {props.label !== null && props.label !== undefined ? (
        <Label for={props.id} className="form-label">
          {`${props.required === true ? "*" : ""}${props.label}`}
        </Label>
      ) : (
        ""
      )}
      <Select
        isMulti={
          props.isMulti !== null && props.isMulti !== undefined
            ? props.isMulti
            : false
        }
        classNamePrefix="select2-selection"
        className="select2-form"
        closeMenuOnSelect={true}
        isSearchable={true}
        isClearable={true}
        defaultValue={defaultValue}
        menuPlacement={props.menuPlacement}
        placeholder={
          props.placeholder !== null && props.placeholder !== undefined
            ? props.placeholder
            : ""
        }
        options={options}
        getOptionLabel={(option) => {
          let comp = !!option.comp ? `${option.comp} - ` : "";
          return `${comp}${option.ispb} - ${option.name}`;
        }}
        getOptionValue={(option) => {
          return option.ispb;
        }}
        components={animatedComponents}
        onChange={(options) => {
          onChange(options);
        }}
      />
    </div>
  );
}

export default withTranslation()(Select2Banks);
