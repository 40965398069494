import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import { Label } from "reactstrap";

const animatedComponents = makeAnimated();

function Select2YesOrNo(props) {
  const [options, setOptions] = useState([
    { label: props.t("No"), value: "0" },
    { label: props.t("Yes"), value: "1" },
  ]);

  function onChange(options) {
    if (props.onChange) props.onChange(options);
  }

  return (
    <div className="templating-select select2-container">
      {props.label !== null && props.label !== undefined ? (
        <Label for={props.id} className="form-label">
          {`${props.required === true ? "*" : ""}${props.label}`}
        </Label>
      ) : (
        ""
      )}
      <Select
        isMulti={false}
        classNamePrefix="select2-selection"
        className="select2-form"
        closeMenuOnSelect={true}
        isSearchable={false}
        menuPlacement={props.menuPlacement}
        placeholder={
          props.placeholder !== null && props.placeholder !== undefined
            ? props.placeholder
            : ""
        }
        defaultValue={options[props.defaultValue]}
        options={options}
        components={animatedComponents}
        onChange={(options) => {
          onChange(options);
        }}
      />
    </div>
  );
}

export default withTranslation()(Select2YesOrNo);
